import React from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'

import { EventTermConditionScreen } from './container/auth/eventTermConditions'

import { Event } from './container/auth/event'
import { PATH } from './utils/constants/path'
import { Mandatory } from './container/auth/securityHolder/Mandatory'
import { Exchange } from './container/auth/securityHolder/Exchange'
import { Dilivery } from './container/auth/securityHolder/Dilvery'
import { IssueEntitlement } from './container/auth/securityHolder/IssueEntitlement'

import { TermsConditions } from './container/auth/termsConditions'
import LocationHelper from './utils/hooks/LocationHelper'
import { WarrantExercise } from './container/auth/securityHolder/WarrantExercise'
import { IssueSecurities } from './container/auth/securityHolder/IssueSecurities'
import { SecuritiesDilivery } from './container/auth/securityHolder/securitiesDilivery'
import { Pay } from './container/auth/securityHolder/pay'
import { ArrangementEvent } from './container/auth/securityHolder/arrangementPlan/Event'
import { ArrangementIssueEntitlement } from './container/auth/securityHolder/arrangementPlan/IssueEntitlement'
import { ArrangementDilivery } from './container/auth/securityHolder/arrangementPlan/Dilivery'
import { ArrangementQuestions } from './container/auth/securityHolder/arrangementPlan/Questions'
import { SecuritiesIssues } from './container/auth/ValidationSteps/SecuritiesIssue'
import { WarrantIssue } from './container/auth/ValidationSteps/WarrantIssues'
import { EConsent } from './container/auth/EConsent'
import { DepositInstruction } from './container/auth/Instructions/desposit/deposit_step_1'
import { DepositIssueName } from './container/auth/Instructions/desposit/deposit_step_2'
import { DepositStep3 } from './container/auth/Instructions/desposit/deposit_step_3'
import { DepositStep4 } from './container/auth/Instructions/desposit/deposit_step_4'
import { ExerciseInstruction } from './container/auth/Instructions/exercise'
import { REG_INS_STEP1 } from './container/auth/Instructions/registration/registration_step_1'
import { REG_INS_STEP2 } from './container/auth/Instructions/registration/registration_step_2'
import { DiliveryInstruction } from './container/auth/Instructions/delivery'
import { WarrantPayment } from './container/auth/Instructions/payment/warrant_payment'
import { ElectionDeclaration } from './container/auth/Instructions/election&declarations'
import { PaymentInstruction } from './container/auth/Instructions/payment'
import { ResidencyInstructions } from './container/auth/Instructions/residency'
import { TAX_CERT_STEP1 } from './container/auth/Instructions/taxcertification/taxcertification_step_1'
import { TAX_CERT_STEP3 } from './container/auth/Instructions/taxcertification/taxcertification_step_3'
import { TAX_CERT_STEP4 } from './container/auth/Instructions/taxcertification/taxcertification_step_4'
import { TAX_CERT_STEP2 } from './container/auth/Instructions/taxcertification/taxcertification_step_2'
import { ReviewInstruction } from './container/auth/Instructions/review'
import { DownloadInstruction } from './container/auth/Instructions/confirmdownload/index.jsx'
import { Login } from './container/auth/login/login'
import { Home } from './container/dashboard/home'
import { EventMain } from './container/dashboard/eventDetails/main'
import { EventWarrant } from './container/dashboard/eventDetails/warrant'
import { EventSecurityHolder } from './container/dashboard/securityholder/main'
import { UserTermsConditions } from './container/dashboard/terms&Conditions'
import { UserPrivacyPolicy } from './container/dashboard/PrivacyPolicy'
import { UpdatePassword } from './container/dashboard/updatePassword'
import { Terms_Conditions } from './container/auth/terms&Conditions'
import { PrivacyPolicy } from './container/auth/PrivacyPolicy'
import { ProtectedRoute } from './routes/userRoutes'
import { AdminLogin } from './container/admin/login'
import { AdminAuth } from './container/admin/authenticate'
import { AdminRoute } from './routes/adminRoutes'
import { AdminEventScreen } from './container/admin/adminEventScreen'
import { AdminCreateEvent } from './container/admin/adminCreateEvent'
import { AdminClientScreen } from './container/admin/adminClientScreen'
import { AdminEventDetail } from './container/admin/adminEventDetail'
import { AdminEventAccessCode } from './container/admin/adminEventAccessCode'
import { AdminReports } from './container/admin/reports'
import { SecurityHolders } from './container/admin/SecurityHolders'
import { SecurityHoldersDetails } from './container/admin/SecurityHoldersDetails'
import EventRoute from './routes/eventRoutes'

const App = () => {
    const location = useLocation()

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    React.useEffect(() => {
        scrollToTop()
        //document.title = LocationHelper.getLocationTitle(location.pathname)
    }, [location])

    return (
        <Routes>
            <Route path="/" element={<Navigate to={PATH.CLIENT_LOGIN} />} />
            <Route path={PATH.GET_EVENT} element={<EventRoute component={Event} />} />
            <Route path={PATH.CLIENT_LOGIN} element={<EventRoute component={Login} />} />
            <Route path={PATH.PORTAL_TERMS_CONDITIONS} element={<EventRoute component={TermsConditions} />} />
            <Route path={PATH.EVENT_TERMS_CONDITIONS} element={<EventRoute component={EventTermConditionScreen} />} />
            <Route path={PATH.PRIVACY_POLICY} element={<EventRoute component={PrivacyPolicy} />} />
            <Route path={PATH.TERMS_AND_CONDITIONS} element={<EventRoute component={Terms_Conditions} />} />
            {/* User Stack */}

            <Route
                path={PATH.CLIENT_HOME}
                element={<ProtectedRoute component={Home} />}
            />
            <Route
                path={PATH.CLIENT_SECURITY_HOLDER}
                element={<ProtectedRoute component={EventSecurityHolder} />}
            />
            <Route
                path={PATH.CLIENT_EVENT_DETAIL}
                element={<ProtectedRoute component={EventMain} />}
            />
            <Route
                path={PATH.CLIENT_TERMS_AND_CONDITIONS}
                element={<ProtectedRoute component={UserTermsConditions} />}
            />
            <Route
                path={PATH.CLIENT_PRIVACY_POLICY}
                element={<ProtectedRoute component={UserPrivacyPolicy} />}
            />
            <Route
                path={PATH.UPDATE_PASSWORD}
                element={<ProtectedRoute component={UpdatePassword} />}
            />
            {/* Admin dashboard */}
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-auth/:id" element={<AdminAuth />} />
            <Route
                path="/admin-events"
                element={<AdminRoute component={AdminEventScreen} />}
            />
            <Route
                path="/admin-events/create"
                element={<AdminRoute component={AdminCreateEvent} />}
            />
            <Route
                path="/admin-events/edit/:id"
                element={<AdminRoute component={AdminCreateEvent} />}
            />
            <Route
                path="/admin-events/:id/security-holders"
                element={<AdminRoute component={SecurityHolders} />}
            />
            <Route
                path="/admin-events/:id/security-holders/:sid"
                element={<AdminRoute component={SecurityHoldersDetails} />}
            />
            <Route
                path="/admin-events/:id"
                element={<AdminRoute component={AdminEventAccessCode} />}
            />
            <Route
                path="/admin-clients"
                element={<AdminRoute component={AdminClientScreen} />}
            />
            <Route
                path="/admin-event-detail/:id"
                element={<AdminRoute component={AdminEventDetail} />}
            />
            <Route
                path="/admin-reports"
                element={<AdminRoute component={AdminReports} />}
            />

            {/* Authentification */}
            <Route path={PATH.SECURITIES_VALIDATION} element={<EventRoute component={SecuritiesIssues} />} />
            <Route path={PATH.WARRANT_VALIDATION} element={<EventRoute component={WarrantIssue} />} />
            <Route path={PATH.ECONSENT_EMAIL} element={<EventRoute component={EConsent} />} />
            <Route path={PATH.DEPOSIT_INSTRUCTION} element={<EventRoute component={DepositInstruction} />} />
            <Route path={PATH.DEPOSIT_INSTRUCTION_STEP_2} element={<EventRoute component={DepositIssueName} />} />
            <Route path={PATH.DEPOSIT_INSTRUCTION_STEP_3} element={<EventRoute component={DepositStep3} />} />
            <Route path={PATH.DEPOSIT_INSTRUCTION_STEP_4} element={<EventRoute component={DepositStep4} />} />
            <Route path={PATH.ELECTION_DECLARATION} element={<EventRoute component={ElectionDeclaration} />} />
            <Route path={PATH.PAYMENT_METHODS} element={<EventRoute component={PaymentInstruction} />} />
            <Route path={PATH.RESIDENCY_INSTRUCTIONS} element={<EventRoute component={ResidencyInstructions} />} />
            <Route path={PATH.TAX_CERTIFICATION_STEP_1} element={<EventRoute component={TAX_CERT_STEP1} />} />
            <Route path={PATH.TAX_CERTIFICATION_STEP_2} element={<EventRoute component={TAX_CERT_STEP2} />} />
            <Route path={PATH.TAX_CERTIFICATION_STEP_3} element={<EventRoute component={TAX_CERT_STEP3} />} />
            <Route path={PATH.TAX_CERTIFICATION_STEP_4} element={<EventRoute component={TAX_CERT_STEP4} />} />
            <Route path={PATH.REVIEW_INSTRUCTION} element={<EventRoute component={ReviewInstruction} />} />
            <Route path={PATH.CONFIRM_DOWNLOAD} element={<EventRoute component={DownloadInstruction} />} />
            <Route path={PATH.EXCERCISE_INSTRUCTION} element={<EventRoute component={ExerciseInstruction} />} />
            <Route path={PATH.REGISTRATION_INSTRUCTION_STEP_1} element={<EventRoute component={REG_INS_STEP1} />} />
            <Route path={PATH.REGISTRATION_INSTRUCTION_STEP_2} element={<EventRoute component={REG_INS_STEP2} />} />
            <Route path={PATH.WARRANT_DELIVERY_INSTRUCTIONS} element={<EventRoute component={DiliveryInstruction} />} />
            <Route path={PATH.WARRANT_PAYMENT} element={<EventRoute component={WarrantPayment} />} />

            {/*  */}

            {/* These pages not used yet */}
            <Route path="/security-holder/mandatory" element={<Mandatory />} />
            <Route path="/security-holder/exhange" element={<Exchange />} />
            <Route
                path="/security-holder/issue-entitlement"
                element={<IssueEntitlement />}
            />
            <Route path="/security-holder/dilivery" element={<Dilivery />} />
            <Route
                path="/security-holder/warrant-exercise-event"
                element={<WarrantExercise />}
            />
            <Route
                path="/security-holder/issue-securities"
                element={<IssueSecurities />}
            />
            <Route
                path="/security-holder/securities-dilvery"
                element={<SecuritiesDilivery />}
            />
            <Route path="/security-holder/pay" element={<Pay />} />

            <Route
                path="/security-holder/arrangement-event"
                element={<ArrangementEvent />}
            />
            <Route
                path="/security-holder/arrangement-event-entitlement"
                element={<ArrangementIssueEntitlement />}
            />
            <Route
                path="/security-holder/arrangement-dilivery"
                element={<ArrangementDilivery />}
            />
            <Route
                path="/security-holder/arrangement-questions"
                element={<ArrangementQuestions />}
            />
            <Route path="*" element={<Navigate to={PATH.CLIENT_LOGIN} />} />
        </Routes>
    )
}

export default App
