import {ASSETS} from '../assets/path'

export const TermsConditions = [
    {
        heading:
            'TERMS & CONDITIONS GOVERNING YOUR USE OF THIS WEBSITE (THE “TERMS”).',
        explanation:
            'These Terms govern your use of all Odyssey Trust Company Websites including the Online Tools (hereinafter collectively referred to as the “Websites”) offered by Odyssey Trust Company (“Odyssey”).If you access and use the Websites, you accept and agree to be bound by and comply with the (i) Terms, and (ii) any terms, conditions, legal notices and disclaimers in the footers, content, other pages of the Websites. ',
    },
    {
        heading: 'YOUR AUTHORITY TO USE THE WEBSITES',
        explanation:
            'By using the Websites, you are representing to us that you have the power and authority to accept these Terms of Use and to enter into this agreement with us, that you are capable of assuming, and do assume, any risks related to the use of the Websites and their content, and that you understand and accept the terms, conditions and risks relating to their use.  ',
    },
    {
        heading: 'GENERAL',
        explanation:
            'All services of Odyssey that are described, made available or provided on the Websites may be changed or withdrawn at any time without notice and are subject to the terms and conditions of the applicable agreements governing their use in force at the time of the provision of the service, in addition to the Terms. In the event that the Terms are inconsistent with the terms of any agreement that you or your organization may have with Odyssey, the terms of that agreement will govern.The information, material and content provided in the pages of our Websites, is believed to be reliable when posted, but there is no guarantee that it is accurate, complete or current at all times. Without limiting the generality of the foregoing, the Information may include technical inaccuracies or typographical errors, and Odyssey and their officers, directors, employees and agents have no obligation to update the Information. The Websites may be changed, withdrawn or terminated at any time without notice.Changes may be made to the Terms at any time without notice by updating this posting. You agree to review the Terms regularly, and your continued access or use of the Websites will mean that you agree to any changes.',
    },
    {
        heading:
            'TERMS & CONDITIONS GOVERNING YOUR USE OF THIS WEBSITE (THE “TERMS”).',
        explanation:
            'These Terms govern your use of all Odyssey Trust Company Websites including the Online Tools (hereinafter collectively referred to as the “Websites”) offered by Odyssey Trust Company (“Odyssey”).If you access and use the Websites, you accept and agree to be bound by and comply with the (i) Terms, and (ii) any terms, conditions, legal notices and disclaimers in the footers, content, other pages of the Websites. ',
    },
]

export const TERMS_AND_CONDITIONS = [
    {
        heading: 'The standard Lorem Ipsum passage, used since the 1500s',
        des:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        IMAGES: [],
    },
    {
        heading: `You must read the following before continuing`,
        des: '',
        IMAGES: [
            {
                IMG: ASSETS.EVENT_TERM_CONDITIONS.EVENT_TERMS_CONDITION_IMG,
                TEXT: 'Letter of Transmittal',
            },
            {
                IMG: ASSETS.EVENT_TERM_CONDITIONS.EVENT_TERMS_CONDITION_IMG,
                TEXT: 'Warrant Certificate',
            },
            {
                IMG: ASSETS.EVENT_TERM_CONDITIONS.EVENT_TERMS_CONDITION_IMG,
                TEXT: 'Excercise Form',
            },
            {
                IMG: ASSETS.EVENT_TERM_CONDITIONS.EVENT_TERMS_CONDITION_IMG,
                TEXT: 'Exercise Form',
            },
        ],
    },
]

export const Validation = {
    securitiesIssues: {
        HEADING: '', //'Consolidation/Reverse Split By Exchange - Event',
        DESCRIPTION: {
            HEADING:
                'This step is required as an identification check. The total number of securities (shares) you hold can be determined as follows:',
            STEPS: [
                'If you hold shares in Direct Registration (DRS), or book-entry form, by viewing the box labeled TOTAL DRS BALANCE on your most recent DRS Statement, if applicable.',
                'If your shares are represented by physical certificate(s), by totaling the amount shown on each share certificate you hold in this class of securities.',
                'If you hold both DRS/book-entry and physical certificate(s), by adding your TOTAL DRS BALANCE (or book-entry shares) to the share amount on each of your certificates.',
            ],
            NOTE:
                'NOTE: If you hold securities in a predecessor of the above-named securities, please contact us at the number below if you require assistance to determine the number of securities you currently hold.',
        },
    },
    WarrantsEvent: {
        HEADING: '', //'Exercise Of Warrants Event',
        DESCRIPTION:
            'This step is required as an identification check. The total number of warrants you hold can be determined by viewing the box labeled TOTAL DRS BALANCE on your most recent DRS Statement registered as shown above.',
    },
}

export const E_CONSENT = {
    HEADING:
        ' We currently do not have your email address and consent to electronic delivery on file',
    DESCRIPTION:
        'For the fastest delivery of your new shares and/or future continuous disclosure mailings by the Issuer, as applicable, please provide your email address.    providing your email address, you consent to its use for delivery of DRS Statements, payment confirmations, continuous disclosure materials and other    communications issued by the Issuer of your securities.',
    NOTE: [
        'Please note that you may subsequently withdraw your consent to electronic delivery at any time by notifying Odyssey at www.odysseycontact.com.',
        'If you do not provide your email address and consent, any securities/share entitlements issued will be delivered by mail.',
    ],
}
export const consolidationReverse = [
    'Current (Old) Issue Name and Issue Type:',
    'New Issue Name and Issue Type:',
    'Account Number:',
    'Registered in the Name of:',
    'Registered Holder Name:',

    'Certificate Prefix(6 Characters):',
    'Certificate Number(15 chars max):',
    'Number of shares / securities for each Certificate:',
    'Number of shares / securities in DRS form:',
    'Number of shares / securities in post - consolidated amount for each Certificate:',
    'Number of shares / securities in post - consolidated amount for DRS:',
    'Total number of pre - consolidated securities held:',
    'Total Post - consolidated Share or securities entitlement for each:',
    'Indicate if any certificates are lost',
]

export const warrantExercise = [
    ' Account Number:',
    'Certificate Number:',
    'Current number of securities per certificate:',
    'DRS – total current number of warrants in DRS form:',
    'Grand total warrants held:',
    'Grand Total Securities available for purchase under exercise terms according to the existing balance of warrants: ',
    'Exercise Terms:',
    'Expiry date and time:',
]

export const arrangementEvent = [
    'Issue Name and Issue Type:',
    'Registered holder name:',
    'Account Number ( 10 Characters):',
    'Certificate Number (15 Characters), if applicable:',
    'Number of securities for each certificate:',
    'Total DRS position, if applicable:',
    'The total number of securities:',
    'Price by share (to be paid) and/or exchange rate for securities:',
    'The total gross entitlement available for payment in cash and/or securities:',
    'Indicate if any certificates are lost',
]

export const TABLE = {
    Consolidation: {
        HEADING: [
            'CERTIFICATE NUMBER OR DRS/BOOK-ENTRY POSITION',
            'CURRENT NUMBER OF SECURITIES(SHARES)',
            'POST-CONSOLIDATED SHARE ENTITLEMENT (AFTER EXCHANGE)',
            'CHECK BOX BELOW TO DEPOSIT',
            'CHECK BOX BELOW IF CERTIFICATE IS LOST',
        ],
    },
    PlanofArrangement: {
        HEADING: [
            'CERTIFICATE NUMBER OR DRS/BOOK-ENTRY POSITION',
            'CURRENT NUMBER OF SECURITIES(SHARES)',
            'CHECK BOX BELOW TO DEPOSIT',
            'CHECK BOX BELOW IF CERTIFICATE IS LOST',
        ],
    },
}

export const VALIDATION = {
    PHONE_NUMBER: /^\d([0-9 -]{0,10}\d)?$/,
}

const formatting_options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
}
export const DOLLAR_STRING = new Intl.NumberFormat('en-US', formatting_options)
export const NUMBER_FORMAT = (number) =>
    new Intl.NumberFormat('en-US').format(number)
export const DATE_FORMAT = (date) =>
    new Intl.DateTimeFormat('en-US').format(date)
