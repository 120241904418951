import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'
import {SelectStateWithLabel} from '../../../../components/selectWithLabel/selectStateWithLabel'
import {SelectCountryWithLabel} from '../../../../components/selectWithLabel/selectCountryWithLabel'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import {prepareConsolidationExchangeDetail} from '../../../../redux/actions/auth.actions'
import { VALIDATION } from '../../../constants'

export const DepositStep3 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const {consolidation_exchange_detail, consolidation_exchange, user, event} = useSelector((state: RootState)=> state && state.VerificationReducer)
    const formik = useFormik({
        initialValues: {
            full_name: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.full_name || '' :'',
            street_address: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.street_address || '' :'',
            city: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.city || '' :'',
            zip_or_postal_code: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.zip_or_postal_code || '' :'',
            state: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.state || '' :'',
            country: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.country || '' :'',
            unit_suite_apartment_number: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.unit_suite_apartment_number || '' :'',
            telephone: consolidation_exchange.issue_in_anothername ? consolidation_exchange.issue_in_anothername.telephone || '' :''
        },
        validationSchema: Yup.object({
            full_name: Yup.string()
                .min(3, 'Min character 3 required.')
                .max(16, 'Limit Max character 16')
                .required('Full Name Required'),
            street_address: Yup.string().required('Street Address Required'),
            city: Yup.string().required('City Required'),
            zip_or_postal_code: Yup.string().when('country', {
                is: (country)=> country === 'US' || country === 'CA',
                then: Yup.string().required('Zip Code Required')}),
            state: Yup.string().when('country', {
                is: (country)=> country === 'US' || country === 'CA',
                then: Yup.string().required('State Required')}),
            country: Yup.string().required('Country Required'),
            unit_suite_apartment_number: Yup.string(),
            telephone: Yup.string().matches(VALIDATION.PHONE_NUMBER,'Telephone Number Not Valid'),
        }),
        onSubmit: (values) => {
            dispatch(prepareConsolidationExchangeDetail({issue_in_anothername:values}))
            if(user?.event_detail?.allow_deliver_to_alternate_mailing_address || user?.event_detail?.allow_hold_for_pickup){
                navigate('/deposit-instruction-step-4')
            }else {
                if(event && event.eventtype_id === 1){
                    navigate("/review-instruction")
                }
                if(event && event.eventtype_id === 3){
                    if(consolidation_exchange_detail.holder_make_an_election ||
                        consolidation_exchange_detail.ask_residency_holder_status_question ||
                        consolidation_exchange_detail.accredited_investor_info_required) {
                            navigate("/election-declaration")
                        }else {
                            if ((consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash')) {
                                navigate('/Payment-methods')
                            } else {
                                if (consolidation_exchange_detail.ask_residency_holder_status_question) {
                                    navigate('/residency-instructions')
                                } else {
                                    navigate('/review-instruction')
                                }
                            }
                        }
                }
            }
        },
    })
    return (
        <div className="w-full h-full flex flex-col justify-between  min-h-screen">
            <div className="">
                <Nav
                    enableProps={true}
                />
                <Header
                    heading={event.event_name}
                    description="Deposit Instructions"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col py-0 space-y-10 accent-reddark">
                        <div className="mt-10 space-y-8">
                            <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                                <div className="space-y-3">
                                    {' '}
                                    <InputWithLabel
                                        label="Full Name"
                                        placeholder="Please enter full name"
                                        name="full_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.full_name}
                                        errors={formik?.errors?.full_name}
                                        touched={formik?.touched?.full_name}
                                        width="full"
                                    />
                                    <SelectCountryWithLabel
                                        label="Country"
                                        name="country"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.country}
                                        errors={formik?.errors?.country}
                                        touched={formik?.touched?.country}
                                        width="full"
                                    />
                                   {['US','CA'].includes(formik.values.country) && <SelectStateWithLabel
                                        label="Province/State"
                                        name="state"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.state}
                                        errors={formik?.errors?.state}
                                        touched={formik?.touched?.state}
                                        width="full"
                                        countryCode={formik.values.country}
                                    />}
                                    <InputWithLabel
                                        label="Unit/Suite/Apt"
                                        placeholder="Please enter unit/suite/appartment number"
                                        name="unit_suite_apartment_number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.unit_suite_apartment_number}
                                        errors={formik?.errors?.unit_suite_apartment_number}
                                        touched={formik?.touched?.unit_suite_apartment_number}
                                        width="full"
                                    />
                                </div>
                                <div className="space-y-3">
                                    <InputWithLabel
                                        label="Street Address"
                                        placeholder="Please enter street"
                                        name="street_address"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.street_address}
                                        errors={formik?.errors?.street_address}
                                        touched={formik?.touched?.street_address}
                                        width="full"
                                    />

                                    <InputWithLabel
                                        label="City"
                                        placeholder="Please enter city"
                                        name="city"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                        errors={formik?.errors?.city}
                                        touched={formik?.touched?.city}
                                        width="full"
                                    />
                                    <InputWithLabel
                                        label="Zip/Postal Code"
                                        placeholder="Please enter Zip code"
                                        name="zip_or_postal_code"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.zip_or_postal_code}
                                        errors={formik?.errors?.zip_or_postal_code}
                                        touched={formik?.touched?.zip_or_postal_code}
                                        width="full"
                                    />

                                    <InputWithLabel
                                        label="Telephone Number"
                                        placeholder="Please enter telephone number"
                                        name="telephone"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.telephone}
                                        errors={formik?.errors?.telephone}
                                        touched={
                                            formik?.touched?.telephone
                                        }
                                        width="full"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-end">
                                <button
                                    className="btn-brown w-44"
                                    type="submit">
                                    CONTINUE
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}
