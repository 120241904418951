import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft, FaUpload} from 'react-icons/fa'
import {Input} from '../../../../components/input/input'
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'
import {QuestionIcon} from '../../../../components/question'
import OtpInput from 'react-otp-input'
import React, {useRef, useState, }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import { handleUploadDoc, prepareConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'
import { TOAST_TYPE } from '../../../../utils/constants'
import { showToast } from '../../../../utils/functions'
import w9Instructions from '../../../../assets/doc/W9 Instructions.pdf'
import Tooltip from '@material-ui/core/Tooltip'
import { DATE_FORMAT } from '../../../constants'
import moment from 'moment'

export const TAX_CERT_STEP2 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const fileInput = useRef<any>(null);
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)
    const formik = useFormik({
        initialValues: {
            name: consolidation_exchange?.w9form?.name || '',
            business_name: consolidation_exchange?.w9form?.business_name || '',
            federal_tax_classification: consolidation_exchange?.w9form?.federal_tax_classification || '',
            tax_classification: consolidation_exchange?.w9form?.tax_classification || '',
            exempt_payee_code: consolidation_exchange?.w9form?.exempt_payee_code || '',
            exemption_from_facta_reporting_code: consolidation_exchange?.w9form?.exemption_from_facta_reporting_code || '',
            address: consolidation_exchange?.w9form?.address || '',
            city_state_and_zip_code: consolidation_exchange?.w9form?.city_state_and_zip_code || '',
            account_numbers: consolidation_exchange?.w9form?.account_numbers || '',
            social_security_number: consolidation_exchange?.w9form?.social_security_number || '',
            employer_identification_number: consolidation_exchange?.w9form?.employer_identification_number || '',
            date: consolidation_exchange?.w9form?.date || moment().format('YYYY-MM-DD'),
            signature_of_us_person: consolidation_exchange?.w9form?.signature_of_us_person || '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(),
            federal_tax_classification: Yup.string().required("Federal tax field is required"),
            tax_classification: Yup.string().when('federal_tax_classification', {
                is: "Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)",
                then: Yup.string().required('Tax Classification is required')}),
            address: Yup.string().required(),
            city_state_and_zip_code: Yup.string().required('City, State and Zip Code field is required'),
            social_security_number: Yup.string().when('employer_identification_number', {
                is: (ein)=> !ein,
                then: Yup.string().required('Social security number or Employer Identification Number is required')}),
            employer_identification_number: Yup.string().when('social_security_number', {
                is: (ssn)=> !ssn,
                then: Yup.string().required('Social security number or Employer Identification Number is required')}),
            //date: Yup.string().required('Date is required'),
            signature_of_us_person: Yup.string().required('Signature need to be uploaded')
        },[["social_security_number", "employer_identification_number"]]),
        onSubmit: (values) => {
            let w9form = consolidation_exchange.w9form
            dispatch(prepareConsolidationExchangeDetail({w9form: {...w9form , ...values}}))
            navigate('/review-instruction')
        },
    })
    const handleFileChange = async (file: any) => {
        if (file) {
            if(file.target.files[0].type.includes("image")){
                const res = await dispatch(handleUploadDoc(file.target.files[0]))
                if(res.status){
                    let w9form = consolidation_exchange.w9form
                    formik.setFieldValue("signature_of_us_person", res.data.location)
                    dispatch(prepareConsolidationExchangeDetail({w9form: {...w9form , signature_of_us_person: res.data.location}}))
                }else {
                    showToast('Error uploading document, Try Again!', TOAST_TYPE.error)
                }
            } else {
                showToast('Only Image files are allowed', TOAST_TYPE.error)
            }
        }
    }
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav
                    enableProps={true}
                />
                <Header
                    heading={event.event_name}
                    description="Tax Election"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col space-y-10 accent-reddark">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                        Please complete the Form W-9 below
                        </div>
                        <hr className="border border-gray2" />
                        <div className="space-y-4">
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="name"
                                    className="text-sm Roboto-Medium">
                                    1. NAME (AS SHOWN ON YOUR INCOME TAX
                                    RETURN). NAME IS REQUIRED ON THIS LINE; DO
                                    NOT LEAVE THIS LINE BLANK.
                                </label>
                                <Input
                                    id="name"
                                    placeholder=""
                                    name="name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    errors={formik?.errors?.name}
                                    touched={formik?.touched?.name}
                                    width="full"
                                />
                            </div>
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="business_name"
                                    className="text-sm Roboto-Medium">
                                    2. BUSINESS NAME/DISREGARDED ENTITY NAME, IF
                                    DIFFERENT FROM ABOVE
                                </label>
                                <Input
                                    id="business_name"
                                    placeholder=""
                                    name="business_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.business_name}
                                    errors={formik?.errors?.business_name}
                                    touched={formik?.touched?.business_name}
                                    width="full"
                                />
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label
                                htmlFor="name"
                                className="text-sm Roboto-Medium">
                                3. CHECK APPROPRIATE BOX FOR FEDERAL TAX
                                CLASSIFICATION OF THE PERSON WHOSE NAME IS
                                ENTERED ON LINE 1. CHECK ONLY ONE OF THE
                                FOLLOWING SEVEN BOXES.
                            </label>
                            <div className="space-y-2 px-2">
                                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-4">
                                    <Checkbox
                                        text=" Individual/sole proprietor or
                                    single-member LLC
                                    "
                                        name="federal_tax_classification"
                                        id="federalTax1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value=" Individual/sole proprietor or
                                    single-member LLC
                                    "
                                    stateVal={formik.values.federal_tax_classification}
                                    />
                                    <Checkbox
                                        text="C Corporation"
                                        name="federal_tax_classification"
                                        id="federalTax2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'C Corporation'}
                                        stateVal={formik.values.federal_tax_classification}
                                    />
                                    <Checkbox
                                        text="S Corporation"
                                        name="federal_tax_classification"
                                        id="federalTax3"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'S Corporation'}
                                        stateVal={formik.values.federal_tax_classification}
                                    />
                                    <Checkbox
                                        text="Partnership"
                                        name="federal_tax_classification"
                                        id="federalTax4"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'Partnership'}
                                        stateVal={formik.values.federal_tax_classification}
                                    />
                                    <Checkbox
                                        text="Trust/estate"
                                        name="federal_tax_classification"
                                        id="federalTax5"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'Trust/estate'}
                                        stateVal={formik.values.federal_tax_classification}
                                    />
                                </div>
                                <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between space-y-2 lg:space-y-0">
                                    <Checkbox
                                        text="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
                                        name="federal_tax_classification"
                                        id="federalTax1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value="Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
                                        stateVal={formik.values.federal_tax_classification}
                                    />
                                    {formik.values.federal_tax_classification === "Limited liability company. Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)" && <InputWithLabel
                                        label=""
                                        placeholder=""
                                        name="tax_classification"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tax_classification}
                                        errors={formik?.errors?.tax_classification}
                                        touched={formik?.touched?.tax_classification}
                                        width="full"
                                    />}
                                </div>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label
                                htmlFor="name"
                                className="text-sm Roboto-Medium">
                                Note: Check the appropriate box in the line
                                above for the tax classification of the
                                single-member owner. Do not check LLC if the LLC
                                is classified as a single-member LLC that is
                                disregarded from the owner unless the owner of
                                the LLC is another LLC that is not disregarded
                                from the owner for U.S. federal tax purposes.
                                Otherwise, a single-member LLC that is
                                disregarded from the owner should check the
                                appropriate box for the tax classification of
                                its owner.
                            </label>
                            <div className="space-y-2 px-2">
                                <div className="flex space-x-1  md:space-x-2 items-center cursor-pointer">
                                    <input
                                        type="radio"
                                        id="federalTax2"
                                        name="federal_tax_classification"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value="Other"
                                        className="cursor-pointer accent-[#0E294B]  outline-none ring-0 focus:ring-0 "
                                        checked={formik.values.federal_tax_classification === "Other"}
                                    />
                                    <label
                                        className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mt-0.5"
                                        htmlFor={'federalTax2'}>
                                        <div className="whitespace-nowrap flex ">
                                            Other (
                                                <span className="text-blue-normal cursor-pointer" onClick={()=>window.open(w9Instructions)}>
                                                    See Instructions
                                                </span>{' '}
                                                <Tooltip title="Click to open Instruction">
                                                <div className="-mt-1 px-1"  onClick={()=>window.open(w9Instructions)}>
                                                    <QuestionIcon />
                                                </div>
                                            </Tooltip>
                                            )
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {formik.errors.federal_tax_classification && (
                            <div className="text-reddark text-xs">
                                {formik.errors.federal_tax_classification}
                            </div>
                        )}
                        <div className="space-y-4">
                            <div className="flex flex-col space-y-3">
                                <label
                                    htmlFor="address"
                                    className="text-sm Roboto-Medium flex items-center">
                                    4. Exemptions (codes apply only to certain
                                    entities, not individuals; ) See
                                    <span className="px-1 text-blue-normal cursor-pointer" onClick={()=>window.open(w9Instructions)}>
                                        Instructions
                                    </span>{' '}
                                    <Tooltip title="Click to open Instruction">
                                                <div className="-mt-1 px-1"  onClick={()=>window.open(w9Instructions)}>
                                                    <QuestionIcon />
                                                </div>
                                                </Tooltip>
                                </label>
                                <div className="space-y-6 md:w-8/12">
                                    <div className="grid md:grid-cols-2 gap-4">
                                        <label
                                            htmlFor="payeecode"
                                            className="text-xs md:text-sm Roboto-Medium md:whitespace-nowrap">
                                            Exempt payee code (if any)
                                        </label>
                                        <Input
                                            id="payeecode"
                                            placeholder=""
                                            name="exempt_payee_code"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.exempt_payee_code}
                                            errors={formik?.errors?.exempt_payee_code}
                                            touched={formik?.touched?.exempt_payee_code}
                                            width="full"
                                        />
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-4">
                                        <label
                                            htmlFor="exemption_from_facta_reporting_code"
                                            className="text-xs md:text-sm Roboto-Medium md:whitespace-nowrap">
                                            Exemption from FATCA reporting code
                                            (if any)
                                        </label>
                                        <Input
                                            id="exemption_from_facta_reporting_code"
                                            placeholder=""
                                            name="exemption_from_facta_reporting_code"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.exemption_from_facta_reporting_code}
                                            errors={formik?.errors?.exemption_from_facta_reporting_code}
                                            touched={formik?.touched?.exemption_from_facta_reporting_code}
                                            width="full"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="address"
                                    className="text-sm Roboto-Medium flex items-center">
                                    5. ADDRESS (number, street, and apt. or
                                    suite no.) See
                                    <span className="px-1 text-blue-normal cursor-pointer" onClick={()=>window.open(w9Instructions)}>
                                        Instructions
                                    </span>{' '}
                                    <Tooltip title="Click to open Instruction">
                                                <div className="-mt-1 px-1"  onClick={()=>window.open(w9Instructions)}>
                                                    <QuestionIcon />
                                                </div>
                                                </Tooltip>
                                </label>
                                <Input
                                    id="address"
                                    placeholder=""
                                    name="address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    errors={formik?.errors?.address}
                                    touched={formik?.touched?.address}
                                    width="full"
                                />
                            </div>
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="city_state_and_zip_code"
                                    className="text-sm Roboto-Medium">
                                    6. CITY, STATE, AND ZIP CODE
                                </label>
                                <Input
                                    id="city_state_and_zip_code"
                                    placeholder=""
                                    name="city_state_and_zip_code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city_state_and_zip_code}
                                    errors={formik?.errors?.city_state_and_zip_code}
                                    touched={formik?.touched?.city_state_and_zip_code}
                                    width="full"
                                />
                            </div>
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="account_numbers"
                                    className="text-sm Roboto-Medium">
                                    7. LIST ACCOUNT NUMBER(S) HERE (OPTIONAL)
                                </label>
                                <Input
                                    id="account_numbers"
                                    placeholder=""
                                    name="account_numbers"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.account_numbers}
                                    errors={formik?.errors?.account_numbers}
                                    touched={formik?.touched?.account_numbers}
                                    width="full"
                                />
                            </div>
                        </div>
                        <div className="space-y-6">
                            <div className="text-xl lg:text-2xl Roboto-Medium">
                                PART I: TAXPAYER IDENTIFICATION NUMBER (TIN)
                            </div>
                            <div className="space-y-2">
                                <div className="text-xs md:text-sm">
                                    Enter your TIN in the appropriate box. The
                                    TIN provided must match the name given on
                                    line 1 to avoid backup withholding. For
                                    individuals, this is generally your social
                                    security number (SSN). However, for a
                                    resident alien, sole proprietor, or
                                    disregarded entity, see the instructions for
                                    Part I, later. For other entities, it is
                                    your employer identification number (EIN).
                                    If you do not have a number, see How to get
                                    a TIN, later.
                                </div>
                                <div className="text-xs md:text-sm ">
                                    <span className="Roboto-Medium pr-1">
                                        Note:
                                    </span>{' '}
                                    If the account is in more than one name, see
                                    the instructions for line 1. Also see What
                                    Name and Number To Give the Requester for
                                    guidelines on whose number to enter.
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6">
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="accountNumber"
                                    className="text-sm Roboto-Medium">
                                    SOCIAL SECURITY NUMBER
                                </label>

                                <div className="flex items-center space-x-4">
                                    <Input
                                        id="social_security_number"
                                        placeholder=""
                                        name="social_security_number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.social_security_number}
                                        errors={formik?.errors?.social_security_number}
                                        touched={formik?.touched?.social_security_number}
                                        width="full"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <label
                                    htmlFor="accountNumber"
                                    className="text-sm Roboto-Medium">
                                    or Employer identification number
                                </label>

                                <div className="flex items-center space-x-4">
                                <Input
                                        id="employer_identification_number"
                                        placeholder=""
                                        name="employer_identification_number"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.employer_identification_number}
                                        errors={formik?.errors?.employer_identification_number}
                                        touched={formik?.touched?.employer_identification_number}
                                        width="full"
                                    />
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="text-xl lg:text-2xl Roboto-Medium">
                                    Under penalties of perjury, I certify that:
                                </div>

                                <div className="text-xs md:text-sm Roboto-Medium">
                                    1. The number shown on this form is my
                                    correct taxpayer identification number (or I
                                    am waiting for a number to be issued to me);
                                    and
                                    <br />
                                    2. I am not subject to backup withholding
                                    because: (a) I am exempt from backup
                                    withholding, or (b) I have not been notified
                                    by the Internal Revenue Service (IRS) that I
                                    am subject to backup withholding as a result
                                    of a failure to report all interest or
                                    dividends, or (c) the IRS has notified me
                                    that I am no longer subject to backup
                                    withholding; and
                                    <br /> 3. I am a U.S. citizen or other U.S.
                                    person (defined below); and
                                    <br /> 4. The FATCA code(s) entered on this
                                    form (if any) indicating that I am exempt
                                    from FATCA reporting is correct.
                                </div>

                                <div className="text-xs md:text-sm Roboto-Medium">
                                    Certification instructions. You must cross
                                    out item 2 above if you have been notified
                                    by the IRS that you are currently subject to
                                    backup withholding because you have failed
                                    to report all interest and dividends on your
                                    tax return. For real estate transactions,
                                    item 2 does not apply. For mortgage interest
                                    paid, acquisition or abandonment of secured
                                    property, cancellation of debt,
                                    contributions to an individual retirement
                                    arrangement (IRA), and generally, payments
                                    other than interest and dividends, you are
                                    not required to sign the certification, but
                                    you must provide your correct TIN.
                                </div>

                                <div className="flex space-x-1 Roboto-Regular text-xs md:text-sm Roboto-Medium">
                                        See the{' '}
                                        
                                    <span className="px-1 text-blue-normal cursor-pointer" onClick={()=>window.open(w9Instructions)}>
                                        Instructions
                                    </span>{' '}
                                    <Tooltip title="Click to open Instruction">
                                                <div className="-mt-1 px-1"  onClick={()=>window.open(w9Instructions)}>
                                                    <QuestionIcon />
                                                </div>
                                                </Tooltip>{' '}
                                    <span>for Part II, later.</span>
                                </div>
                            </div>

                            <div className="w-full">
                                <div className="text-xl lg:text-2xl Roboto-Medium">
                                    SIGN HERE
                                </div>
                                <div className="flex flex-col md:flex-row md:items-center md:justify-between md:space-x-4 space-y-4 md:space-y-0 text-blue-base text-xs md:text-sm Roboto-Medium">
                                    <div> SIGNATURE OF U.S. PERSON</div>
                                    <button onClick={() => fileInput.current.click()} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                        <FaUpload />
                                        <input ref={fileInput} style={{ display: 'none' }} type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />
                                        <span>Upload a picture</span>
                                    </button>
                                    {formik.errors.signature_of_us_person && (
                            <div className="text-reddark text-xs">
                                {formik.errors.signature_of_us_person}
                            </div>
                        )}
                                    {formik.values.signature_of_us_person && <img src={formik.values.signature_of_us_person} width={300}  />}
                                    <div className="flex items-center space-x-2">
                                        <label
                                            htmlFor="date"
                                            className="text-sm md:text-base Roboto-Medium">
                                            DATE
                                        </label>
                                        <Input
                                            id="date"
                                            placeholder=""
                                            name="date"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.date}
                                            errors={formik?.errors?.date}
                                            touched={formik?.touched?.date}
                                            width="full"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button
                                className="btn-brown w-44"
                                type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}
