import { useEffect, useRef, useState } from 'react'
import { FaUpload, FaTimes } from 'react-icons/fa'
import { ReactMaterialTable } from '../../components/table/customtable'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Input } from '../../components/input/input'
import { imgs } from '../../assets/images/img'
import { ModalComponent } from '../../components/Modal'
import { deleteEntitlement, onCreateClient, onCreateEvent, onGetClient, onGetEventDetails, onGetEventType, onUpdateEvent } from '../../redux/actions/admin.actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SelectWithLabel } from '../../components/selectWithLabel/selectWithLabel'
import { TextArea } from '../../components/textarea/input'
import { Checkbox } from '../../components/checkbox/checkbox'
import { handleUpload, showToast } from '../../utils/functions'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import { TOAST_TYPE } from '../../utils/constants'

export const AdminCreateEvent = () => {
    const dispatch = useDispatch<any>()
    const { id } = useParams<any>()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<any>(false)
    const [mode, setMode] = useState<any>(0)
    const [entitlementLoading, setEntitlementLoading] = useState<boolean>(false)
    const [e_id, setE_ID] = useState<any>(null)
    const [isEntitlement, setisEntitlement] = useState<any>(false)
    const [status, setStatus] = useState<any>('')
    const [new_entitlements, setnew_entitlements] = useState<any>([])
    const [entitlements, setentitlements] = useState<any>([])
    const [completeEntitlement, setCompleteEntitlement] = useState<any>(false)
    const [activeEntitlement, setactiveEntitlement] = useState<any>(1)
    const hiddenFileInput = useRef<any>(null);
    const hiddenFileInput1 = useRef<any>(null);
    const hiddenFileInput2 = useRef<any>(null);
    const hiddenFileInput3 = useRef<any>(null);
    const hiddenFileInput4 = useRef<any>(null);
    const hiddenFileInput5 = useRef<any>(null);
    const { clients, client_added, event_types } = useSelector((state: any) => state.AdminReducer, shallowEqual)
    useEffect(() => {
        dispatch(onGetClient('', navigate))
        dispatch(onGetEventType(navigate))
    }, [])

    useEffect(() => {
        if (id) {
            setMode(1)
            getDetails()
        }
    }, [id])

    const getDetails = async () => {
        const query = `?event_id=${id}`
        const query1 = id
        const res = await dispatch(onGetEventDetails(query, query1, '', navigate, true))
        setStatus(res?.eventDetails?.status)
        formik.setFieldValue('eventtype_id', res?.eventDetails?.eventtype_id?.toString())
        formik.setFieldValue('client_id', res?.eventDetails?.client_id)
        formik.setFieldValue('event_name', res?.eventDetails?.event_name)
        formik.setFieldValue('color_palette', res?.eventDetails?.color_palette)
        formik.setFieldValue('letter_of_transmittal', res?.eventDetails?.letter_of_transmittal)
        formik.setFieldValue('warrant_document', res?.eventDetails?.warrant_document)
        formik.setFieldValue('exercise_form', res?.eventDetails?.exercise_form)
        formik.setFieldValue('other_document', res?.eventDetails?.other_document)
        formik.setFieldValue('warrant_payment_instruction', res?.eventDetails?.warrant_payment_instruction)
        formik.setFieldValue('description', res?.eventDetails?.description)
        formik.setFieldValue('term_and_conditions_of_portal', res?.eventDetails?.term_and_conditions_of_portal)
        formik.setFieldValue('terms_and_conditions_of_event', res?.eventDetails?.terms_and_conditions_of_event)
        formik.setFieldValue('current_issue_name', res?.eventDetails?.current_issue_name)
        formik.setFieldValue('current_issue_type', res?.eventDetails?.current_issue_type)
        formik.setFieldValue('new_issue_name', res?.eventDetails?.new_issue_name || '')
        formik.setFieldValue('new_issue_type', res?.eventDetails?.new_issue_type || '')
        formik.setFieldValue('status', res?.eventDetails?.status)
        formik.setFieldValue('physical_certificate', res?.eventDetails?.physical_certificate)
        formik.setFieldValue('offer_econsent', res?.eventDetails?.offer_econsent)
        formik.setFieldValue('extra_validation_required', res?.eventDetails?.extra_validation_required)
        formik.setFieldValue('signing_authority_required', res?.eventDetails?.signing_authority_required)
        formik.setFieldValue('units_held', res?.eventDetails?.units_held)
        formik.setFieldValue('units_entitled', res?.eventDetails?.units_entitled)
        formik.setFieldValue('fractional_treatment', res?.eventDetails?.fractional_treatment)
        formik.setFieldValue('number_of_decimal_places', res?.eventDetails?.number_of_decimal_places)
        formik.setFieldValue('cash_in_lieu_payment_rate', res?.eventDetails?.cash_in_lieu_payment_rate)
        formik.setFieldValue('entitlement', res?.eventDetails?.number_of_entitlement?.toString())
        formik.setFieldValue('event_start_date', res?.eventDetails?.event_start_date ? moment.utc(res?.eventDetails?.event_start_date).local().format('YYYY-MM-DD') : "")
        formik.setFieldValue('event_end_date', res?.eventDetails?.event_end_date ? moment.utc(res?.eventDetails?.event_end_date).local().format('YYYY-MM-DD') : '')
        formik.setFieldValue('event_end_date_text', res?.eventDetails?.event_end_date_text)
        formik.setFieldValue('issue_type', res?.eventDetails?.entitlement === 'securities' ? "1" : res?.eventDetails?.entitlement === 'cash' ? '2' : '3')
        formik.setFieldValue('ask_residency_holder_status_question', res?.eventDetails?.ask_residency_holder_status_question)
        formik.setFieldValue('tax_status', res?.eventDetails?.tax_status)
        formik.setFieldValue('w9_from_us_holder', res?.eventDetails?.w9_from_us_holder)
        formik.setFieldValue('w8_from_non_us_holder', res?.eventDetails?.w8_from_non_us_holder)
        formik.setFieldValue('accredited_investor_info_required', res?.eventDetails?.accredited_investor_info_required)
        formik.setFieldValue('is_transfer_allowed', res?.eventDetails?.is_transfer_allowed)
        formik.setFieldValue('holder_make_an_election', res?.eventDetails?.holder_make_an_election)
        formik.setFieldValue('securityholder_election_option', res?.eventDetails?.securityholder_election_option)
        formik.setFieldValue('question', res?.eventDetails?.question)
        formik.setFieldValue('payment', res?.eventDetails?.payment)
        formik.setFieldValue('currency', res?.eventDetails?.currency)
        formik.setFieldValue('payment_rate', res?.eventDetails?.payment_rate)
        formik.setFieldValue('exercise_terms', res?.eventDetails?.exercise_terms)
        formik.setFieldValue('by_wire', res?.eventDetails?.by_wire)
        formik.setFieldValue('by_wire_fee', res?.eventDetails?.by_wire_fee)
        formik.setFieldValue('direct_deposit_fee', res?.eventDetails?.direct_deposit_fee)
        formik.setFieldValue('ach_fee', res?.eventDetails?.ach_fee)
        formik.setFieldValue('by_cheque', res?.eventDetails?.by_cheque)
        formik.setFieldValue('direct_deposit', res?.eventDetails?.direct_deposit)
        formik.setFieldValue('ach', res?.eventDetails?.ach)
        formik.setFieldValue('entitlements', res?.eventDetails?.plan_of_arrangement_entitlements)
        setentitlements(res?.eventDetails?.plan_of_arrangement_entitlements)
        formik.setFieldValue('allow_deliver_to_alternate_mailing_address', res?.eventDetails?.allow_deliver_to_alternate_mailing_address)
        formik.setFieldValue('allow_hold_for_pickup', res?.eventDetails?.allow_hold_for_pickup)
        if (res?.eventDetails?.number_of_entitlement > 0) {
            setCompleteEntitlement(true)
        }
        if (res?.eventDetails?.letter_of_transmittal) {
            const a = res?.eventDetails?.letter_of_transmittal?.split('/')
            formik.setFieldValue('letter_of_transmittal_name', a[a?.length - 1])
        }
        if (res?.eventDetails?.other_document) {
            const a = res?.eventDetails?.other_document?.split('/')
            formik.setFieldValue('other_document_name', a[a?.length - 1])
        }
        if (res?.eventDetails?.exercise_form) {
            const a = res?.eventDetails?.exercise_form?.split('/')
            formik.setFieldValue('exercise_form_name', a[a?.length - 1])
        }
        if (res?.eventDetails?.warrant_document) {
            const a = res?.eventDetails?.warrant_document?.split('/')
            formik.setFieldValue('warrant_document_name', a[a?.length - 1])
        }
        if (res?.eventDetails?.warrant_payment_instruction) {
            const a = res?.eventDetails?.warrant_payment_instruction?.split('/')
            formik.setFieldValue('warrant_payment_instruction_name', a[a?.length - 1])
        }

        // new_issue_name1: '',
        // new_issue_type1: '',
        // units_held1: '',
        // units_entitled1: '',
        // fractional_treatment1: 'roundup',
        // number_of_decimal_places1: 0,
        // cash_in_lieu_payment_rate1: '',
        // cash_in_lieu_currency1: '',

    }

    const handleEntitleClick = (index) => {
        setE_ID(entitlements[index]?.id)
        setisEntitlement(true)
        if (index > 0) {
            formik.setFieldValue('units_held1', entitlements[index]?.units_held)
            formik.setFieldValue('units_entitled1', entitlements[index]?.units_entitled)
            formik.setFieldValue('fractional_treatment1', entitlements[index]?.fractional_treatment)
            formik.setFieldValue('new_issue_name1', entitlements[index]?.new_issue_name)
            formik.setFieldValue('new_issue_type1', entitlements[index]?.new_issue_type)
            formik.setFieldValue('cash_in_lieu_payment_rate1', entitlements[index]?.cash_in_lieu_payment_rate)
            formik.setFieldValue('cash_in_lieu_currency1', entitlements[index]?.cash_in_lieu_currency)
            formik.setFieldValue('number_of_decimal_places1', entitlements[index]?.number_of_decimal_places)
            setactiveEntitlement(index + 1)
        } else {
            setactiveEntitlement(1)
            formik.setFieldValue('units_held', entitlements[index]?.units_held || formik.values.units_held)
            formik.setFieldValue('units_entitled', entitlements[index]?.units_entitled || formik.values.units_entitled)
            formik.setFieldValue('current_issue_name', entitlements[index]?.current_issue_name || formik.values.current_issue_name)
            formik.setFieldValue('current_issue_type', entitlements[index]?.current_issue_type || formik.values.current_issue_type)
            formik.setFieldValue('fractional_treatment', entitlements[index]?.fractional_treatment || formik.values.fractional_treatment)
            formik.setFieldValue('new_issue_name', entitlements[index]?.new_issue_name || formik.values.new_issue_name || '')
            formik.setFieldValue('new_issue_type', entitlements[index]?.new_issue_type || formik.values.new_issue_type || '')
            formik.setFieldValue('direct_deposit', entitlements[index]?.direct_deposit || formik.values.direct_deposit)
            formik.setFieldValue('by_wire', entitlements[index]?.by_wire || formik.values.by_wire)
            formik.setFieldValue('ach', entitlements[index]?.ach || formik.values.ach)
        }
    }

    const handleEntitleClose = () => {
        if (activeEntitlement > 1) {
            const list = []
            entitlements?.forEach(element => {
                if (e_id === element?.id) {
                    const entitle = {
                        "id": element?.id,
                        "units_held": formik.values.units_held1,
                        "units_entitled": formik.values.units_entitled1,
                        "fractional_treatment": formik.values.fractional_treatment1,
                        "new_issue_name": formik.values.new_issue_name1,
                        "new_issue_type": formik.values.new_issue_type1,
                    }
                    if (formik.values.fractional_treatment1 === "keep") {
                        entitle['number_of_decimal_places'] = formik.values.number_of_decimal_places1
                    }
                    if (formik.values.fractional_treatment1 === "cash_in_lieu") {
                        entitle['cash_in_lieu_payment_rate'] = formik.values.cash_in_lieu_payment_rate1
                        entitle['cash_in_lieu_currency'] = formik.values.cash_in_lieu_currency1
                    }
                    list.push(entitle)
                } else {
                    list.push(element)
                }
            });
            setentitlements(list)
            formik.setFieldValue('units_held1', 0)
            formik.setFieldValue('units_entitled1', 0)
            formik.setFieldValue('fractional_treatment1', 'roundup')
            formik.setFieldValue('new_issue_name1', '')
            formik.setFieldValue('new_issue_type1', '')
            formik.setFieldValue('cash_in_lieu_payment_rate1', '')
            formik.setFieldValue('cash_in_lieu_currency1', '')
            formik.setFieldValue('number_of_decimal_places1', '')
            setactiveEntitlement(1)
            setE_ID(null)
            setisEntitlement(false)
        } else {
            setE_ID(null)
            setisEntitlement(false)
        }
    }

    const handleChangeLocal = async (file: any, key: string) => {
        if (file) {
            const res = await handleUpload(file.target.files[0])
            console.log('resres', res);
            if (res.status) {
                formik.setFieldValue(key, res.data.location)
                formik.setFieldValue(key + '_name', res.data.key)
                const message = key === 'logo' ? 'logo uploaded!' : key === 'letter_of_transmittal' ? 'letter of transmittal uploaded!' : key === 'warrant_document' ? 'warrant document uploaded!' : key === 'warrant_payment_instruction' ? "warrant payment instruction uploaded!" : key === 'other_document' ? "other document uploaded!" : 'exercise form uploaded!'
                showToast(message, TOAST_TYPE.success)
            } else {
                showToast('Error uploading logo, Try Again!', TOAST_TYPE.error)
            }
        }
    }


    const [searchedInputStatus, setsearchedInputStatus] =
        useState<boolean>(false)
    const [searchedList, setsearchedList] = useState<
        {
            client_company_name: string
            contact_person_name: string
            address: string
            telephone: string
            email: string
        }[]
    >([])



    const onEnterInput = (e: any, searchedBy: string, input: boolean) => {
        setsearchedInputStatus(input)

        e.target.value !== ''
            ? setsearchedList(
                clients?.filter((item: any) =>
                    item[`${searchedBy}`]
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            )
            : setsearchedList([])
    }

    const formik = useFormik({
        initialValues: {
            client_id: '',
            color_palette: '',
            event_name: '',
            email: '',
            // logo: '',
            letter_of_transmittal: '',
            warrant_document: '',
            exercise_form: '',
            other_document: '',
            warrant_payment_instruction: '',
            description: '',
            term_and_conditions_of_portal: false,
            terms_and_conditions_of_event: '',
            current_issue_name: '',
            current_issue_type: '',
            new_issue_name: '',
            new_issue_type: '',
            status: 'pending',
            physical_certificate: false,
            offer_econsent: false,
            extra_validation_required: false,
            signing_authority_required: false,
            units_held: '',
            units_entitled: '',
            fractional_treatment: 'roundup',
            number_of_decimal_places: '',
            cash_in_lieu_payment_rate: '',
            cash_in_lieu_currency: '',
            entitlement: '',
            event_start_date: '',
            event_end_date: '',
            event_end_date_text: '',
            eventtype_id: '',
            issue_type: '1',
            ask_residency_holder_status_question: false,
            tax_status: 'w9',
            w9_from_us_holder: false,
            w8_from_non_us_holder: false,
            accredited_investor_info_required: false,
            is_transfer_allowed: false,
            holder_make_an_election: false,
            securityholder_election_option: '1',
            question: '',
            payment: 'cheque',
            currency: '',
            payment_rate: '',
            exercise_terms: '',
            by_wire_fee: '',
            direct_deposit_fee: '',
            ach_fee: '',
            by_wire: false,
            by_cheque: true,
            direct_deposit: false,
            ach: false,
            new_issue_name1: '',
            new_issue_type1: '',
            units_held1: '',
            units_entitled1: '',
            fractional_treatment1: 'roundup',
            number_of_decimal_places1: 0,
            cash_in_lieu_payment_rate1: '',
            cash_in_lieu_currency1: '',
            entitlements: [],
            allow_deliver_to_alternate_mailing_address: false,
            allow_hold_for_pickup: false
        },
        validationSchema: Yup.object({
            color_palette: Yup.string().required('color is required'),
            event_name: Yup.string().required('event name is required'),
            description: Yup.string().required('description is required'),
            client_id: Yup.string().required('client is required'),
            terms_and_conditions_of_event: Yup.string().required('event of terms and conditions is required'),
            // logo: Yup.string().required('logo is required'),
            // warrant_document: Yup.string().required('warrant document is required'),
            // exercise_form: Yup.string().required('exercise form is required'),
            // letter_of_transmittal: Yup.string().required('letter of transmittal is required'),
            ach_fee: Yup.number().when('ach', {
                is: (value) => value === true,
                then: Yup.number()
                    .required('ach fee is required'),
            }),
            by_wire_fee: Yup.number().when(['by_wire', 'eventtype_id'], {
                is: (value, eventtype_id) => eventtype_id === '3' && value === true,
                then: Yup.number()
                    .required('by_wire fee is required'),
            }),
            direct_deposit_fee: Yup.number().when('direct_deposit', {
                is: (value) => value === true,
                then: Yup.number()
                    .required('direct deposit fee is required'),
            }),
            payment_rate: Yup.number().when('eventtype_id', {
                is: (value) => value === '2',
                then: Yup.number()
                    .required('payment rate is required'),
            }),
            currency: Yup.string().when('eventtype_id', {
                is: (value) => value === '2',
                then: Yup.string().required('currency is required'),
            }),
            // issue_type: Yup.string().when('eventtype_id', {
            //     is: (value) => value === '3',
            //     then: Yup.string().required('entitlement is required'),
            // }),
            units_entitled: Yup.number().when('eventtype_id', {
                is: (value) => value === '1',
                then: Yup.number()
                    .required('units entitled is required'),
            }),
            units_held: Yup.number().when('eventtype_id', {
                is: (value) => value === '1',
                then: Yup.number()
                    .required('units held is required'),
            }),
            exercise_terms: Yup.string().when('eventtype_id', {
                is: (value) => value === '2',
                then: Yup.string().required('exercise terms type is required'),
            }),
            new_issue_type: Yup.string().when('eventtype_id', {
                is: (value) => value !== '3',
                then: Yup.string().required('new issue type is required'),
            }),
            new_issue_name: Yup.string().when('eventtype_id', {
                is: (value) => value !== '3',
                then: Yup.string().required('new issue name is required'),
            }),
            current_issue_type: Yup.string().when('eventtype_id', {
                is: (value) => value !== '',
                then: Yup.string().required('current issue type is required'),
            }),
            current_issue_name: Yup.string().when('eventtype_id', {
                is: (value) => value !== '',
                then: Yup.string().required('current issue name is required'),
            }),
            entitlement: Yup.number().when('eventtype_id', {
                is: (value) => value === '3',
                then: Yup.number().integer()
                    .min(1).max(9).required('entitlement is required'),
            }),
            number_of_decimal_places: Yup.number().when('fractional_treatment', {
                is: (value) => value === 'keep',
                then: Yup.number().integer()
                    .min(1).max(10).required('number of decimal places is required'),
            }),
            cash_in_lieu_payment_rate: Yup.number().when('fractional_treatment', {
                is: (value) => value === 'cash_in_lieu',
                then: Yup.number()
                    .required('cash in lieu payment rate is required'),
            }),
            cash_in_lieu_currency: Yup.string().when('fractional_treatment', {
                is: (value) => value === 'cash_in_lieu',
                then: Yup.string().required('cash in lieu currency is required'),
            }),
        }),
        onSubmit: async (values) => {
            console.log('valuesvalues', values);

            setLoading(true)
            const {
                client_id,
                color_palette,
                email,
                description,
                term_and_conditions_of_portal,
                terms_and_conditions_of_event,
                current_issue_name,
                current_issue_type,
                new_issue_name,
                new_issue_type,
                status,
                physical_certificate,
                offer_econsent,
                extra_validation_required,
                signing_authority_required,
                units_held,
                units_entitled,
                fractional_treatment,
                cash_in_lieu_payment_rate,
                number_of_decimal_places,
                cash_in_lieu_currency,
                event_start_date,
                event_end_date,
                event_end_date_text,
                eventtype_id,
                issue_type,
                entitlement,
                question,
                payment,
                currency,
                payment_rate,
                exercise_terms,
                holder_make_an_election,
                ask_residency_holder_status_question,
                accredited_investor_info_required,
                tax_status,
                is_transfer_allowed,
                by_wire,
                by_cheque,
                securityholder_election_option,
                // logo,
                letter_of_transmittal,
                warrant_document,
                exercise_form,
                warrant_payment_instruction,
                event_name,
                other_document,
                w8_from_non_us_holder,
                w9_from_us_holder,
                direct_deposit,
                ach,
                direct_deposit_fee,
                ach_fee,
                by_wire_fee,
                allow_deliver_to_alternate_mailing_address,
                allow_hold_for_pickup
            }
                = values
            const payload = {
                client_id,
                // event_name: event_types?.filter(e => e.id === Number(eventtype_id))[0].name,
                event_name,
                // logo,
                color_palette,
                email,
                description,
                term_and_conditions_of_portal,
                terms_and_conditions_of_event,
                current_issue_name,
                current_issue_type,
                new_issue_name,
                new_issue_type,
                status,
                physical_certificate,
                offer_econsent,
                extra_validation_required,
                signing_authority_required,
                event_start_date: mode ? moment.utc(event_start_date).format() : event_start_date ? moment(event_start_date).format('YYYY-MM-DD') : '',
                eventtype_id,
                event_end_date: mode ? moment.utc(event_end_date).format() : event_end_date ? moment(event_end_date).format('YYYY-MM-DD') : '',
                event_end_date_text,
                question,
                currency,
                exercise_terms,
                is_transfer_allowed,
                accredited_investor_info_required,
                allow_deliver_to_alternate_mailing_address,
                allow_hold_for_pickup
            }
            if (!event_end_date) {
                delete payload['event_end_date']
            }
            if (!event_start_date) {
                delete payload['event_start_date']
            }
            if (other_document) {
                payload['other_document'] = other_document
            }
            if (fractional_treatment === 'cash_in_lieu') {
                payload['cash_in_lieu_payment_rate'] = cash_in_lieu_payment_rate
                payload['cash_in_lieu_currency'] = cash_in_lieu_currency
            }
            if (fractional_treatment === 'keep') {
                payload['number_of_decimal_places'] = number_of_decimal_places
            }
            if (eventtype_id !== '1') {
                payload['payment_rate'] = payment_rate || 0
            }
            if (eventtype_id !== '2') {
                payload['letter_of_transmittal'] = letter_of_transmittal
            }
            if (eventtype_id === '2') {
                payload['warrant_document'] = warrant_document
                payload['exercise_form'] = exercise_form
                payload['warrant_payment_instruction'] = warrant_payment_instruction
                // payload['payment'] = payment
                payload['by_cheque'] = by_cheque
                payload['by_wire'] = by_wire
                payload['ask_residency_holder_status_question'] = ask_residency_holder_status_question
            }
            if (eventtype_id === '3') {
                payload['ask_residency_holder_status_question'] = ask_residency_holder_status_question
                payload['w9_from_us_holder'] = w9_from_us_holder
                payload['w8_from_non_us_holder'] = w8_from_non_us_holder
                payload['number_of_entitlement'] = entitlement
                payload['holder_make_an_election'] = holder_make_an_election
                payload['by_cheque'] = by_cheque
                payload['by_wire'] = by_wire
                payload['direct_deposit'] = direct_deposit
                payload['ach'] = ach
                payload['by_wire_fee'] = by_wire_fee || 0
                payload['direct_deposit_fee'] = direct_deposit_fee || 0
                payload['ach_fee'] = ach_fee || 0
                payload['payment'] = payment
                payload['entitlement'] = issue_type === '1' ? "securities" : issue_type === '2' ? 'cash' : 'securities_and_cash'
                if (issue_type === '2') {
                    delete payload['new_issue_name']
                    delete payload['new_issue_type']
                }
                if (issue_type === '3') {
                    payload['securityholder_election_option'] = securityholder_election_option
                }
                if (!mode) {
                    const obj = issue_type === '2' ? {
                        current_issue_name,
                        current_issue_type,
                        direct_deposit,
                        by_wire,
                        by_wire_fee: by_wire_fee || 0,
                        ach_fee: ach_fee || 0,
                        payment_rate: payment_rate || 0,
                        currency: currency,
                        direct_deposit_fee: direct_deposit_fee || 0,
                        ach
                    } : {
                        current_issue_name,
                        current_issue_type,
                        units_held,
                        units_entitled,
                        new_issue_name,
                        new_issue_type,
                        fractional_treatment,
                        direct_deposit,
                        by_wire,
                        by_wire_fee: by_wire_fee || 0,
                        direct_deposit_fee: direct_deposit_fee || 0,
                        ach_fee: ach_fee || 0,
                        ach
                    }
                    const list = [obj, ...entitlements]
                    payload['entitlements'] = list
                }
                if (mode && entitlements?.length > 0) {
                    const list = []
                    entitlements?.forEach((element, index) => {
                        if (index === 0) {
                            const entitle = {
                                "id": element?.id,
                                "units_held": formik.values.units_held,
                                "units_entitled": formik.values.units_entitled,
                                "current_issue_name": formik.values.current_issue_name,
                                "current_issue_type": formik.values.current_issue_type,
                                "new_issue_name": formik.values.new_issue_name,
                                "new_issue_type": formik.values.new_issue_type,
                                "fractional_treatment": formik.values.fractional_treatment,
                                "direct_deposit": formik.values.direct_deposit,
                                "by_wire": formik.values.by_wire,
                                "ach": formik.values.ach,
                            }
                            list.push(entitle)
                        } else {
                            list.push(element)
                        }
                    });
                    payload['entitlements'] = list
                }
                if (mode && new_entitlements?.length > 0) {
                    payload['new_entitlements'] = new_entitlements
                }
            }
            if (eventtype_id === '1') {
                payload['fractional_treatment'] = fractional_treatment
                payload['units_entitled'] = units_entitled
                payload['units_held'] = units_held
            }
            if (eventtype_id === '3' && issue_type !== '2') {
                payload['fractional_treatment'] = fractional_treatment
                payload['units_entitled'] = units_entitled
                payload['units_held'] = units_held
            }
            console.log('payload', payload);
            if (mode) {
                payload['id'] = id
                const res = await dispatch(onUpdateEvent(payload, navigate))
                console.log('onUpdateEvent', res?.payload);
                if (res?.payload?.event_id) {
                    navigate(`/admin-events/${res?.payload?.event_id}?name=${res?.payload?.client_company_name}`)
                }
                setLoading(false)
            } else {
                const res = await dispatch(onCreateEvent(payload, navigate))
                console.log('onCreateEvent', res?.payload);
                if (res?.payload?.event_id) {
                    navigate(`/admin-events/${res?.payload?.event_id}?name=${res?.payload?.client_company_name}`)
                }
                setLoading(false)
            }

        },
    })

    const _deleteEntitlement = async () => {
        try {
            setEntitlementLoading(true)
            const res = await dispatch(deleteEntitlement(e_id, `?event_id=${id}`, navigate))
            const payload = { id, number_of_entitlement: Number(formik?.values?.entitlement) - 1 }
            await dispatch(onUpdateEvent(payload, navigate))
            setisEntitlement(false)
            getDetails()
            setE_ID(null)
            setEntitlementLoading(false)
        } catch (error) {
            setEntitlementLoading(false)
            alert(JSON.stringify(error?.response?.data))
        }
    }

    const getClientsList = () => {
        return (
            <>
                <option value={''}>
                    Select Client
                </option>
                {
                    clients?.results?.map((client, index) => (
                        <option key={index} value={client?.id}>
                            {client?.client_company_name}
                        </option>
                    ))
                }
            </>
        )
    }

    const getEventTypeList = () => {
        return (
            <>
                <option value={''}>
                    Select Event Type
                </option>
                {
                    event_types?.map((type, index) => (
                        <option key={index} value={type?.e_type}>
                            {type?.name}
                        </option>
                    ))
                }
            </>
        )
    }

    const getCurrencyList = () => {
        return (
            <>
                <option value={''}>Select Currency</option>
                <option value={'CAD'}>CAD</option>
                <option value={'USD'}>USD</option>
                <option value={'EUR'}>EUR</option>
                <option value={'GBP'}>GBP</option>
            </>
        )
    }

    const handleClick = event => {
        if (event === 'hiddenFileInput') {
            hiddenFileInput.current.click();
        } else if (event === 'hiddenFileInput1') {
            hiddenFileInput1.current.click();
        } else if (event === 'hiddenFileInput2') {
            hiddenFileInput2.current.click();
        } else if (event === 'hiddenFileInput3') {
            hiddenFileInput3.current.click();
        } else if (event === 'hiddenFileInput4') {
            hiddenFileInput4.current.click();
        } else if (event === 'hiddenFileInput5') {
            hiddenFileInput5.current.click();
        }
    };

    if (isEntitlement) {
        return (
            <div className="page-padding py-10">
                <div
                    className="border border-gray2 p-10 space-y-4">
                    <div className="text-xl lg:text-2xl text-blue-base Roboto-Bold">
                        Entitlement {activeEntitlement}
                    </div>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="grid md:grid-cols-1 gap-8">
                            {
                                activeEntitlement === 1 &&
                                <>
                                    <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                        <Checkbox
                                            text="Issue Securities"
                                            name="issue_type"
                                            id="RegisteredAddress1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'1'}
                                            stateVal={formik.values.issue_type}
                                        />
                                        <>
                                            <Checkbox
                                                text="Issue Cash"
                                                name="issue_type"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'2'}
                                                stateVal={formik.values.issue_type}
                                            />
                                            <Checkbox
                                                text="Issue Securities and Cash"
                                                name="issue_type"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'3'}
                                                stateVal={formik.values.issue_type}
                                            />
                                        </>
                                    </div>
                                    <Checkbox
                                        text="Holder is required to make an election for securities"
                                        name="holder_make_an_election"
                                        type="checkbox"
                                        id="RegisteredAddress1"
                                        disabled={formik.values.issue_type !== '3'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.holder_make_an_election}
                                    />
                                    {
                                        formik.values.holder_make_an_election &&
                                        <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">

                                            <Checkbox
                                                text="Securities or Cash"
                                                name="securityholder_election_option"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'1'}
                                                stateVal={formik.values.securityholder_election_option}
                                            />
                                            <Checkbox
                                                text="Securities or Cash or Securities and Cash"
                                                name="securityholder_election_option"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'2'}
                                                stateVal={formik.values.securityholder_election_option}
                                            />
                                        </div>
                                    }
                                    <div className="grid grid-cols-2 flex items-center space-x-4">
                                        <Input
                                            placeholder="Current (Old) Issue Name"
                                            name="current_issue_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.current_issue_name}
                                            errors={formik.errors.current_issue_name}
                                            touched={formik?.touched?.current_issue_name}
                                            width="full"
                                        />
                                        <Input
                                            placeholder="Current Issue Type"
                                            name="current_issue_type"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.current_issue_type}
                                            errors={formik.errors.current_issue_type}
                                            touched={formik?.touched?.current_issue_type}
                                            width="full"
                                        />
                                    </div>
                                    {
                                        formik.values.issue_type !== '2'
                                        &&
                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="New Issue Name"
                                                name="new_issue_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.new_issue_name}
                                                errors={formik.errors.new_issue_name}
                                                touched={formik?.touched?.new_issue_name}
                                                width="full"
                                            />
                                            <Input
                                                placeholder="New Issue Type"
                                                name="new_issue_type"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.new_issue_type}
                                                errors={formik.errors.new_issue_type}
                                                touched={formik?.touched?.new_issue_type}
                                                width="full"
                                            />
                                        </div>
                                    }
                                    {
                                        formik.values.issue_type !== '2'
                                        &&
                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="Units Held"
                                                name="units_held"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.units_held}
                                                errors={formik.errors.units_held}
                                                touched={formik?.touched?.units_held}
                                                width="full"
                                            />
                                            <Input
                                                placeholder="Units Entitled "
                                                name="units_entitled"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.units_entitled}
                                                errors={formik.errors.units_entitled}
                                                touched={formik?.touched?.units_entitled}
                                                width="full"
                                            />
                                        </div>
                                    }
                                    {
                                        formik.values.issue_type !== '2'
                                        &&
                                        <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                            <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                                Fractional Treatment:
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-8">
                                                <Checkbox
                                                    text="Round up"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'roundup'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Drop"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'drop'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Nearest"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'nearest'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Cash in LIEU"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'cash_in_lieu'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Keep"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'keep'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        formik.values.fractional_treatment === 'keep' &&
                                        <div className="grid grid-cols-1 flex items-center space-x-4">
                                            <Input
                                                placeholder="Number of decimal places"
                                                type={'number'}
                                                name="number_of_decimal_places"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.number_of_decimal_places}
                                                errors={formik.errors.number_of_decimal_places}
                                                touched={formik.touched.number_of_decimal_places}
                                                width="full"
                                            />
                                        </div>
                                    }
                                    {
                                        formik.values.fractional_treatment === 'cash_in_lieu' &&

                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="Cash in LIEU rate"
                                                name="cash_in_lieu_payment_rate"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type={'number'}
                                                value={formik.values.cash_in_lieu_payment_rate}
                                                errors={formik.errors.cash_in_lieu_payment_rate}
                                                touched={formik.touched.cash_in_lieu_payment_rate}
                                                width="full"
                                            />
                                            <SelectWithLabel
                                                placeholder="Currency"
                                                name="cash_in_lieu_currency"
                                                onChange={formik.handleChange}
                                                errors={formik.errors.cash_in_lieu_currency}
                                                touched={formik.touched.cash_in_lieu_currency}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cash_in_lieu_currency}
                                                width="full"
                                                customList={getCurrencyList()}
                                            />
                                        </div>
                                    }
                                    {
                                        formik.values.issue_type !== '1' &&
                                        <>

                                            <div className="grid grid-cols-2 flex items-center space-x-4">
                                                <Input
                                                    placeholder="Payment rate"
                                                    name="payment_rate"
                                                    type={'number'}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.payment_rate}
                                                    errors={formik.errors.payment_rate}
                                                    touched={formik.touched.payment_rate}
                                                    width="full"
                                                />
                                                <SelectWithLabel
                                                    placeholder="Currency"
                                                    name="currency"
                                                    onChange={formik.handleChange}
                                                    errors={formik.errors.currency}
                                                    touched={formik.touched.currency}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.currency}
                                                    width="full"
                                                    customList={getCurrencyList()}
                                                />
                                            </div>
                                            <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                                    Payment:
                                                </div>
                                                <div className="grid md:grid-cols-2 gap-8">
                                                    <Checkbox
                                                        text="By cheque"
                                                        name="by_cheque"
                                                        id="RegisteredAddress1"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type='checkbox'
                                                        value={true}
                                                        stateVal={formik.values.by_cheque}
                                                    />
                                                    <Checkbox
                                                        text="Direct Deposit"
                                                        name="direct_deposit"
                                                        id="RegisteredAddress2"
                                                        onChange={formik.handleChange}
                                                        type='checkbox'
                                                        onBlur={formik.handleBlur}
                                                        value={true}
                                                        stateVal={formik.values.direct_deposit}
                                                    />
                                                    <Checkbox
                                                        text="By wire"
                                                        name="by_wire"
                                                        type='checkbox'
                                                        id="RegisteredAddress1"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={true}
                                                        stateVal={formik.values.by_wire}
                                                    />
                                                    <Checkbox
                                                        text="ACH"
                                                        name="ach"
                                                        type='checkbox'
                                                        id="RegisteredAddress2"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={true}
                                                        stateVal={formik.values.ach}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    formik.values.direct_deposit &&
                                                    <div className="mt-1">
                                                        <Input
                                                            placeholder="Direct Deposit Fee"
                                                            name="direct_deposit_fee"
                                                            type={'number'}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.direct_deposit_fee}
                                                            errors={formik.errors.direct_deposit_fee}
                                                            touched={formik?.touched?.direct_deposit_fee}
                                                            width="full"
                                                        />
                                                    </div>
                                                }
                                                {
                                                    formik.values.by_wire &&
                                                    <div className="mt-4">
                                                        <Input
                                                            placeholder="By Wire Fee"
                                                            name="by_wire_fee"
                                                            type={'number'}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.by_wire_fee}
                                                            errors={formik.errors.by_wire_fee}
                                                            touched={formik?.touched?.by_wire_fee}
                                                            width="full"
                                                        />
                                                    </div>
                                                }
                                                {
                                                    formik.values.ach &&
                                                    <div className="mt-4">
                                                        <Input
                                                            placeholder="ACH Fee"
                                                            type={'number'}
                                                            name="ach_fee"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.ach_fee}
                                                            errors={formik.errors.ach_fee}
                                                            touched={formik?.touched?.ach_fee}
                                                            width="full"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        activeEntitlement === 1 &&
                                        <>

                                            <Checkbox
                                                text="Ask Residency Question and Tax Status requirement"
                                                name="ask_residency_holder_status_question"
                                                type="checkbox"
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={true}
                                                stateVal={formik.values.ask_residency_holder_status_question}
                                            />
                                            {
                                                formik.values.ask_residency_holder_status_question &&
                                                <>
                                                    <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                                            Tax Status:
                                                        </div>
                                                        <div className="grid md:grid-cols-1 gap-8">
                                                            <Checkbox
                                                                text="W9 from US residents"
                                                                name="w9_from_us_holder"
                                                                id="RegisteredAddress1"
                                                                type='checkbox'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={true}
                                                                stateVal={formik.values.w9_from_us_holder}
                                                            />
                                                            <Checkbox
                                                                text="W8 from non-US residents"
                                                                name="w8_from_non_us_holder"
                                                                type='checkbox'
                                                                id="RegisteredAddress2"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={true}
                                                                stateVal={formik.values.w8_from_non_us_holder}
                                                            />
                                                            {/* <Checkbox
                                                                text="None"
                                                                name="tax_status"
                                                                id="RegisteredAddress1"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={'none'}
                                                                stateVal={formik.values.tax_status}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                    <Checkbox
                                                        text="Accredited investor info needs to be collected"
                                                        name="accredited_investor_info_required"
                                                        type="checkbox"
                                                        id="RegisteredAddress1"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={true}
                                                        stateVal={formik.values.accredited_investor_info_required}
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {
                                activeEntitlement > 1 &&
                                <>
                                    <Checkbox
                                        text="Issue Securities"
                                        name="issue_type"
                                        id="RegisteredAddress1"
                                        onChange={() => console.log('')}
                                        onBlur={() => console.log('')}
                                        value={'1'}
                                        stateVal={'1'}
                                    />
                                    <div className="grid grid-cols-2 flex items-center space-x-4">

                                        <Input
                                            placeholder="New Issue Name"
                                            name="new_issue_name1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.new_issue_name1}
                                            errors={formik.errors.new_issue_name1}
                                            touched={formik?.touched?.new_issue_name1}
                                            width="full"
                                        />
                                        <Input
                                            placeholder="New Issue Type"
                                            name="new_issue_type1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.new_issue_type1}
                                            errors={formik.errors.new_issue_type1}
                                            touched={formik?.touched?.new_issue_type1}
                                            width="full"
                                        />
                                    </div>
                                    <div className="grid grid-cols-2 flex items-center space-x-4">
                                        <Input
                                            placeholder="Units Held"
                                            name="units_held1"
                                            type="number"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.units_held1}
                                            errors={formik.errors.units_held1}
                                            touched={formik?.touched?.units_held1}
                                            width="full"
                                        />
                                        <Input
                                            placeholder="Units Entitled "
                                            name="units_entitled1"
                                            type="number"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.units_entitled1}
                                            errors={formik.errors.units_entitled1}
                                            touched={formik?.touched?.units_entitled1}
                                            width="full"
                                        />
                                    </div>
                                    <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                            Fractional Treatment:
                                        </div>
                                        <div className="grid md:grid-cols-2 gap-8">
                                            <Checkbox
                                                text="Round up"
                                                name="fractional_treatment"
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'roundup'}
                                                stateVal={formik.values.fractional_treatment1}
                                            />
                                            <Checkbox
                                                text="Drop"
                                                name="fractional_treatment1"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'drop'}
                                                stateVal={formik.values.fractional_treatment1}
                                            />
                                            <Checkbox
                                                text="Nearest"
                                                name="fractional_treatment1"
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'nearest'}
                                                stateVal={formik.values.fractional_treatment1}
                                            />
                                            <Checkbox
                                                text="Cash in LIEU"
                                                name="fractional_treatment1"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'cash_in_lieu'}
                                                stateVal={formik.values.fractional_treatment1}
                                            />
                                            <Checkbox
                                                text="Keep"
                                                name="fractional_treatment1"
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'keep'}
                                                stateVal={formik.values.fractional_treatment1}
                                            />
                                        </div>
                                    </div>
                                    {
                                        formik.values.fractional_treatment1 === 'keep' &&
                                        <div className="grid grid-cols-1 flex items-center space-x-4">
                                            <Input
                                                placeholder="Number of decimal places"
                                                type={'number'}
                                                name="number_of_decimal_places1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.number_of_decimal_places1}
                                                errors={formik.errors.number_of_decimal_places1}
                                                touched={formik.touched.number_of_decimal_places1}
                                                width="full"
                                            />
                                        </div>
                                    }
                                    {
                                        formik.values.fractional_treatment1 === 'cash_in_lieu' &&

                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="Cash in LIEU rate"
                                                name="cash_in_lieu_payment_rate1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type={'number'}
                                                value={formik.values.cash_in_lieu_payment_rate1}
                                                errors={formik.errors.cash_in_lieu_payment_rate1}
                                                touched={formik.touched.cash_in_lieu_payment_rate1}
                                                width="full"
                                            />
                                            <SelectWithLabel
                                                placeholder="Currency"
                                                name="cash_in_lieu_currency1"
                                                onChange={formik.handleChange}
                                                errors={formik.errors.cash_in_lieu_currency1}
                                                touched={formik.touched.cash_in_lieu_currency1}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cash_in_lieu_currency1}
                                                width="full"
                                                customList={getCurrencyList()}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>

                    </div>
                    <div style={{ height: '100%' }} className="flex h-15 xs:h-15 md:h-15 justify-end">
                        {(mode && e_id) ? <button
                            onClick={() => {
                                if (e_id) {
                                    _deleteEntitlement()
                                } else {
                                    showToast('Select Entitlement first', TOAST_TYPE.error)
                                }
                            }}
                            style={{ height: 40 }}
                            className="bg-brown text-white px-20 mr-3 h-full">
                            {entitlementLoading ? <CircularProgress style={{ color: "#fff", width: 20, height: 20 }} /> : 'Delete'}
                        </button> : null}
                        <button
                            onClick={() => {
                                if (mode) {
                                    handleEntitleClose()
                                    return
                                }
                                if (activeEntitlement === 1) {
                                    setisEntitlement(false)
                                    return
                                }
                                formik.setFieldValue('units_held1', entitlements[activeEntitlement - 3]?.units_held)
                                formik.setFieldValue('units_entitled1', entitlements[activeEntitlement - 3]?.units_entitled)
                                formik.setFieldValue('fractional_treatment1', entitlements[activeEntitlement - 3]?.fractional_treatment)
                                formik.setFieldValue('new_issue_name1', entitlements[activeEntitlement - 3]?.new_issue_name)
                                formik.setFieldValue('new_issue_type1', entitlements[activeEntitlement - 3]?.new_issue_type)
                                formik.setFieldValue('cash_in_lieu_payment_rate1', entitlements[activeEntitlement - 3]?.cash_in_lieu_payment_rate)
                                formik.setFieldValue('cash_in_lieu_currency1', entitlements[activeEntitlement - 3]?.cash_in_lieu_currency)
                                formik.setFieldValue('number_of_decimal_places1', entitlements[activeEntitlement - 3]?.number_of_decimal_places)
                                entitlements.pop()
                                setentitlements([...entitlements])
                                setactiveEntitlement(activeEntitlement - 1)
                            }}
                            style={{ height: 40 }}
                            className="bg-brown text-white px-20 mr-3 h-full">
                            {'Back'}
                        </button>
                        {
                            (!mode || mode && Number(formik.values.entitlement) > formik.values.entitlements.length) &&
                            <button
                                onClick={() => {
                                    // if (mode) {
                                    //     handleEntitleClose()
                                    //     return
                                    // }
                                    if (activeEntitlement > 1) {
                                        if (!formik.values.units_held1) {
                                            showToast('Units held is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.units_entitled1) {
                                            showToast('Units entitled is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.new_issue_name1) {
                                            showToast('New issue name is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.new_issue_type1) {
                                            showToast('New issue type is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.fractional_treatment1 === "keep" && !formik.values.number_of_decimal_places1) {
                                            showToast('Number of decimal places is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.fractional_treatment1 === "cash_in_lieu" && !formik.values.cash_in_lieu_payment_rate1) {
                                            showToast('Cash in lieu payment rate is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.fractional_treatment1 === "cash_in_lieu" && !formik.values.cash_in_lieu_currency1) {
                                            showToast('Cash in lieu currency rate is required', TOAST_TYPE.error)
                                            return
                                        }
                                    }
                                    if (Number(formik.values.entitlement) === activeEntitlement) {
                                        const entitle = {
                                            "units_held": formik.values.units_held1,
                                            "units_entitled": formik.values.units_entitled1,
                                            "fractional_treatment": formik.values.fractional_treatment1,
                                            "new_issue_name": formik.values.new_issue_name1,
                                            "new_issue_type": formik.values.new_issue_type1,
                                        }
                                        if (formik.values.fractional_treatment1 === "keep") {
                                            entitle['number_of_decimal_places'] = formik.values.number_of_decimal_places1
                                        }
                                        if (formik.values.fractional_treatment1 === "cash_in_lieu") {
                                            entitle['cash_in_lieu_payment_rate'] = formik.values.cash_in_lieu_payment_rate1
                                            entitle['cash_in_lieu_currency'] = formik.values.cash_in_lieu_currency1
                                        }
                                        if (activeEntitlement > 1) {
                                            if (mode) {
                                                setnew_entitlements([...new_entitlements, entitle])
                                            } else {
                                                setentitlements([...entitlements, entitle])
                                            }
                                            formik.setFieldValue('units_held1', 0)
                                            formik.setFieldValue('units_entitled1', 0)
                                            formik.setFieldValue('fractional_treatment1', 'roundup')
                                            formik.setFieldValue('new_issue_name1', '')
                                            formik.setFieldValue('new_issue_type1', '')
                                            formik.setFieldValue('cash_in_lieu_payment_rate1', '')
                                            formik.setFieldValue('cash_in_lieu_currency1', '')
                                            formik.setFieldValue('number_of_decimal_places1', '')
                                        }
                                        setisEntitlement(false)
                                        setCompleteEntitlement(true)
                                        setactiveEntitlement(1)
                                    } else if (activeEntitlement === 1) {
                                        if (!formik.values.units_held) {
                                            showToast('Units held is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.units_entitled) {
                                            showToast('Units entitled is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.current_issue_name) {
                                            showToast('Current issue name is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.current_issue_type) {
                                            showToast('Current issue type is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.new_issue_name) {
                                            showToast('New issue name is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (!formik.values.new_issue_type) {
                                            showToast('New issue type is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.fractional_treatment === "keep" && !formik.values.number_of_decimal_places) {
                                            showToast('Number of decimal places is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.fractional_treatment === "cash_in_lieu" && !formik.values.cash_in_lieu_payment_rate) {
                                            showToast('Cash in lieu payment rate is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.fractional_treatment === "cash_in_lieu" && !formik.values.cash_in_lieu_currency) {
                                            showToast('Cash in lieu currency rate is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.direct_deposit && !formik.values.direct_deposit_fee) {
                                            showToast('direct deposit fee is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.by_wire && !formik.values.by_wire_fee) {
                                            showToast('by wire fee is required', TOAST_TYPE.error)
                                            return
                                        }
                                        if (formik.values.ach && !formik.values.ach_fee) {
                                            showToast('ach fee is required', TOAST_TYPE.error)
                                            return
                                        }
                                        setactiveEntitlement(activeEntitlement + 1)
                                    } else {
                                        const entitle = {
                                            "units_held": formik.values.units_held1,
                                            "units_entitled": formik.values.units_entitled1,
                                            "fractional_treatment": formik.values.fractional_treatment1,
                                            "new_issue_name": formik.values.new_issue_name1,
                                            "new_issue_type": formik.values.new_issue_type1,
                                        }
                                        if (formik.values.fractional_treatment1 === "keep") {
                                            entitle['number_of_decimal_places'] = formik.values.number_of_decimal_places1
                                        }
                                        if (formik.values.fractional_treatment1 === "cash_in_lieu") {
                                            entitle['cash_in_lieu_payment_rate'] = formik.values.cash_in_lieu_payment_rate1
                                            entitle['cash_in_lieu_currency'] = formik.values.cash_in_lieu_currency1
                                        }
                                        if (mode) {
                                            setnew_entitlements([...new_entitlements, entitle])
                                        } else {
                                            setentitlements([...entitlements, entitle])
                                        }
                                        formik.setFieldValue('units_held1', 0)
                                        formik.setFieldValue('units_entitled1', 0)
                                        formik.setFieldValue('fractional_treatment1', 'roundup')
                                        formik.setFieldValue('new_issue_name1', '')
                                        formik.setFieldValue('new_issue_type1', '')
                                        formik.setFieldValue('cash_in_lieu_payment_rate1', '')
                                        formik.setFieldValue('cash_in_lieu_currency1', '')
                                        formik.setFieldValue('number_of_decimal_places1', '')
                                        setactiveEntitlement(activeEntitlement + 1)
                                    }
                                }}
                                disabled={!formik.values.entitlement}
                                style={{ height: 40 }}
                                className="bg-brown text-white px-20 h-full">
                                {loading ? <CircularProgress style={{ color: "#fff", width: 20, height: 20 }} /> : "Next"}
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="page-padding py-10">
            <div
                className="border border-gray2 p-10 space-y-4">
                <div className="text-xl lg:text-2xl text-blue-base Roboto-Bold">
                    {mode ? 'Update Event / Event Template' : "+ Create New Event / Event Template"}
                </div>
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="grid md:grid-cols-1 gap-8">
                        <Input
                            placeholder="Event Name"
                            disabled={status === 'active'}
                            name="event_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.event_name}
                            errors={formik.errors.event_name}
                            touched={formik?.touched?.event_name}
                            width="full"
                        />
                        <SelectWithLabel
                            disabled={status === 'active'}
                            placeholder="Choose Client"
                            name="client_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errors={formik.errors.client_id}
                            touched={formik?.touched?.client_id}
                            value={formik.values.client_id}
                            width="full"
                            customList={getClientsList()}
                        />
                        <div className="grid grid-cols-1 flex items-center space-x-4">
                            {/* <div className='w-100'>
                                <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                    <FaUpload />
                                    <input ref={hiddenFileInput} style={{ display: 'none' }} type="file" accept='image/png, image/gif, image/jpeg' onChange={(e) => handleChangeLocal(e, 'logo')} />
                                    <span onClick={() => handleClick('hiddenFileInput')}>UPLOAD LOGO</span>
                                </button>
                                {
                                    formik.values['logo_name'] &&
                                    <div className='text-xs' style={{ marginBottom: -20 }}>{formik.values['logo_name']}</div>
                                }
                            </div> */}
                            <div className="grid">
                                <SelectWithLabel
                                    disabled={status === 'active'}
                                    placeholder="Choose colour palette"
                                    list={['Odyssey Default']}
                                    extraOption={'Select Colour'}
                                    name="color_palette"
                                    onChange={formik.handleChange}
                                    errors={formik.errors.color_palette}
                                    touched={formik?.touched?.color_palette}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.color_palette}
                                    width="full"
                                />
                            </div>
                        </div>
                        <SelectWithLabel
                            disabled={status === 'active'}
                            placeholder="Choose Event Type"
                            name="eventtype_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.eventtype_id}
                            width="full"
                            customList={getEventTypeList()}
                        />
                        {
                            formik.values.eventtype_id !== '' &&
                            <>
                                <TextArea
                                    disabled={status === 'active'}
                                    placeholder="Enter Event Details"
                                    name="description"
                                    maxlength={500}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    errors={formik.errors.description}
                                    touched={formik.touched.description}
                                    width="full"
                                />
                                <Checkbox
                                    disabled={status === 'active'}
                                    text="Terms and Conditions of the Portal"
                                    name="term_and_conditions_of_portal"
                                    type="checkbox"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.term_and_conditions_of_portal}
                                />
                                {
                                    formik.values.eventtype_id !== '2' &&
                                    <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                        <div>Letter of Transmittal</div>
                                        {
                                            formik.values['letter_of_transmittal_name'] &&
                                            <div>{formik.values['letter_of_transmittal_name']}</div>
                                        }
                                        <button disabled={status === 'active'} onClick={() => handleClick('hiddenFileInput1')} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                            <FaUpload />
                                            <input ref={hiddenFileInput1} style={{ display: 'none' }} type="file" accept='application/pdf' onChange={(e) => handleChangeLocal(e, 'letter_of_transmittal')} />
                                            <span >Upload</span>
                                        </button>
                                    </div>
                                }
                                {
                                    formik.values.eventtype_id === '2' &&
                                    <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                        <div>Warrant Document</div>
                                        {
                                            formik.values['warrant_document_name'] &&
                                            <div>{formik.values['warrant_document_name']}</div>
                                        }
                                        <button disabled={status === 'active'} onClick={() => handleClick('hiddenFileInput2')} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                            <FaUpload />
                                            <input ref={hiddenFileInput2} style={{ display: 'none' }} type="file" accept='application/pdf' onChange={(e) => handleChangeLocal(e, 'warrant_document')} />
                                            <span >Upload</span>
                                        </button>
                                    </div>
                                }
                                {
                                    formik.values.eventtype_id === '2' &&
                                    <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                        <div>Exercise Form</div>
                                        {
                                            formik.values['exercise_form_name'] &&
                                            <div>{formik.values['exercise_form_name']}</div>
                                        }
                                        <button disabled={status === 'active'} onClick={() => handleClick('hiddenFileInput3')} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                            <FaUpload />
                                            <input ref={hiddenFileInput3} style={{ display: 'none' }} type="file" accept='application/pdf' onChange={(e) => handleChangeLocal(e, 'exercise_form')} />
                                            <span >Upload</span>
                                        </button>
                                    </div>
                                }
                                <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                    <div>Other Document</div>
                                    {
                                        formik.values['other_document_name'] &&
                                        <div>{formik.values['other_document_name']}</div>
                                    }
                                    <button disabled={status === 'active'} onClick={() => handleClick('hiddenFileInput5')} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                        <FaUpload />
                                        <input ref={hiddenFileInput5} style={{ display: 'none' }} type="file" accept='application/pdf' onChange={(e) => handleChangeLocal(e, 'other_document')} />
                                        <span >Upload</span>
                                    </button>
                                </div>
                                <TextArea
                                    placeholder="Event Terms and conditions"
                                    disabled={status === 'active'}
                                    name="terms_and_conditions_of_event"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.terms_and_conditions_of_event}
                                    errors={formik.errors.terms_and_conditions_of_event}
                                    touched={formik.touched.terms_and_conditions_of_event}
                                    width="full"
                                />
                            </>
                        }
                    </div>
                    <div className="grid items-start md:grid-cols-1 gap-8">
                        {
                            (formik.values.eventtype_id !== '' && formik.values.eventtype_id !== '3') &&
                            <>
                                <div className="grid grid-cols-2 flex items-center space-x-4">
                                    <Input
                                        placeholder="Current (Old) Issue Name"
                                        disabled={status === 'active'}
                                        name="current_issue_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.current_issue_name}
                                        errors={formik.errors.current_issue_name}
                                        touched={formik?.touched?.current_issue_name}
                                        width="full"
                                    />
                                    <Input
                                        placeholder="Current Issue Type"
                                        name="current_issue_type"
                                        disabled={status === 'active'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.current_issue_type}
                                        errors={formik.errors.current_issue_type}
                                        touched={formik?.touched?.current_issue_type}
                                        width="full"
                                    />
                                </div>
                                <div className="grid grid-cols-2 flex items-center space-x-4">
                                    <Input
                                        placeholder="New Issue Name"
                                        disabled={status === 'active'}
                                        name="new_issue_name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.new_issue_name}
                                        errors={formik.errors.new_issue_name}
                                        touched={formik?.touched?.new_issue_name}
                                        width="full"
                                    />
                                    <Input
                                        placeholder="New Issue Type"
                                        name="new_issue_type"
                                        disabled={status === 'active'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.new_issue_type}
                                        errors={formik.errors.new_issue_type}
                                        touched={formik?.touched?.new_issue_type}
                                        width="full"
                                    />
                                </div>
                                {
                                    formik.values.eventtype_id === '1' &&

                                    <div className="grid grid-cols-2 flex items-center space-x-4">
                                        <Input
                                            placeholder="Units Held"
                                            name="units_held"
                                            type="number"
                                            disabled={status === 'active'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.units_held}
                                            errors={formik.errors.units_held}
                                            touched={formik?.touched?.units_held}
                                            width="full"
                                        />
                                        <Input
                                            placeholder="Units Entitled "
                                            name="units_entitled"
                                            type="number"
                                            disabled={status === 'active'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.units_entitled}
                                            errors={formik.errors.units_entitled}
                                            touched={formik?.touched?.units_entitled}
                                            width="full"
                                        />
                                    </div>
                                }
                                {
                                    formik.values.eventtype_id === '1' &&
                                    <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                            Fractional Treatment:
                                        </div>
                                        <div className="grid md:grid-cols-2 gap-8">
                                            <Checkbox
                                                text="Round up"
                                                name="fractional_treatment"
                                                disabled={status === 'active'}
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'roundup'}
                                                stateVal={formik.values.fractional_treatment}
                                            />
                                            <Checkbox
                                                text="Drop"
                                                name="fractional_treatment"
                                                id="RegisteredAddress2"
                                                disabled={status === 'active'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'drop'}
                                                stateVal={formik.values.fractional_treatment}
                                            />
                                            <Checkbox
                                                disabled={status === 'active'}
                                                text="Nearest"
                                                name="fractional_treatment"
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'nearest'}
                                                stateVal={formik.values.fractional_treatment}
                                            />
                                            <Checkbox
                                                text="Cash in LIEU"
                                                name="fractional_treatment"
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                disabled={status === 'active'}
                                                onBlur={formik.handleBlur}
                                                value={'cash_in_lieu'}
                                                stateVal={formik.values.fractional_treatment}
                                            />
                                            <Checkbox
                                                text="Keep"
                                                name="fractional_treatment"
                                                disabled={status === 'active'}
                                                id="RegisteredAddress1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'keep'}
                                                stateVal={formik.values.fractional_treatment}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    formik.values.eventtype_id === '1' && formik.values.fractional_treatment === 'keep' &&
                                    <div className="grid grid-cols-1 flex items-center space-x-4">
                                        <Input
                                            placeholder="Number of decimal places"
                                            type={'number'}
                                            disabled={status === 'active'}
                                            name="number_of_decimal_places"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.number_of_decimal_places}
                                            errors={formik.errors.number_of_decimal_places}
                                            touched={formik.touched.number_of_decimal_places}
                                            width="full"
                                        />
                                    </div>
                                }
                                {
                                    formik.values.eventtype_id === '1' && formik.values.fractional_treatment === 'cash_in_lieu' &&
                                    <div className="grid grid-cols-2 flex items-center space-x-4">
                                        <Input
                                            placeholder="Cash in LIEU rate"
                                            name="cash_in_lieu_payment_rate"
                                            type={'number'}
                                            disabled={status === 'active'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.cash_in_lieu_payment_rate}
                                            errors={formik.errors.cash_in_lieu_payment_rate}
                                            touched={formik.touched.cash_in_lieu_payment_rate}
                                            width="full"
                                        />
                                        <SelectWithLabel
                                            placeholder="Currency"
                                            disabled={status === 'active'}
                                            name="cash_in_lieu_currency"
                                            onChange={formik.handleChange}
                                            errors={formik.errors.cash_in_lieu_currency}
                                            touched={formik.touched.cash_in_lieu_currency}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.cash_in_lieu_currency}
                                            width="full"
                                            customList={getCurrencyList()}
                                        />
                                    </div>
                                }
                            </>
                        }
                        {
                            formik.values.eventtype_id === '3' &&
                            <>
                                <div>
                                    <Input
                                        placeholder="The number of entitlement options (1-9) "
                                        name="entitlement"
                                        disabled={status === 'active'}
                                        onChange={formik.handleChange}
                                        type='number'
                                        onBlur={formik.handleBlur}
                                        value={formik.values.entitlement}
                                        touched={formik.touched.entitlement}
                                        errors={formik.errors.entitlement}
                                        width="full"
                                    />
                                    {
                                        completeEntitlement &&
                                        <>
                                            {

                                                (mode ? formik?.values?.entitlements : Array(Number(formik.values.entitlement)).fill(0).map((e, i) => i + 1)).map((count, index) => (
                                                    <div key={count} onClick={() => mode ? handleEntitleClick(mode ? index : count) : console.log('')} className="text-xl lg:text-xl text-blue-base cursor-pointer Roboto-Bold mt-4">
                                                        Entitlement {mode ? index + 1 : count}
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }
                                </div>
                                {/* <Checkbox
                                    text="Holder is required to make an election for securities"
                                    name="holder_make_an_election"
                                    type="checkbox"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.holder_make_an_election}
                                />
                                {
                                    !formik.values.holder_make_an_election &&
                                    <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                        <Checkbox
                                            text="Issue Securities"
                                            name="issue_type"
                                            id="RegisteredAddress1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'1'}
                                            stateVal={formik.values.issue_type}
                                        />
                                        <Checkbox
                                            text="Issue Cash"
                                            name="issue_type"
                                            id="RegisteredAddress2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'2'}
                                            stateVal={formik.values.issue_type}
                                        />
                                        <Checkbox
                                            text="Issue Securities and Cash"
                                            name="issue_type"
                                            id="RegisteredAddress2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'3'}
                                            stateVal={formik.values.issue_type}
                                        />
                                    </div>
                                }
                                {
                                    (formik.values.issue_type === "1" || formik.values.issue_type === "3" || formik.values.holder_make_an_election) &&
                                    <>

                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="Units Held"
                                                name="units_held"
                                                type={'number'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.units_held}
                                                touched={formik.touched.units_held}
                                                errors={formik.errors.units_held}
                                                width="full"
                                            />
                                            <Input
                                                placeholder="Units Entitled "
                                                type={'number'}
                                                name="units_entitled"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.units_entitled}
                                                touched={formik.touched.units_entitled}
                                                errors={formik.errors.units_entitled}
                                                width="full"
                                            />
                                        </div>
                                        <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                            <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                                Fractional Treatment:
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-8">
                                                <Checkbox
                                                    text="Round up"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'roundup'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Drop"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'drop'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Nearest"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'nearest'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Cash in LIEU"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'cash_in_lieu'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                                <Checkbox
                                                    text="Keep"
                                                    name="fractional_treatment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'keep'}
                                                    stateVal={formik.values.fractional_treatment}
                                                />
                                            </div>
                                        </div>
                                        {
                                            formik.values.eventtype_id === '3' && formik.values.fractional_treatment === 'keep' &&
                                            <div className="grid grid-cols-1 flex items-center space-x-4">
                                                <Input
                                                    placeholder="Number of decimal places"
                                                    type={'number'}
                                                    name="number_of_decimal_places"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.number_of_decimal_places}
                                                    errors={formik.errors.number_of_decimal_places}
                                                    touched={formik.touched.number_of_decimal_places}
                                                    width="full"
                                                />
                                            </div>
                                        }
                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="Cash in LIEU rate"
                                                name="cash_in_lieu_payment_rate"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                type={'number'}
                                                value={formik.values.cash_in_lieu_payment_rate}
                                                errors={formik.errors.cash_in_lieu_payment_rate}
                                                touched={formik.touched.cash_in_lieu_payment_rate}
                                                width="full"
                                            />
                                            <SelectWithLabel
                                                placeholder="Currency"
                                                name="cash_in_lieu_currency"
                                                onChange={formik.handleChange}
                                                errors={formik.errors.cash_in_lieu_currency}
                                                touched={formik.touched.cash_in_lieu_currency}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.cash_in_lieu_currency}
                                                width="full"
                                                customList={getCurrencyList()}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    (formik.values.issue_type === "2" || formik.values.issue_type === "3" || formik.values.holder_make_an_election) &&
                                    <>

                                        <div className="grid grid-cols-2 flex items-center space-x-4">
                                            <Input
                                                placeholder="Payment rate"
                                                name="payment_rate"
                                                type={'number'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.payment_rate}
                                                errors={formik.errors.payment_rate}
                                                touched={formik.touched.payment_rate}
                                                width="full"
                                            />
                                            <SelectWithLabel
                                                placeholder="Currency"
                                                name="currency"
                                                onChange={formik.handleChange}
                                                errors={formik.errors.currency}
                                                touched={formik.touched.currency}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.currency}
                                                width="full"
                                                customList={getCurrencyList()}
                                            />
                                        </div>
                                        <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                            <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                                Payment:
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-8">
                                                <Checkbox
                                                    text="By cheque"
                                                    name="payment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'cheque'}
                                                    stateVal={formik.values.payment}
                                                />
                                                <Checkbox
                                                    text="EFT"
                                                    name="payment"
                                                    id="RegisteredAddress2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'eft'}
                                                    stateVal={formik.values.payment}
                                                />
                                                <Checkbox
                                                    text="By wire"
                                                    name="payment"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'wire'}
                                                    stateVal={formik.values.payment}
                                                />
                                                <Checkbox
                                                    text="ACH"
                                                    name="payment"
                                                    id="RegisteredAddress2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'ach'}
                                                    stateVal={formik.values.payment}
                                                />
                                            </div>
                                        </div>

                                        <Checkbox
                                            text="Ask Residency Question and Tax Status requirement"
                                            name="ask_residency_holder_status_question"
                                            type="checkbox"
                                            id="RegisteredAddress1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={true}
                                            stateVal={formik.values.ask_residency_holder_status_question}
                                        />
                                        {
                                            formik.values.ask_residency_holder_status_question &&
                                            <>
                                                <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                                    <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                                        Tax Status:
                                                    </div>
                                                    <div className="grid md:grid-cols-1 gap-8">
                                                        <Checkbox
                                                            text="W9 from US residents"
                                                            name="tax_status"
                                                            id="RegisteredAddress1"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={'w9'}
                                                            stateVal={formik.values.tax_status}
                                                        />
                                                        <Checkbox
                                                            text="W8 from non-US residents"
                                                            name="tax_status"
                                                            id="RegisteredAddress2"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={'w8'}
                                                            stateVal={formik.values.tax_status}
                                                        />
                                                        <Checkbox
                                                            text="None"
                                                            name="tax_status"
                                                            id="RegisteredAddress1"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={'none'}
                                                            stateVal={formik.values.tax_status}
                                                        />
                                                    </div>
                                                </div>
                                                <Checkbox
                                                    text="Accredited investor info needs to be collected"
                                                    name="accredited_investor_info_required"
                                                    type="checkbox"
                                                    id="RegisteredAddress1"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={true}
                                                    stateVal={formik.values.accredited_investor_info_required}
                                                />
                                            </>
                                        }
                                    </>
                                } */}
                            </>
                        }
                        {
                            formik.values.eventtype_id === '2' &&
                            <>
                                <Input
                                    placeholder="Enter the exercise terms (e.g. 1 warrant + $1.50 = 1 common share)"
                                    name="exercise_terms"
                                    onChange={formik.handleChange}
                                    disabled={status === 'active'}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.exercise_terms}
                                    errors={formik.errors.exercise_terms}
                                    value={formik.values.exercise_terms}
                                    width="full"
                                />
                                <div className="grid grid-cols-2 flex items-center space-x-4">
                                    <Input
                                        placeholder="Payment rate"
                                        disabled={status === 'active'}
                                        name="payment_rate"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type={'number'}
                                        touched={formik.touched.payment_rate}
                                        errors={formik.errors.payment_rate}
                                        value={formik.values.payment_rate}
                                        width="full"
                                    />
                                    <SelectWithLabel
                                        placeholder="Currency"
                                        name="currency"
                                        onChange={formik.handleChange}
                                        disabled={status === 'active'}
                                        errors={formik.errors.currency}
                                        touched={formik.touched.currency}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.currency}
                                        width="full"
                                        customList={getCurrencyList()}
                                    />
                                </div>
                                {/* <Input
                                    placeholder="The name and class of the new issue"
                                    name="new_issue_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.new_issue_name}
                                    width="full"
                                /> */}
                                <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                    <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                        Payment:
                                    </div>
                                    <div className="grid md:grid-cols-2 gap-8">
                                        <Checkbox
                                            text="By cheque"
                                            disabled={status === 'active'}
                                            type="checkbox"
                                            name="by_cheque"
                                            id="RegisteredAddress1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={true}
                                            stateVal={formik.values.by_cheque}
                                        />
                                        <Checkbox
                                            text="By wire"
                                            disabled={status === 'active'}
                                            type="checkbox"
                                            name="by_wire"
                                            id="RegisteredAddress2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={true}
                                            stateVal={formik.values.by_wire}
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                    <div>Upload wire payment instructions</div>
                                    {
                                        formik.values['warrant_payment_instruction_name'] &&
                                        <div className='text-xs'>{formik.values['warrant_payment_instruction_name']}</div>
                                    }
                                    <button disabled={status === 'active'} onClick={() => handleClick('hiddenFileInput4')} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                        <FaUpload />
                                        <input ref={hiddenFileInput4} style={{ display: 'none' }} type="file" accept='image/pdf' onChange={(e) => handleChangeLocal(e, 'warrant_payment_instruction')} />
                                        <span >Upload</span>
                                    </button>
                                </div>
                                <Checkbox
                                    disabled={status === 'active'}
                                    text="Ask Residency/Holder Status question "
                                    name="ask_residency_holder_status_question"
                                    type="checkbox"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.ask_residency_holder_status_question}
                                />
                                {/* <Input
                                    placeholder="Enter the question "
                                    name="question"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.question}
                                    width="full"
                                /> */}
                                <Checkbox
                                    text="Are Transfers Allowed?"
                                    name="is_transfer_allowed"
                                    disabled={status === 'active'}
                                    type="checkbox"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.is_transfer_allowed}
                                />
                            </>
                        }
                        {
                            formik.values.eventtype_id === '1' &&
                            <Checkbox
                                text="Are Transfers Allowed?"
                                disabled={status === 'active'}
                                name="is_transfer_allowed"
                                type="checkbox"
                                id="RegisteredAddress1"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={true}
                                stateVal={formik.values.is_transfer_allowed}
                            />
                        }
                        {
                            (formik.values.eventtype_id !== '' && formik.values.eventtype_id !== '3') &&
                            <>
                                <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                    <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                        Set Event Status:
                                    </div>
                                    <div className="flex space-x-4 space-y-2 md:space-y-0 flex-col md:flex-row">
                                        <Checkbox
                                            text="Pending"
                                            disabled={status === 'active'}
                                            name="status"
                                            id="RegisteredAddress1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'pending'}
                                            stateVal={formik.values.status}
                                        />
                                        <Checkbox
                                            text="Active immediately"
                                            disabled={status === 'active'}
                                            name="status"
                                            id="RegisteredAddress2"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'active'}
                                            stateVal={formik.values.status}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 flex items-center space-x-4">
                                    <div>
                                        <label
                                            className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mb-0.5"
                                            htmlFor={"Start Date"}>
                                            <div> {"Start Date"}</div>
                                        </label>
                                        <Input
                                            placeholder="Start Date"
                                            name="event_start_date"
                                            disabled={status === 'active'}
                                            type="date"
                                            min={moment().format('YYYY-MM-DD')}
                                            max={formik.values.event_end_date && moment(formik.values.event_end_date).format('YYYY-MM-DD')}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.event_start_date}
                                            errors={formik.errors.event_start_date}
                                            touched={formik?.touched?.event_start_date}
                                            width="full"
                                        />
                                    </div>
                                    <div>
                                        <label
                                            className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mb-0.5"
                                            htmlFor={"Start Date"}>
                                            <div> {"End Date"}</div>
                                        </label>
                                        <Input
                                            placeholder="End Date"
                                            name="event_end_date"
                                            type="date"
                                            min={formik.values.event_start_date ? moment(formik.values.event_start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.event_end_date}
                                            errors={formik.errors.event_end_date}
                                            touched={formik?.touched?.event_end_date}
                                            width="full"
                                        />
                                    </div>
                                </div>
                                <div className="grid flex items-center space-x-4">
                                    <div>
                                        <label
                                            className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mb-0.5"
                                            htmlFor={"End Date Text"}>
                                            <div> {"End Date Text"}</div>
                                        </label>
                                        <Input
                                            placeholder="End Date Text"
                                            name="event_end_date_text"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.event_end_date_text}
                                            errors={formik.errors.event_end_date_text}
                                            touched={formik?.touched?.event_end_date_text}
                                            width="full"
                                        />
                                    </div>
                                </div>
                                <div className='h-1 border-1' />
                                <Checkbox
                                    text="Deliver to alternate mailing address"
                                    name="allow_deliver_to_alternate_mailing_address"
                                    type="checkbox"
                                    disabled={status === 'active'}
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.allow_deliver_to_alternate_mailing_address}
                                />
                                <Checkbox
                                    text="Hold for pickup"
                                    name="allow_hold_for_pickup"
                                    disabled={status === 'active'}
                                    type="checkbox"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.allow_hold_for_pickup}
                                />
                                <Checkbox
                                    text="Extra validation step will be required."
                                    type="checkbox"
                                    disabled={status === 'active'}
                                    name="extra_validation_required"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.extra_validation_required}
                                />
                                <Checkbox
                                    text="Entities are required to submit document to confirm signing authority."
                                    type="checkbox"
                                    disabled={status === 'active'}
                                    name="signing_authority_required"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.signing_authority_required}
                                />
                                <Checkbox
                                    text="Offer eConsent"
                                    type="checkbox"
                                    name="offer_econsent"
                                    disabled={status === 'active'}
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={true}
                                    stateVal={formik.values.offer_econsent}
                                />

                                <div className="h-15 xs:h-15 md:h-15 grid justify-end">
                                    {/* <Checkbox
                                        text="Save as Template"
                                        type="checkbox"
                                        name="delivery_type"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'1'}
                                        // stateVal={formik.values.delivery_type}
                                    /> */}
                                    <button
                                        onClick={() => formik.handleSubmit()}
                                        style={{ height: 40 }}
                                        className="bg-brown text-white px-20 h-full">
                                        {loading ? <CircularProgress style={{ color: "#fff", width: 20, height: 20 }} /> : mode ? "UPDATE EVENT" : "CREATE EVENT"}
                                    </button>
                                </div>

                            </>
                        }
                    </div>
                </div>
                {
                    formik.values.eventtype_id === '3' &&
                    <>
                        <div className="grid md:grid-cols-2 gap-8">
                            <div className="grid md:grid-cols-1 gap-8"></div>
                            {
                                completeEntitlement &&
                                <div className="grid md:grid-cols-1 gap-8">
                                    <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                            Set Event Status:
                                        </div>
                                        <div className="flex space-x-4 space-y-2 md:space-y-0 flex-col md:flex-row">
                                            <Checkbox
                                                text="Pending"
                                                name="status"
                                                id="RegisteredAddress1"
                                                disabled={status === 'active'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'pending'}
                                                stateVal={formik.values.status}
                                            />
                                            <Checkbox
                                                text="Active immediately"
                                                name="status"
                                                disabled={status === 'active'}
                                                id="RegisteredAddress2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'active'}
                                                stateVal={formik.values.status}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 flex items-center space-x-4">
                                        <div>
                                            <label
                                                className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mb-0.5"
                                                htmlFor={"Start Date"}>
                                                <div> {"Start Date"}</div>
                                            </label>
                                            <Input
                                                placeholder="Start Date"
                                                name="event_start_date"
                                                disabled={status === 'active'}
                                                type="date"
                                                min={moment().format('YYYY-MM-DD')}
                                                max={formik.values.event_end_date && moment(formik.values.event_end_date).format('YYYY-MM-DD')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.event_start_date}
                                                errors={formik.errors.event_start_date}
                                                touched={formik?.touched?.event_start_date}
                                                width="full"
                                            />
                                        </div>
                                        <div>
                                            <label
                                                className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mb-0.5"
                                                htmlFor={"Start Date"}>
                                                <div> {"End Date"}</div>
                                            </label>
                                            <Input
                                                placeholder="End Date"
                                                name="event_end_date"
                                                type="date"
                                                min={formik.values.event_start_date ? moment(formik.values.event_start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.event_end_date}
                                                errors={formik.errors.event_end_date}
                                                touched={formik?.touched?.event_end_date}
                                                width="full"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid flex items-center space-x-4">
                                        <div>
                                            <label
                                                className="text-xs md:text-sm flex items-center space-x-2  Roboto-Medium cursor-pointer mb-0.5"
                                                htmlFor={"End Date Text"}>
                                                <div> {"End Date Text"}</div>
                                            </label>
                                            <Input
                                                placeholder="End Date Text"
                                                name="event_end_date_text"
                                                type="text"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.event_end_date_text}
                                                errors={formik.errors.event_end_date_text}
                                                touched={formik?.touched?.event_end_date_text}
                                                width="full"
                                            />
                                        </div>
                                    </div>
                                    <div className='h-1 border-1' />
                                    <Checkbox
                                        text="Are Transfers Allowed?"
                                        disabled={status === 'active'}
                                        name="is_transfer_allowed"
                                        type="checkbox"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.is_transfer_allowed}
                                    />
                                    <Checkbox
                                        text="Deliver to alternate mailing address"
                                        name="allow_deliver_to_alternate_mailing_address"
                                        disabled={status === 'active'}
                                        type="checkbox"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.allow_deliver_to_alternate_mailing_address}
                                    />
                                    <Checkbox
                                        text="Hold for pickup"
                                        disabled={status === 'active'}
                                        name="allow_hold_for_pickup"
                                        type="checkbox"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.allow_hold_for_pickup}
                                    />
                                    <Checkbox
                                        text="Extra validation step will be required."
                                        disabled={status === 'active'}
                                        type="checkbox"
                                        name="extra_validation_required"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.extra_validation_required}
                                    />
                                    <Checkbox
                                        text="Entities are required to submit document to confirm signing authority."
                                        type="checkbox"
                                        disabled={status === 'active'}
                                        name="signing_authority_required"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.signing_authority_required}
                                    />
                                    <Checkbox
                                        text="Offer eConsent"
                                        type="checkbox"
                                        disabled={status === 'active'}
                                        name="offer_econsent"
                                        id="RegisteredAddress1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={true}
                                        stateVal={formik.values.offer_econsent}
                                    />
                                    {/* <div className="h-15 xs:h-15 md:h-15 grid justify-end"> */}
                                    {/* <Checkbox
                                            text="Save as Template"
                                            type="checkbox"
                                            name="delivery_type"
                                            id="RegisteredAddress1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={'1'}
                                        // stateVal={formik.values.delivery_type}
                                        /> */}
                                    {/* <button
                                        onClick={() => formik.handleSubmit()}
                                        style={{ height: 40 }}
                                        className="bg-brown text-white px-20 h-full">
                                        {loading ? <CircularProgress style={{ color: "#fff", width: 20, height: 20 }} /> : "CREATE EVENT"}
                                    </button> */}
                                    {/* </div> */}
                                </div>
                            }
                        </div>
                        <div style={{ height: '100%' }} className="h-15 xs:h-15 md:h-15 grid justify-end">
                            {
                                mode && Number(formik.values.entitlement) > formik.values.entitlements.concat(new_entitlements).length ?
                                    <button
                                        onClick={() => {
                                            setisEntitlement(true)
                                            setCompleteEntitlement(false)
                                            setactiveEntitlement(formik.values.entitlements.length + 1)
                                        }}
                                        disabled={!formik.values.entitlement}
                                        style={{ height: 40 }}
                                        className="bg-brown text-white px-20 h-full">
                                        {"Add Entitlements"}
                                    </button>
                                    :
                                    <button
                                        onClick={() => {
                                            if (completeEntitlement) {
                                                formik.handleSubmit()
                                            } else {
                                                setisEntitlement(true)
                                                setCompleteEntitlement(false)
                                                setactiveEntitlement(1)
                                            }
                                        }}
                                        disabled={!formik.values.entitlement}
                                        style={{ height: 40 }}
                                        className="bg-brown text-white px-20 h-full">
                                        {loading ? <CircularProgress style={{ color: "#fff", width: 20, height: 20 }} /> : mode ? "UPDATE EVENT" : completeEntitlement ? "CREATE EVENT" : "Next"}
                                    </button>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
