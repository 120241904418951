export const APP_LOADING_ACTIONS_TYPES = {
  APP_LOADING: "[APP] APP_LOADING",
  APP_ROUTES: "[APP] APP_ROUTES",
};

export const setAppLoadingState = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: APP_LOADING_ACTIONS_TYPES.APP_LOADING,
      payload,
    });
  };
};

