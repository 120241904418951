import {useEffect, useState, createRef} from 'react'
import {FaSearch} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {ReactMaterialTable} from '../../../components/table/customtable'
import { getSecurityHolderDetail, getSecurityHolders } from '../../../redux/actions/client.actions'
import { RootState } from '../../../redux/rootstore'
import {NUMBER_FORMAT, DOLLAR_STRING, DATE_FORMAT} from '../../constants'
import { Alert } from '@material-ui/lab'

export const EventSecurityHolder = () => {
    const [showHolderDetail, setShowHolderDetail] = useState(false)
    const {eventId} = useParams<any>()
    const [queryParams, setQueryParams] = useState<
        {
            search: string
        }[]
    >([])
    const tableRef = createRef();
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const {security_holders, security_holder_detail} = useSelector((state: RootState) => state && state.ClientReducer && state.ClientReducer)
    const tableData = security_holders.results
   
    var columns = [
        {
            field: 'event_type',
            title: 'FULL NAME',
            type: 'string',
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.full_name}</div>
                </div>
            ),
        },
    ]

    const onEnterInput = (e: any, searchedBy: string) => {
        let query = {...queryParams}
        if(e.target.value){
            query[searchedBy]  = e.target.value
        } else {
            delete query[searchedBy]
        }
        setQueryParams(query)
        tableRef.current && (tableRef.current as any).onQueryChange({page: 0})

    }

    const showDetail = (event, rowData) => {
        if(rowData && rowData.id){
            dispatch(getSecurityHolderDetail(navigate, rowData.id))
            setShowHolderDetail(true)
        }
    }

    return (
        <div className="space-y-10 page-padding py-10">
            <div className="space-y-10">
                <div className="h-7 w-full md:w-1/2 sm:h-8 md:h-10 relative flex items-center space-x-3 px-2 md:px-4 bg-white text-gray border  border-bordergray">
                    <input
                        className={`h-full text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base pace-x-2 sm:space-x-4 2xl:space-x-8 `}
                        onChange={(e: any) => onEnterInput(e, 'search')}
                        placeholder="Search"
                    />
                    <FaSearch className="text-sm md:text-lg lg:text-xl 2xl:text-2xl bg-white text-home-layout-gray" />
                </div>
                <div className="grid md:grid-cols-2 md:gap-20  w-full space-y-4  text-sm 2xl:text-base Roboto-Regular">
                    <div className="w-full">
                    <Alert severity="info">Click on table row to get detail about Security Holder</Alert>
                        <ReactMaterialTable
                            tableRef = {tableRef}
                            columns={columns}
                            data = {((query)=>
                                new Promise(async (resolve, reject) => {
                                    const page = query.page ? query.page + 1 : 1
                                    const params = {...queryParams, page, event_id: eventId}
                                    const result = await dispatch(getSecurityHolders(navigate, params))
                                    //result && result.data && result.data.length ? showDetail(null,result.data[0]) : setShowHolderDetail(false)
                                    setShowHolderDetail(false)
                                    return resolve(result)
                                  }))
                            }
                            searchbar={false}
                            toolbar={false}
                            exportbtn={false}
                            fontSize="16px"
                            pagging={true}
                            id="table"
                            rowClickEvent = {(event, rowData)=>showDetail(event, rowData)}
                        />
                    </div>
                    {showHolderDetail && security_holder_detail && <div className="flex flex-col space-y-3">
                        <div className="Roboto-Medium text-base md:text-2xl text-blue-base">
                            {security_holder_detail.full_name}
                        </div>
                        <div className=" space-y-3">
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Number of securities remaining for exchange:{NUMBER_FORMAT(security_holder_detail.number_of_securities_remaining_for_exchange)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Number of securities exchanged:{NUMBER_FORMAT(security_holder_detail.number_of_securities_exchanged)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Total securities entitlement:{NUMBER_FORMAT(security_holder_detail.total_securities_entitlement)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Total securities issued to date:{security_holder_detail.total_securities_issued_to_date && DATE_FORMAT(new Date(security_holder_detail.total_securities_issued_to_date))}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Total cash entitlement ($):{DOLLAR_STRING.format(security_holder_detail.total_cash_entitlement)}
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Amount paid to date ($):{DOLLAR_STRING.format(security_holder_detail.amount_paid_to_date)}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}
