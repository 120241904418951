import { useNavigate, useParams } from 'react-router-dom'
import { imgs } from '../../assets/images/img'
import { Nav } from '../../components/nav/nav'
import { Footer } from '../../components/footer/footer'

import { useState } from 'react'
import OtpInput from 'react-otp-input'
import { onAdminConfirmOTP, onAdminSendOTP } from '../../redux/actions/auth.actions'
import { useDispatch } from 'react-redux'

export const AdminAuth = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch<any>()

    const [otp, setotp] = useState('')

    const handleChange = (value: any) => {
        setotp(value)
    }

    const handleSubmit = () => {
        dispatch(onAdminConfirmOTP({ email: id, otp }, navigate))
    }

    const handleSubmitResend = () => {
        dispatch(onAdminSendOTP({ email: id }))
    }

    return (
        <div
            className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
            style={{
                backgroundImage: `url(${imgs.background})`,
            }}>
            <div
                className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
                style={{
                    backgroundImage: `url(${imgs.backgroundlayer})`,
                }}>
                <Nav enableProps={false} />
                <div className="flex justify-center  py-10 text-left min-h-full md:space-x-10 xl:space-x-16">
                    <div className="text-white w-1/2 lg:w-5/12 space-y-6 px-12 hidden sm:block">
                        <div className="text-2xl md:text-4xl Roboto-Bold">
                            OdysseyXpress Corporate Event Gateway
                        </div>
                        <div className="text-base md:text-lg Roboto-Regular">
                            Odyssey's Corporate Event Gatewaymakes it simple,
                            fast and easy for you to monitor and track the
                            status of your corporate actions and other corporate
                            events and for your registered holders to submit
                            their instructions securely online.
                        </div>
                    </div>
                    <div className="bg-white px-8 py-10  space-y-2 w-80  flex flex-col items-center">
                        <div
                            className="space-y-8">
                            <div className="flex flex-col items-center justify-center px-5">
                                <img
                                    src={imgs.logo}
                                    alt=""
                                    className="w-full object-cover"
                                />
                                <div className="self-end Roboto-Medium mr-1 text-xs sm:text-sm text-blue-base">
                                    XPRESS
                                </div>
                            </div>
                            <div className="space-y-4">
                                <div className="Roboto-Bold text-blue-base text-xl lg:text-2xl">
                                    Authenticate your account
                                </div>
                                <div className="Roboto-Medium w-full text-blue-base text-sm">
                                    Please enter token number which was sent to
                                    your email address.
                                </div>
                                <div className="space-y-3">
                                    <div className="w-full">
                                        <OtpInput
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={6}
                                            separator={
                                                <span className="text-white">
                                                    -
                                                </span>
                                            }
                                            className="h-10 w-full"
                                            inputStyle={{
                                                border: '1px solid #8B8B8B',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                            focusStyle={false}
                                        />
                                    </div>
                                    <div onClick={handleSubmitResend} className="Roboto-Bold cursor-pointer w-full text-right text-blue-base text-sm">
                                        Resend Token
                                    </div>
                                </div>
                                <div className="space-y-3">
                                    <button
                                        onClick={() => handleSubmit()}
                                        type={'button'}
                                        className="btn-brown w-full ">
                                        CONTINUE
                                    </button>
                                    <button
                                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                                        type="submit">
                                        CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
