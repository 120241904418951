export const BasicCountries = [
    {
        country_name: 'AFGHANISTAN',
        code: 'AF',
    },
    {
        country_name: 'ALBANIA',
        code: 'AL',
    },
    {
        country_name: 'ALGERIA',
        code: 'AG',
    },
    {
        country_name: 'ANDORRA',
        code: 'AN',
    },
    {
        country_name: 'ANGOLA',
        code: 'AO',
    },
    {
        country_name: 'ANTARCTICA',
        code: 'AY',
    },
    {
        country_name: 'ANTIGUA AND BARBUDA',
        code: 'AC',
    },
    {
        country_name: 'ARGENTINA',
        code: 'AR',
    },
    {
        country_name: 'ARMENIA',
        code: 'AM',
    },
    {
        country_name: 'ARUBA',
        code: 'AA',
    },
    {
        country_name: 'AUSTRALIA',
        code: 'AS',
    },
    {
        country_name: 'AUSTRIA',
        code: 'AU',
    },
    {
        country_name: 'AZERBAIJAN',
        code: 'AJ',
    },
    {
        country_name: 'AZORES',
        code: 'AZ',
    },
    {
        country_name: 'BAHAMAS',
        code: 'BF',
    },
    {
        country_name: 'BAHRAIN',
        code: 'BA',
    },
    {
        country_name: 'BANGLADESH',
        code: 'BG',
    },
    {
        country_name: 'BARBADOS',
        code: 'BB',
    },
    {
        country_name: 'BELARUS',
        code: 'BO',
    },
    {
        country_name: 'BELGIUM',
        code: 'BE',
    },
    {
        country_name: 'BELIZE',
        code: 'BH',
    },
    {
        country_name: 'BENIN',
        code: 'BN',
    },
    {
        country_name: 'BERMUDA',
        code: 'BD',
    },
    {
        country_name: 'BHUTAN',
        code: 'BT',
    },
    {
        country_name: 'BOLIVIA',
        code: 'BL',
    },
    {
        country_name: 'BOSNIA AND HERZEGOVINA',
        code: 'BS',
    },
    {
        country_name: 'BOTSWANA',
        code: 'BC',
    },
    {
        country_name: 'BOUVET ISLAND',
        code: 'BV',
    },
    {
        country_name: 'BRAZIL',
        code: 'BR',
    },
    {
        country_name: 'BRITISH INDIAN OCEAN TERRITORY',
        code: 'IO',
    },
    {
        country_name: 'BRITISH VIRGIN ISLANDS',
        code: 'VI',
    },
    {
        country_name: 'BRITISH WEST INDIES',
        code: 'BW',
    },
    {
        country_name: 'BRUNEI',
        code: 'BX',
    },
    {
        country_name: 'BULGARIA',
        code: 'BU',
    },
    {
        country_name: 'BURKINA FASO',
        code: 'UV',
    },
    {
        country_name: 'BURMA',
        code: 'BM',
    },
    {
        country_name: 'BURUNDI',
        code: 'BY',
    },
    {
        country_name: 'CAMBODIA',
        code: 'CB',
    },
    {
        country_name: 'CAMEROON',
        code: 'CM',
    },
    {
        country_name: 'CAN',
        code: 'AV',
    },
    {
        country_name: 'CANADA',
        code: 'CA',
    },
    {
        country_name: 'CANARY ISLANDS',
        code: 'XY',
    },
    {
        country_name: 'CAPE VERDE',
        code: 'CV',
    },
    {
        country_name: 'CAYMAN ISLANDS',
        code: 'CJ',
    },
    {
        country_name: 'CENTRAL AFRICAN REPUBLIC',
        code: 'CT',
    },
    {
        country_name: 'CHAD',
        code: 'CD',
    },
    {
        country_name: 'CHILE',
        code: 'CI',
    },
    {
        country_name: 'CHINA',
        code: 'CH',
    },
    {
        country_name: 'CHINA TAIWAN',
        code: 'TW',
    },
    {
        country_name: 'CHRISTMAS ISLAND',
        code: 'KT',
    },
    {
        country_name: 'COCOS ISLAND',
        code: 'CK',
    },
    {
        country_name: 'COLOMBIA',
        code: 'CO',
    },
    {
        country_name: 'COMORO ISLANDS',
        code: 'CN',
    },
    {
        country_name: 'CONGO',
        code: 'CF',
    },
    {
        country_name: 'COOK ISLANDS',
        code: 'CW',
    },
    {
        country_name: 'COSTA RICA',
        code: 'CS',
    },
    {
        country_name: 'CROATIA',
        code: 'HR',
    },
    {
        country_name: 'CUBA',
        code: 'CU',
    },
    {
        country_name: 'CURACAO',
        code: 'UC',
    },
    {
        country_name: 'CYPRUS',
        code: 'CY',
    },
    {
        country_name: 'CZECH REPUBLIC',
        code: 'EX',
    },
    {
        country_name: 'DENMARK',
        code: 'DA',
    },
    {
        country_name: 'DJIBOUTI',
        code: 'DJ',
    },
    {
        country_name: 'DOMINICA',
        code: 'DO',
    },
    {
        country_name: 'DOMINICAN REPUBLIC',
        code: 'DR',
    },
    {
        country_name: 'DUBAI',
        code: 'DU',
    },
    {
        country_name: 'ECUADOR',
        code: 'EC',
    },
    {
        country_name: 'EGYPT',
        code: 'EG',
    },
    {
        country_name: 'EL SALVADOR',
        code: 'ES',
    },
    {
        country_name: 'EQUATORIAL GUINEA',
        code: 'EK',
    },
    {
        country_name: 'ERITREA',
        code: 'ER',
    },
    {
        country_name: 'ESTONIA',
        code: 'EN',
    },
    {
        country_name: 'ETHIOPIA',
        code: 'ET',
    },
    {
        country_name: 'FALKLAND ISLAND',
        code: 'FK',
    },
    {
        country_name: 'FAROE ISLANDS',
        code: 'FO',
    },
    {
        country_name: 'FIJI',
        code: 'FJ',
    },
    {
        country_name: 'FINLAND',
        code: 'FI',
    },
    {
        country_name: 'FRANCE',
        code: 'FR',
    },
    {
        country_name: 'FRENCH GUIANA',
        code: 'FG',
    },
    {
        country_name: 'FRENCH POLYNESIA',
        code: 'FP',
    },
    {
        country_name: 'FRENCH SOUTHERN & ANTARCTIC',
        code: 'FS',
    },
    {
        country_name: 'GABON',
        code: 'GB',
    },
    {
        country_name: 'GAMBIA',
        code: 'GA',
    },
    {
        country_name: 'GEORGIA REPUBLIC',
        code: 'GG',
    },
    {
        country_name: 'GERMANY',
        code: 'GM',
    },
    {
        country_name: 'GHANA',
        code: 'GH',
    },
    {
        country_name: 'GIBRALTAR',
        code: 'GI',
    },
    {
        country_name: 'GILBERT ISLANDS',
        code: 'GS',
    },
    {
        country_name: 'GREECE',
        code: 'GR',
    },
    {
        country_name: 'GREENLAND',
        code: 'GL',
    },
    {
        country_name: 'GRENADA',
        code: 'GJ',
    },
    {
        country_name: 'GUADELOUPE',
        code: 'GP',
    },
    {
        country_name: 'GUATEMALA',
        code: 'GT',
    },
    {
        country_name: 'GUERNSEY',
        code: 'GK',
    },
    {
        country_name: 'GUINEA',
        code: 'GV',
    },
    {
        country_name: 'GUINEA BISSAU',
        code: 'PU',
    },
    {
        country_name: 'GUYANA',
        code: 'GY',
    },
    {
        country_name: 'HAITI',
        code: 'HA',
    },
    {
        country_name: 'HEARD ISLAND AND MCDONALD ISLANDS',
        code: 'HM',
    },
    {
        country_name: 'HONDURAS',
        code: 'HO',
    },
    {
        country_name: 'HONG KONG',
        code: 'HK',
    },
    {
        country_name: 'HUNGARY',
        code: 'HU',
    },
    {
        country_name: 'ICELAND',
        code: 'IC',
    },
    {
        country_name: 'INDIA',
        code: 'IN',
    },
    {
        country_name: 'INDONESIA',
        code: 'ID',
    },
    {
        country_name: 'IRAN',
        code: 'IR',
    },
    {
        country_name: 'IRAQ',
        code: 'IZ',
    },
    {
        country_name: 'IRELAND',
        code: 'EI',
    },
    {
        country_name: 'ISLE OF MAN',
        code: 'IM',
    },
    {
        country_name: 'ISRAEL',
        code: 'IS',
    },
    {
        country_name: 'ITALY',
        code: 'IT',
    },
    {
        country_name: 'IVORY COAST',
        code: 'IV',
    },
    {
        country_name: 'JAMAICA',
        code: 'JM',
    },
    {
        country_name: 'JAPAN',
        code: 'JA',
    },
    {
        country_name: 'JERSEY',
        code: 'JE',
    },
    {
        country_name: 'JORDAN',
        code: 'JO',
    },
    {
        country_name: 'KAZAKHSTAN',
        code: 'KZ',
    },
    {
        country_name: 'KENYA',
        code: 'KE',
    },
    {
        country_name: 'KIRIBATI',
        code: 'KR',
    },
    {
        country_name: 'KOREA NORTH',
        code: 'KN',
    },
    {
        country_name: 'KOREA SOUTH',
        code: 'KS',
    },
    {
        country_name: 'KOSOVO',
        code: 'KV',
    },
    {
        country_name: 'KUWAIT',
        code: 'KU',
    },
    {
        country_name: 'KYRGYZSTAN',
        code: 'KG',
    },
    {
        country_name: 'LAOS',
        code: 'LA',
    },
    {
        country_name: 'LATVIA',
        code: 'LG',
    },
    {
        country_name: 'LEBANON',
        code: 'LE',
    },
    {
        country_name: 'LESOTHO',
        code: 'LT',
    },
    {
        country_name: 'LIBERIA',
        code: 'LI',
    },
    {
        country_name: 'LIBYA',
        code: 'LY',
    },
    {
        country_name: 'LIECHTENSTEIN',
        code: 'LS',
    },
    {
        country_name: 'LITHUANIA',
        code: 'LH',
    },
    {
        country_name: 'LUXEMBOURG',
        code: 'LU',
    },
    {
        country_name: 'MACAO',
        code: 'MC',
    },
    {
        country_name: 'MADAGASCAR',
        code: 'MA',
    },
    {
        country_name: 'MALAWI',
        code: 'MI',
    },
    {
        country_name: 'MALAYSIA',
        code: 'MY',
    },
    {
        country_name: 'MALDIVES',
        code: 'MV',
    },
    {
        country_name: 'MALI',
        code: 'ML',
    },
    {
        country_name: 'MALTA',
        code: 'MT',
    },
    {
        country_name: 'MARSHALL ISLANDS',
        code: 'RM',
    },
    {
        country_name: 'MARTINIQUE',
        code: 'MB',
    },
    {
        country_name: 'MAURITANIA',
        code: 'MR',
    },
    {
        country_name: 'MAURITIUS',
        code: 'MP',
    },
    {
        country_name: 'MAYOTTE',
        code: 'MF',
    },
    {
        country_name: 'MEXICO',
        code: 'MX',
    },
    {
        country_name: 'MOLDOVA',
        code: 'MD',
    },
    {
        country_name: 'MONACO',
        code: 'MN',
    },
    {
        country_name: 'MONGOLIA',
        code: 'MG',
    },
    {
        country_name: 'MONTENEGRO',
        code: 'MJ',
    },
    {
        country_name: 'MONTSERRAT',
        code: 'MH',
    },
    {
        country_name: 'MOROCCO',
        code: 'MO',
    },
    {
        country_name: 'MOZAMBIQUE',
        code: 'MZ',
    },
    {
        country_name: 'NAMIBIA',
        code: 'WA',
    },
    {
        country_name: 'NAURU',
        code: 'NR',
    },
    {
        country_name: 'NAVASSA ISLAND',
        code: 'BQ',
    },
    {
        country_name: 'NEPAL',
        code: 'NP',
    },
    {
        country_name: 'NETHERLANDS',
        code: 'NL',
    },
    {
        country_name: 'NETHERLANDS ANTILLES',
        code: 'AN',
    },
    {
        country_name: 'NEW CALEDONIA',
        code: 'NC',
    },
    {
        country_name: 'NEW ZEALAND',
        code: 'NZ',
    },
    {
        country_name: 'NICARAGUA',
        code: 'NU',
    },
    {
        country_name: 'NIGER',
        code: 'NG',
    },
    {
        country_name: 'NIGERIA',
        code: 'NI',
    },
    {
        country_name: 'NIUE',
        code: 'NE',
    },
    {
        country_name: 'NORFOLK ISLAND',
        code: 'NF',
    },
    {
        country_name: 'NORTH MACEDONIA',
        code: 'MK',
    },
    {
        country_name: 'NORWAY',
        code: 'NO',
    },
    {
        country_name: 'OMAN',
        code: 'MU',
    },
    {
        country_name: 'PAKISTAN',
        code: 'PK',
    },
    {
        country_name: 'PANAMA',
        code: 'PM',
    },
    {
        country_name: 'PAPUA NEW GUINEA',
        code: 'PP',
    },
    {
        country_name: 'PARAGUAY',
        code: 'PA',
    },
    {
        country_name: 'PERU',
        code: 'PE',
    },
    {
        country_name: 'PHILIPPINES',
        code: 'RP',
    },
    {
        country_name: 'PITCAIRN',
        code: 'PC',
    },
    {
        country_name: 'POLAND',
        code: 'PL',
    },
    {
        country_name: 'PORTUGAL',
        code: 'PO',
    },
    {
        country_name: 'PORTUGUESE TIMOR',
        code: 'PT',
    },
    {
        country_name: 'QATAR',
        code: 'QA',
    },
    {
        country_name: 'REUNION',
        code: 'RE',
    },
    {
        country_name: 'ROMANIA',
        code: 'RO',
    },
    {
        country_name: 'RUSSIA',
        code: 'RS',
    },
    {
        country_name: 'RWANDA',
        code: 'RW',
    },
    {
        country_name: 'SAINT BARTHELEMY',
        code: 'TB',
    },
    {
        country_name: 'SAINT HELENA',
        code: 'SH',
    },
    {
        country_name: 'SAINT KITTS AND NEVIS',
        code: 'SC',
    },
    {
        country_name: 'SAINT MARTIN',
        code: 'RN',
    },
    {
        country_name: 'SAINT PIERRE AND MIQUELON',
        code: 'SB',
    },
    {
        country_name: 'SAINT VINCENT AND THE GRENADINES',
        code: 'VC',
    },
    {
        country_name: 'SAMOA',
        code: 'WS',
    },
    {
        country_name: 'SAN MARINO',
        code: 'SM',
    },
    {
        country_name: 'SAO TOME & PRINCIPE',
        code: 'TP',
    },
    {
        country_name: 'SAUDI ARABIA',
        code: 'SA',
    },
    {
        country_name: 'SENEGAL',
        code: 'SG',
    },
    {
        country_name: 'SERBIA',
        code: 'RB',
    },
    {
        country_name: 'SEYCHELLES',
        code: 'SE',
    },
    {
        country_name: 'SIERRA LEONE',
        code: 'SL',
    },
    {
        country_name: 'SINGAPORE',
        code: 'SN',
    },
    {
        country_name: 'SLOVAKIA',
        code: 'LO',
    },
    {
        country_name: 'SLOVENIA',
        code: 'SI',
    },
    {
        country_name: 'SOLOMON ISLANDS',
        code: 'BP',
    },
    {
        country_name: 'SOMALIA',
        code: 'SO',
    },
    {
        country_name: 'SOUTH AFRICA',
        code: 'SF',
    },
    {
        country_name: 'SOUTH GEORGIA AND THE SOUTH',
        code: 'SX',
    },
    {
        country_name: 'SPAIN',
        code: 'SP',
    },
    {
        country_name: 'SRI LANKA',
        code: 'CE',
    },
    {
        country_name: 'ST HELENA',
        code: 'SH',
    },
    {
        country_name: 'ST KITTS & NEVIS',
        code: 'SC',
    },
    {
        country_name: 'ST LUCIA',
        code: 'ST',
    },
    {
        country_name: 'ST PIERRE & MIQUELON',
        code: 'SB',
    },
    {
        country_name: 'ST VINCENT',
        code: 'VC',
    },
    {
        country_name: 'SUDAN',
        code: 'SU',
    },
    {
        country_name: 'SURINAME',
        code: 'NS',
    },
    {
        country_name: 'SVALBARD AND JAN MAYEN',
        code: 'SV',
    },
    {
        country_name: 'SWAZILAND',
        code: 'WZ',
    },
    {
        country_name: 'SWEDEN',
        code: 'SW',
    },
    {
        country_name: 'SWITZERLAND',
        code: 'SZ',
    },
    {
        country_name: 'SYRIA',
        code: 'SY',
    },
    {
        country_name: 'TAIWAN',
        code: 'TA',
    },
    {
        country_name: 'TAJIKISTAN',
        code: 'TI',
    },
    {
        country_name: 'TANZANIA',
        code: 'TZ',
    },
    {
        country_name: 'THAILAND',
        code: 'TH',
    },
    {
        country_name: 'TIMOR-LESTE',
        code: 'TT',
    },
    {
        country_name: 'TOGO',
        code: 'TO',
    },
    {
        country_name: 'TOKELAU ISLANDS',
        code: 'TL',
    },
    {
        country_name: 'TONGA',
        code: 'TN',
    },
    {
        country_name: 'TRINIDAD & TOBAGO',
        code: 'TD',
    },
    {
        country_name: 'TUNISIA',
        code: 'TS',
    },
    {
        country_name: 'TURKEY',
        code: 'TU',
    },
    {
        country_name: 'TURKMENISTAN',
        code: 'TX',
    },
    {
        country_name: 'TURKS & CAICOS ISLANDS',
        code: 'TK',
    },
    {
        country_name: 'TUVALU',
        code: 'TV',
    },
    {
        country_name: 'UGANDA',
        code: 'UG',
    },
    {
        country_name: 'UKRAINE',
        code: 'UE',
    },
    {
        country_name: 'UNITED ARAB EMIRATES',
        code: 'AE',
    },
    {
        country_name: 'UNITED KINGDOM',
        code: 'UK',
    },
    {
        country_name: 'UNITED STATES',
        code: 'US',
    },
    {
        country_name: 'URUGUAY',
        code: 'UY',
    },
    {
        country_name: 'UZBEKISTAN',
        code: 'UZ',
    },
    {
        country_name: 'VANUATU',
        code: 'NH',
    },
    {
        country_name: 'VATICAN CITY',
        code: 'VT',
    },
    {
        country_name: 'VENEZUELA',
        code: 'VE',
    },
    {
        country_name: 'VIETNAM',
        code: 'VM',
    },
    {
        country_name: 'WALLIS & FUTUNA',
        code: 'WF',
    },
    {
        country_name: 'WESTERN SAHARA',
        code: 'WI',
    },
    {
        country_name: 'WESTERN SAMOA',
        code: 'WS',
    },
    {
        country_name: 'YEMEN',
        code: 'YE',
    },
    {
        country_name: 'ZAMBIA',
        code: 'ZA',
    },
    {
        country_name: 'ZIMBABWE',
        code: 'ZI',
    },
]
