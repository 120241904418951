import { useNavigate } from 'react-router-dom'
import { Footer } from '../../../../components/footer/footer'
import { Nav } from '../../../../components/nav/nav'
import { Header } from '../../../../components/header/header'
import uuid from 'react-uuid';
import { FaArrowLeft, FaQuestion, FaUpload } from 'react-icons/fa'

import { Input } from '../../../../components/input/input'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import { useDispatch } from 'react-redux'
import {
    prepareConsolidationExchangeDetail, prepareWarrantRequest,
    postConsolidationExchangeDetail, postWarrantDetail, postPlanOfArrangementDetail, handleUploadDoc
} from '../../../../redux/actions/auth.actions'
import React, { useRef } from 'react';
import { showToast } from '../../../../utils/functions';
import { TOAST_TYPE } from '../../../../utils/constants';
import { download } from '../confirmdownload/helper';
import { Tooltip } from '@material-ui/core';

export const ReviewInstruction = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const hiddenFileInput = useRef<any>(null);
    const { consolidation_exchange_detail, consolidation_exchange, warrant_request, event, user, warrant_detail } = useSelector((state: RootState) => state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            pin: event.eventtype_id === 1 || event.eventtype_id === 3 ? consolidation_exchange.pin : warrant_request.pin || '',
            authorized_signer: ''
        },
        validationSchema: Yup.object({
            pin: Yup.string().min(12, 'Must be 12 digits.')
                .max(12, 'Must be 12 digits.').required(),
            authorized_signer: user?.securityholder_detail?.ownership_type?.toLowerCase() === "individual" ? Yup.string() : Yup.string().required()
        }),
        onSubmit: async (values) => {
            //let doc_response:any =  await download(event, user, consolidation_exchange, warrant_request, warrant_detail)
            if (event.eventtype_id === 1 || event.eventtype_id === 3) {
                await dispatch(prepareConsolidationExchangeDetail(values))
                let stateVal = { ...consolidation_exchange, ...values }
                let issue_to = stateVal.issue_to ? stateVal.issue_to : 1;
                if (stateVal?.issue_to === '1') {
                    delete stateVal.issue_in_anothername
                }
                let delivery_type =  stateVal.delivery_type ? stateVal.delivery_type : 1;
                if (stateVal?.delivery_type === '1' || stateVal?.delivery_type === '3') {
                    delete stateVal.delivery_address
                }
                // if(doc_response.status){
                stateVal.summary_document = "temp url"//doc_response.data.location
                // }
                stateVal.issue_to = issue_to;
                stateVal.delivery_type = delivery_type;
                if (event.eventtype_id === 1) {
                    await dispatch(postConsolidationExchangeDetail(stateVal, navigate))
                } else {
                    //navigate('/confirm-download')
                    await dispatch(postPlanOfArrangementDetail(stateVal, navigate))
                }
            } else if (event.eventtype_id === 2) {
                let wire_unique_id = `${(user?.event_detail?.event_id || '')}_${(user?.ofac || '')}_${Math.floor(Math.random()*90000) + 10000}`
                if (warrant_request.payment_method === 'wire') {
                    await dispatch(prepareWarrantRequest({ ...values, wire_unique_id }))
                } else {
                    await dispatch(prepareWarrantRequest(values))
                }
                let stateVal = { ...warrant_request, ...values }
                let issue_to = stateVal.issue_to ? stateVal.issue_to : 1;
                if (stateVal?.issue_to === '1') {
                    delete stateVal.issue_in_anothername
                }
                let delivery_type =  stateVal.delivery_type ? stateVal.delivery_type : 1;
                if (stateVal?.delivery_type === '1' || stateVal?.delivery_type === '3') {
                    delete stateVal.delivery_address
                }
                if (warrant_detail.by_wire) {
                    stateVal.wire_unique_id = wire_unique_id
                } else {
                    delete stateVal.wire_unique_id
                }
                // if(doc_response.status){
                stateVal.summary_document = "temp url"//doc_response.data.location
                // }
                stateVal.issue_to = issue_to;
                stateVal.delivery_type = delivery_type;
                await dispatch(postWarrantDetail(stateVal, navigate))
            }
        },
    })
    const handleChangeLocal = async (file: any) => {
        if (file) {
            if (file.target.files[0].type === 'application/pdf') {
                const res = await dispatch(handleUploadDoc(file.target.files[0]))
                if (res.status) {
                    if(event.eventtype_id == 2){
                        dispatch(prepareWarrantRequest({ signing_authority: res.data.location }))
                    } else {
                        dispatch(prepareConsolidationExchangeDetail({ signing_authority: res.data.location }))
                    }
                } else {
                    showToast('Error uploading document, Try Again!', TOAST_TYPE.error)
                }
            } else {
                showToast('Only Pdf extensions are allowed', TOAST_TYPE.error)
            }
        }
    }
    const getHeaderText = () => {
        if (event.eventtype_id === 1) {
            return `Industries Inc. ${consolidation_exchange_detail.units_held} old for ${consolidation_exchange_detail.units_entitled} new Consolidation`;
        } else if (event.eventtype_id === 2) {
            return 'Industries Inc. Share Purchase Warrants Expiring May 10, 2022'
        } else {
            return 'ABC Industries Inc. Plan of Arrangement with DEF Industries Inc.'
        }
    }
    const getHolderName = (input) => `${input?.holder_reg1} ${input?.holder_reg2} ${input?.holder_reg3}`
    return (
        <div className="w-full flex flex-col items-center min-h-screen">
            <Nav
                enableProps={true}
            />
            <Header
                heading={event.event_name}
                description="Review of Submission"
            />

            <div className="page-padding space-y-8 py-10">
                <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="md:px-14 flex flex-col items-center justify-center space-y-8">
                    <div className="Roboto-Bold text-base md:text-xl lg:text-2xl xl:text-3xl uppercase text-blue-base text-center">
                        This is your final chance to make changes.
                    </div>
                    <div className="text-sm Roboto-Medium">
                        The registered securityholder named below, irrevocably
                        deposits the following securities/shares (check the box
                        next to the securities/shares you are depositing for
                        exchange today) with Odyssey in accordance with the
                        terms and conditions of the Letter of Transmittal (as
                        previously agreed):
                    </div>
                    <hr className="border border-gray2  w-full" />
                    <div className="w-full space-y-5">
                        <div className="space-y-4 md:space-y-10">
                            <div className="Roboto-Bold text-base md:text-xl lg:text-2xl xl:text-3xl uppercase text-blue-base text-center">
                                SUBMISSION SUMMARY
                            </div>
                            <div className="border space-y-6 border-blue-base p-8  text-sm 2xl:text-base Roboto-Regular w-full">
                                <div className="Roboto-Bold text-base md:text-xl ">
                                    <div>{getHolderName(user?.securityholder_detail)}</div>
                                    <div>
                                        <span>Name(s) of Authorized Signer(s)*: </span>
                                    </div>
                                    <div className="w-full md:w-1/2 self-start">
                                        <Input
                                            placeholder=" Enter Authorized Signer Name"
                                            name="authorized_signer"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.authorized_signer}
                                            errors={formik?.errors?.authorized_signer}
                                            touched={formik?.touched?.authorized_signer}
                                            width="full"
                                        /></div>
                                </div>
                                <div className="flex flex-col md:flex-row md:space-x-10 space-y-2 md:space-y-0">
                                    <div className="Roboto-Bold text-base md:text-xl ">
                                        Uploaded Documents:
                                    </div>
                                    <div className="flex flex-col text-blue-base text-xs md:text-sm Roboto-Bold space-y-1">
                                        {(consolidation_exchange?.signing_authority || warrant_request?.signing_authority) && <div className="underline" >
                                            <a href={(consolidation_exchange?.signing_authority || warrant_request?.signing_authority)} target='_blank' rel='noopener noreferrer'>Signing Authority</a>
                                        </div>}
                                        {consolidation_exchange?.election?.authority_doc && <div className="underline">
                                            <a href={consolidation_exchange?.election?.authority_doc} target='_blank' rel='noopener noreferrer'>Authority Document</a>
                                        </div>}
                                        {consolidation_exchange?.election?.consent_of_spouse && <div className="underline">
                                            <a href={consolidation_exchange?.election?.consent_of_spouse} target='_blank' rel='noopener noreferrer'>Consent of Spouse</a>
                                        </div>}
                                        {consolidation_exchange?.tax?.w8_form && <div className="underline">
                                            <a href={consolidation_exchange?.tax?.w8_form} target='_blank' rel='noopener noreferrer'>W8-BEN-E</a>
                                        </div>}
                                        {consolidation_exchange?.w9form?.signature_of_us_person && <div className="underline">
                                            <a href={consolidation_exchange?.w9form?.signature_of_us_person} target='_blank' rel='noopener noreferrer'>User Signature</a>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {user.display_signing_authority && <div className="Roboto-Medium text-xs sm:text-sm md:text-base lg:text-lg  space-x-1 md:space-x-2 flex flex-col sm:flex-row">
                            {/* <div className="flex">
                                <div>
                                    <span>*Authorized signers on behalf of an entity must provide evidence of signing authority. An appropriate legal document</span> 
                                <span><Tooltip title={<div style={{fontSize:"12px"}}>
                                        <div><b>Corporations–</b> please upload a corporate resolution dated within 6 months</div>
                                        <div><b>LLCs–</b> please upload the current Operating Agreement and a corporate resolution dated within 6 months</div>
                                        <div><b>Sole signing officer corporations or proprietorships-</b> please upload a notarized or medallion guaranteed sole director resolution</div>
                                        <div><b>Partnerships–</b> please upload the partnership agreement</div>
                                        <div><b>Trusts–</b> please upload the trust agreement and any supplemental agreements.</div>
                                        </div>}>

                                    <span className="w-3.5 h-3.5 cursor-pointer p-1 text-xs rounded-full bg-blue-base text-white flex items-center justify-center">
                                            <FaQuestion />
                                        </span>
                                </Tooltip>
                                </span>
                                <span>must be uploaded to continue.</span>
                                </div>
                            </div> */}
                             <span>   
                                <span className="">
                                    *Authorized signers on behalf of an entity must provide evidence of signing authority. An appropriate
                                </span>
                                <span className="flex space-x-1">
                                    <span className='text-blue-base'>Legal Document</span>
                                    <Tooltip title={<React.Fragment>
                                        <div><b>Corporations–</b> please upload a corporate resolution dated within 6 months.</div>
                                        <div><b>LLCs–</b> please upload the current Operating Agreement and a corporate resolution dated within 6 months.</div>
                                        <div><b>Sole signing officer corporations or proprietorships-</b> please upload a notarized or medallion guaranteed sole director resolution.</div>
                                        <div><b>Partnerships–</b> please upload the partnership agreement.</div>
                                        <div><b>Trusts–</b> please upload the trust agreement and any supplemental agreements.</div>
                                        </React.Fragment>}>
                                        <span className="w-3.5 h-3.5 cursor-pointer p-1 text-xs rounded-full bg-blue-base text-white flex items-center justify-center">
                                            <FaQuestion />
                                        </span>
                                    </Tooltip>
                                <span>must be uploaded to continue.</span>
                                </span>
                            </span>  
                            <button onClick={() => hiddenFileInput.current.click()} className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center" type="button" >
                                <FaUpload />
                                <input ref={hiddenFileInput} style={{ display: 'none' }} type="file" accept="application/pdf" onChange={(e) => handleChangeLocal(e)} />
                                <span>Upload</span>
                            </button>
                        </div>}
                    </div>

                    <div className="text-xs sm:text-sm Roboto-Medium">
                        By re-entering the 12-digit PIN provided, the registered
                        securityholder confirms that I am/we are the registered
                        securityholder(s), or have authorized signing authority
                        to provide instructions on behalf of the registered
                        securityholder(s). The registered securityholder is
                        submitting my/our digital signature(s) for the
                        electronic {event.eventtype_id === 2? "Exercise Form" : "Letter of Transmittal"} to
                        confirm that I/we have reviewed the information
                        submitted and that it is correct:
                    </div>
                    <div className="w-full md:w-1/2 self-start">
                        <Input
                            placeholder=" Enter PIN here to provide digital signature(s)"
                            name="pin"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pin}
                            errors={formik?.errors?.pin}
                            touched={formik?.touched?.pin}
                            width="full"
                        />
                    </div>

                    <div className="flex justify-end w-full">
                        {user.display_signing_authority
                            && (consolidation_exchange.signing_authority || warrant_request.signing_authority) &&
                            <button
                                type="submit"
                                className="btn-brown  whitespace-nowrap w-44">
                                CONTINUE
                            </button>}
                        {!(user.display_signing_authority) &&
                            <button
                                type="submit"
                                className="btn-brown  whitespace-nowrap w-44">
                                CONTINUE
                            </button>}
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}
