import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Header} from '../../../../components/header/header'

import {FaArrowLeft, FaDownload, FaPrint, FaSignOutAlt} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { download } from './helper'
import { onLogoutUser, resetEvents } from '../../../../redux/actions/auth.actions'
import { List, ListItem } from '@material-ui/core'

export const DownloadInstruction = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {consolidation_exchange_detail, warrant_detail, warrant_request, event, user, consolidation_exchange} = useSelector(state => state && state.VerificationReducer)

    const getHeaderText = () => {
        if(event.eventtype_id === 1){
            return `Industries Inc. ${consolidation_exchange_detail.units_held} old for ${consolidation_exchange_detail.units_entitled} new Consolidation`;
        } else if(event.eventtype_id === 2){
            return 'Industries Inc. Share Purchase Warrants Expiring May 10, 2022'
        } else {
            return ''
        }
    }
    const downloadFile = () => download(event, user, consolidation_exchange, warrant_request, warrant_detail, consolidation_exchange_detail)
        
    const signOut = () =>{dispatch(onLogoutUser(navigate))}
    return (
        <div className="w-full flex flex-col items-center min-h-screen">
            <Nav
                enableProps={true}
            />
            <Header
                heading={event.event_name}
                description="Download Confirmation"
            />

            <div className="page-padding space-y-8 py-10">
                {/* <div onClick={async() => {
                    await dispatch(resetEvents())
                    navigate(-1) ;
                }
                 } className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div> */}
                <div className="md:px-14 space-y-8">
                    <div className="text-xs sm:text-sm md:text-base lg:text-lg Roboto-Medium">
                        {/* {event.eventtype_id === 2 ? `Download a final copy of Warrant Exercise Confirmation.`: `Download a final copy of the Letter of Transmittal. If
                        you are submitting securities held as certificate(s),
                        you must send the first page of the submission sheet.`} */}
                        <div>Congratulations!  You have successfully submitted your documentation for this event.  You may download a Submission Confirmation which provides a summary of the details you have provided.</div>
                    </div>
                    <div>
                        <div className="text-xs sm:text-sm md:text-base lg:text-lg Roboto-Medium">Important note regarding stock certificates:</div>
                        <div>
                            <List>
                                <ListItem>If you are submitting certificates for exchange, you must send the original certificates by following the instructions on the Submission Confirmation.</ListItem>
                                <ListItem>If you have reported one or more lost certificates you will be contacted with further information regarding the process to replace the certificate(s).</ListItem>
                            </List>
                        </div>
                    </div>

                    {(consolidation_exchange?.issue_to == "2" || warrant_request?.issue_to =="2") && <div className="text-xs sm:text-sm md:text-base lg:text-lg Roboto-Medium">
                        REMINDER: If you have requested the entitlement(s) to be
                        issued in a name other than the registered owner, the
                        registered owner(s) or their authorized signing
                        officer(s) must print a copy, sign the signature panel
                        and have the signature(s) guaranteed following the
                        instructions on the form. The original document must be
                        sent to us at the address on the form.
                    </div>}
                    <hr className="border border-gray2  w-full" />

                    <div className="flex justify-end space-x-8 w-full">
                        <button  onClick={()=>downloadFile()} className="border-2 border-blue-base flex items-center text-xs md:text-sm lg:text-base px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                            <FaDownload/>
                            <span>Download</span>
                        </button>
                        <button  onClick={()=>signOut()} className="border-2 border-blue-base flex items-center text-xs md:text-sm lg:text-base px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                            <FaSignOutAlt/>
                            <span>Sign Out</span>
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
