import { useNavigate } from 'react-router-dom'
import { Footer } from '../../../../components/footer/footer'
import { Nav } from '../../../../components/nav/nav'
import { Header } from '../../../../components/header/header'

import { FaArrowLeft } from 'react-icons/fa'
import { TableHead } from '../../../../components/table/head'
import { DOLLAR_STRING, NUMBER_FORMAT, TABLE } from '../../../constants'
import { useDispatch } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { getPlanOfArrangementDetail, getConsolidationExchangeDetail, prepareConsolidationExchangeDetail, setConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import { showToast } from '../../../../utils/functions'
import { TOAST_TYPE } from '../../../../utils/constants'

export const DepositInstruction = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { event, user, consolidation_exchange_detail, consolidation_exchange, econsentAddedd } = useSelector((state: RootState) => state && state.VerificationReducer)
    const [certs, setCerts] = useState(consolidation_exchange.certs||[])
    const [entitlement, setEntitlement] = useState(consolidation_exchange.exchange_amount || 0)
    useEffect(() => {
        dispatch(event && event.eventtype_id === 1 ? getConsolidationExchangeDetail(navigate):getPlanOfArrangementDetail(navigate))
    }, [])

    const getTotalSecurities = () => {
        if (consolidation_exchange_detail
            && consolidation_exchange_detail.certificates
            && consolidation_exchange_detail.certificates.length) {
            return consolidation_exchange_detail.certificates.reduce((acc, item) => {
                acc = item && item.securities ? (acc + Number(item.securities)) : acc
                return acc
            }
                , 0)
        }
        else {
            return 0
        }
    }
    const addRoundOffValue = (val, type) => {
        if(val && type) {
            switch (type) {
                case 'cash_in_lieu':
                    return Math.trunc(val)
                case 'roundup':
                    return Math.ceil(val)
                case 'drop':
                    return Math.floor(val)
                case 'nearest':
                    return Math.round(val)
                case 'keep':
                    return val
                default:
                    return val
            }
        } else {
            return 0
        }
    }
    const getTotalEntitelment = () => {
        let total = entitlement || 0
        let totalEntitelment = addRoundOffValue(total, consolidation_exchange_detail.fractional_treatment)
        return {total, totalEntitelment}
    }
    const totalSecurities = useMemo(() => getTotalSecurities(), [JSON.stringify(consolidation_exchange_detail.certificates)]);
    const {total, totalEntitelment} = useMemo(() => consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash' ? {total:0, totalEntitelment:0} : getTotalEntitelment(), [entitlement])
    const handleSubmit = () => {
        let payload = {
            "certs":certs,
            "deposit_amount": totalSecurities && Number(totalSecurities).toFixed(10),
            "exchange_amount": total && Number(total).toFixed(10), 
            "exchange_amount_after_fraction_treatment": totalEntitelment, 
        }
        dispatch(prepareConsolidationExchangeDetail(payload))
        if(user?.event_detail?.is_transfer_allowed){
            navigate('/deposit-instruction-step-2')
        } else if(user?.event_detail?.allow_deliver_to_alternate_mailing_address || user?.event_detail?.allow_hold_for_pickup){
            navigate('/deposit-instruction-step-4')
        } else {
            if(event && event.eventtype_id === 1){
                navigate("/review-instruction")
            }
            if(event && event.eventtype_id === 3){
                if(consolidation_exchange_detail.holder_make_an_election ||
                    consolidation_exchange_detail.ask_residency_holder_status_question ||
                    consolidation_exchange_detail.accredited_investor_info_required) {
                        navigate("/election-declaration")
                    }else {
                        if ((consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash')) {
                            navigate('/Payment-methods')
                        } else {
                            if (consolidation_exchange_detail.ask_residency_holder_status_question) {
                                navigate('/residency-instructions')
                            } else {
                                navigate('/review-instruction')
                            }
                        }
                    }
            }
        }
    }
    const handleChange = (key: string, index: number, event) => {
        let temp = JSON.parse(JSON.stringify(consolidation_exchange_detail))
        if (key === 'deposit') {
            if (event.target.checked) {
                if(consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash'){
                    temp.certificates[index].deposit = true
                }else {
                    if (temp
                        && temp.units_entitled
                        && temp.units_held
                        && temp.certificates[index].securities) {
                        temp.certificates[index].deposit = true
                        temp.certificates[index].postConsolidation = (consolidation_exchange_detail.units_entitled / consolidation_exchange_detail.units_held) * temp.certificates[index].securities
                        setEntitlement(Number(entitlement)+Number(temp.certificates[index].postConsolidation))    
                    } else {
                        showToast('Error in Deposit, Check Values', TOAST_TYPE.error)
                    }
                }
                setCerts([...certs,temp.certificates[index]])
            } else {
                let tempCerts = [...certs]
                var removeIndex = tempCerts.map(item => item.id).indexOf(temp.certificates[index].id)
                ~removeIndex && tempCerts.splice(removeIndex, 1)
                setCerts(tempCerts)
                temp.certificates[index].deposit = false
                temp.certificates[index].certificate_lost = false
                if(consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash'){

                }else {
                    let prevVal = Number((consolidation_exchange_detail.units_entitled / consolidation_exchange_detail.units_held) * temp.certificates[index].securities)
                    temp.certificates[index].postConsolidation = 0
                    setEntitlement(Number(entitlement)-prevVal)   
                }
            }

        } else if (key === 'certificate_lost') {
            let tempCerts = [...certs]
            var certIndex = tempCerts.map(item => item.id).indexOf(temp.certificates[index].id)
            event.target.checked ? tempCerts[certIndex].certificate_lost = event.target.checked : delete tempCerts[certIndex].certificate_lost
            setCerts(tempCerts)
            temp.certificates[index].certificate_lost = event.target.checked
        }
        dispatch(setConsolidationExchangeDetail(temp))
    }

    return (
        <div className="w-full flex flex-col items-center min-h-screen">
            <Nav
                enableProps={true}
            />
            <Header
                heading={event.event_name}
                description="Deposit Instructions"
            />

            <div className="w-full page-padding space-y-8 py-10">
                <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>
                <div className="md:px-14 flex flex-col items-center justify-center space-y-8">
                    <div className="text-sm ">
                        The registered securityholder named below, irrevocably
                        deposits the following securities/shares (check the box
                        next to the securities/shares you are depositing for
                        exchange today) with Odyssey in accordance with the
                        terms and conditions of the Letter of Transmittal (as
                        previously agreed):
                    </div>
                    <div className="w-full">
                        <div className="border space-y-4 border-blue-base p-8  text-sm 2xl:text-base Roboto-Regular w-full">
                            <div className="flex flex-col md:flex-row md:space-x-2 md:items-center">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                                    Issue Name and Class for Exchange:
                                </div>
                                <div>{consolidation_exchange_detail.current_issue_name}  {consolidation_exchange_detail.current_issue_type}</div>
                            </div>
                            <div className="flex flex-col md:flex-row md:space-x-2 md:items-center">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                                    Registered in the Name of:
                                </div>
                                <div>{consolidation_exchange_detail.holder_reg1 && consolidation_exchange_detail.holder_reg1.substring(0, 120)}</div>
                            </div>
                            {/* <div className="flex flex-col md:flex-row md:space-x-2 md:items-center">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                                    Account Number:
                                </div>
                                <div>{consolidation_exchange_detail.account_number && consolidation_exchange_detail.account_number.substring(0, 10)}</div>
                            </div> */}
                            <div className="flex flex-col md:flex-row md:space-x-2 md:items-center">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base">
                                    To be Exchanged for:
                                </div>
                                <div>
                                    {consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash' ?
                                    `${consolidation_exchange_detail?.currency} ${DOLLAR_STRING.format(consolidation_exchange_detail?.payment_rate)} per share`
                                    :`${consolidation_exchange_detail?.new_issue_name}  ${consolidation_exchange_detail?.new_issue_type}`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full overflow-auto'>
                        <table className="border-collapse border border-blue-normal">
                            <TableHead headings={event.eventtype_id == 1 ? TABLE?.Consolidation?.HEADING : TABLE?.PlanofArrangement?.HEADING} />
                            <tbody className="Roboto-Medium">
                                {consolidation_exchange_detail && consolidation_exchange_detail.certificates && consolidation_exchange_detail.certificates.length
                                    && consolidation_exchange_detail.certificates.map((item, index) => {
                                        let temp = certs && certs.length && certs.find(o => o.id === item.id) ||{};
                                        return (
                                            <tr className={`text-center h-10 `}>
                                                <td className="border border-blue-normal">
                                                    {item.certificate_number}
                                                </td>
                                                <td className="border border-blue-normal">
                                                    {NUMBER_FORMAT(item.securities)}
                                                </td>
                                                {event.eventtype_id == 1 && <td className="border border-blue-normal">
                                                    {''}
                                                </td>}
                                                <td className="border border-blue-normal">
                                                    <input
                                                        type="checkbox"
                                                        className="cursor-pointer h-5 w-5 accent-[#0E294B]  outline-none"
                                                        checked={temp.deposit || item.deposit || false}
                                                        onChange={(e) => handleChange('deposit', index, e)}
                                                    />
                                                </td>
                                                <td className="border border-blue-normal">
                                                    {item.certificate_number && 
                                                    !['RDRS','DRS','BOOK'].includes(item.certificate_number.toUpperCase()) ?
                                                    <input
                                                        type="checkbox"
                                                        className="cursor-pointer h-5 w-5 accent-[#0E294B]  outline-none"
                                                        checked={temp.certificate_lost || item.certificate_lost || false}
                                                        disabled={(temp.deposit || item.deposit) ? false : true}
                                                        onChange={(e) => handleChange('certificate_lost', index, e)}
                                                    /> : ''}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                <tr className={`text-center h-10 `}>
                                    <td className="text-white border border-blue-normal uppercase bg-blue-normal">
                                        Total Deposited*
                                    </td>
                                    <td className="border border-blue-normal">
                                        {NUMBER_FORMAT(totalSecurities)}
                                    </td>
                                    {event.eventtype_id == 1 && <td className="border border-blue-normal">
                                        {NUMBER_FORMAT(totalEntitelment)}
                                    </td>}
                                    <td className="border border-blue-normal"></td>
                                    <td className="border border-blue-normal"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-sm flex flex-col space-y-3">
                        <div>
                            If any of the certificates deposited have been lost,
                            stolen or destroyed, please indicate above by
                            checking the box in the applicable row next to the
                            certificate number(s). Odyssey will email or mail
                            instructions on how these certificate(s) can be
                            replaced to effect their exchange.
                        </div>
                        <div>
                            If you have deposited one or more certificates for
                            exchange, you must submit them by mail, courier or
                            in person at one of our branches. In a later step,
                            you will be provided with the opportunity to
                            download and print a Submission Confirmation
                            with delivery instructions that you will attach to
                            your original certificate(s). Your DRS Statement
                            does not need to be submitted to us.
                        </div>
                    </div>
                    <div className="flex w-full justify-end">
                        <div className="md:w-1/2 flex flex-col items-end space-y-8">
                        {user && user.event_detail && user.event_detail.offer_econsent && 
                            <div className="flex items-center space-x-10 ">
                                <div className="Roboto-Medium">
                                    Consent to E-Delivery: { (user.securityholder_detail && user.securityholder_detail.email) ||econsentAddedd ? 'YES' : 'NO'}
                                </div>
                                {(user.securityholder_detail && user.securityholder_detail.email) ||econsentAddedd ? '' :<button className="btn-blue-outline whitespace-nowrap w-44"
                                    onClick={() => navigate('/econsent-email')}>
                                    PROVIDE ECONSENT
                                </button>}
                            </div>}
                            {certs &&certs.length ? <button
                                onClick={() =>
                                    handleSubmit()
                                }
                                className="btn-brown  whitespace-nowrap w-44">
                                ISSUE ENTITLEMENT(S)
                            </button>:''}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
