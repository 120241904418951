import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../components/button/button'
import {Footer} from '../../../components/footer/footer'
import {Nav} from '../../../components/nav/nav'
import {Header} from '../../../components/header/header'
import {Input} from '../../../components/input/input'

export const Mandatory = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            Securities: '',
        },
        validationSchema: Yup.object({
            Securities: Yup.string()
                .matches(/^[0-9,.]*$/)
                .required('Number of Securities Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/exhange')
        },
    })
    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <Header heading="Additional Validation Step" />
            <div className="w-11/12 md:w-9/12 mx-auto py-5 flex flex-col items-center justify-center space-y-10">
                <div className="space-y-4  ">
                    <div className="space-y-2">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                            Please enter the total number of securities you
                            currently hold in [name of securities issue]
                        </div>
                    </div>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="mt-10 sm:w-1/2 md:w-1/3 flex flex-col px-5 items-center  space-y-3">
                    <Input
                        placeholder="Enter number of Securities"
                        name="Securities"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.Securities}
                        errors={formik?.errors?.Securities}
                        touched={formik?.touched?.Securities}
                        width="full"
                    />
                    <button className="btn-brown w-full " type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>

            <div className="bottom-0 absolute w-full">
                <Footer />
            </div>
        </div>
    )
}
