import { FC, useState } from 'react'
import {
    FaBars,
    FaCalendarWeek,
    FaKey,
    FaPlus,
    FaShieldAlt,
    FaStickyNote,
    FaUsers,
} from 'react-icons/fa'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { imgs } from '../../assets/images/img'
import { MuiDrawer } from '../drawer'
import Switch from "react-switch";
import { set2FA } from '../../redux/actions/admin.actions'

type NavProps = {
    onLogout?: () => void
}
export const NavAdmin: FC<NavProps> = (props) => {
    const pathname = window.location.pathname
    const [drawerState, setdrawerState] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { eventDetails } = useSelector((state: any) => state.AdminReducer, shallowEqual)
    const { user } = useSelector((state: any) => state.VerificationReducer, shallowEqual)
    const [enableSFA, setenableSFA] = useState(user?.is2fa || false)
    const { id } = useParams<any>()
    const gotoCreateEvent = () => {
        navigate('/admin-events/create')
    }

    console.log('user', user);

    const onChange = async (checked: any) => {
        setenableSFA(checked)
        dispatch(set2FA({ is2fa: checked }, navigate, false))
    }

    const DrawerContent = (
        <div className="py-5 space-y-8 text-xs md:text-sm lg:text-base Poppins-Medium bg-gray-dashboard ">
            <div className="flex items-center space-x-4 text-blue-base px-10">
                <FaBars
                    className="text-xl Roboto-Bold cursor-pointer"
                    onClick={() => setdrawerState(!drawerState)}
                />
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-10 object-cover"
                    />
                    <div className="self-end Roboto-Medium text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
            </div>
            <div className='flex items-center px-10'>
                <span className='text-xs text-blue-base Roboto-Medium mr-2'>ENABLE 2FA</span>
                <Switch
                    borderRadius={11}
                    onColor={'#B4873F'}
                    // offColor="red"
                    checked={enableSFA}
                    onChange={(e) => onChange(e)}
                />
            </div>

            <div>
                <Link
                    to="/admin-clients"
                    onClick={() => setdrawerState(!drawerState)}
                    className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                    <FaUsers />
                    <span>Clients</span>
                </Link>
                <Link
                    to="/admin-events"
                    onClick={() => setdrawerState(!drawerState)}
                    className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                    <FaCalendarWeek />
                    <span>Events</span>
                </Link>
                {/* <Link
                    to="/user/event/securityholder"
                    className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                    <FaKey />
                    <span>SecurityHolders</span>
                </Link> */}
                {/* <Link
                    to="/admin-reports"
                    className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                    <FaStickyNote />
                    <span>Reports</span>
                </Link> */}

                <div
                    onClick={() => {
                        props.onLogout()
                        setdrawerState(!drawerState)
                    }}
                    className="flex space-x-4 Roboto-Medium items-center  px-10 text-blue-base cursor-pointer  py-2 sm:py-3 md:py-4">
                    <FaShieldAlt />
                    <span>Logout</span>
                </div>
            </div>
        </div>
    )
    return (
        <div className="w-full flex  items-center h-18 space-x-20   text-blue-base bg-white py-1 px-5 sm:px-8 border-b-2 border-blue-base">
            <div className="flex space-x-6 items-center ">
                <FaBars
                    className="text-xl cursor-pointer md:text-2xl lg:text-3xl Roboto-Bold"
                    onClick={() => setdrawerState(!drawerState)}
                />
                <div className="flex flex-col items-center">
                    <img
                        src={imgs.logo}
                        alt=""
                        className="h-6 w-full sm:h-10 object-cover"
                    />
                    <div className="self-end Roboto-Medium text-xs sm:text-sm ">
                        XPRESS
                    </div>
                </div>
            </div>
            <div className="Roboto-Bold text-xl lg:text-2xl">
                CORPORATE EVENT GATEWAY <span className='ml-5'> {pathname === `/admin-events/${id}/security-holders` && eventDetails?.event_name?.slice(0, 30) + (eventDetails?.event_name?.length > 30 ? "..." : "")}</span>
            </div>
            {
                pathname === '/admin-events' &&
                <button className="btn-brown uppercase px-8" style={{ display: 'flex', alignItems: 'center', right: 20, position: 'absolute' }} onClick={gotoCreateEvent}>
                    <FaPlus className='mr-2' /> {'CREATE NEW EVENT'}
                </button>
            }

            <MuiDrawer
                drawerState={drawerState}
                onToggleDrawer={() => setdrawerState(!drawerState)}
                content={DrawerContent}
            />
        </div>
    )
}
