import { useState } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Checkbox } from "../../../../components/checkbox/checkbox"
import { Footer } from "../../../../components/footer/footer"
import { Header } from "../../../../components/header/header"
import { Nav } from "../../../../components/nav/nav"
import { RootState } from "../../../../redux/rootstore"
import { DOLLAR_STRING } from "../../../constants"
import ChequeInstructions from "../../../../assets/doc/Paying by Certified Cheque or Bank Draft.pdf"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { prepareWarrantRequest } from "../../../../redux/actions/auth.actions"



export const WarrantPayment = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { warrant_detail, warrant_request, event } = useSelector((state: RootState) => state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            payment_method: warrant_request.payment_method
        },
        validationSchema: Yup.object({
            payment_method: Yup.string().required()
        }),
        onSubmit: (values) => {
            dispatch(prepareWarrantRequest(values))
            navigate("/review-instruction")
        }
    })
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav enableProps={true} />
                <Header
                    heading={event.event_name}
                    description="Payment Method"
                />
                <div className="page-padding space-y-8 py-10">
                    <div
                        onClick={() => navigate(-1)}
                        className=" text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                        <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col md:px-14 py-12 space-y-10 mx-auto">
                        <div className="space-y-1">
                            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                    Total Amount Payable: {DOLLAR_STRING.format(warrant_request.total_amount_payable)}
                                </div>
                                <div className="flex items-center space-x-10">
                                {warrant_detail.by_wire && <Checkbox
                                        text={<>Pay by wire (See <span className="cursor-pointer" style={{"color":"#B4873F"}}  onClick={()=>window.open(warrant_detail.warrant_payment_instruction)}>Instructions</span>)</>}
                                        name="payment_method"
                                        id="PaymentMethod1"
                                        value={'wire'}
                                        stateVal={formik.values.payment_method}
                                        disabled={false}
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} />}
                                    {warrant_detail.by_cheque && <Checkbox
                                        text={<>Pay by cheque (See <span className="cursor-pointer" style={{"color":"#B4873F"}}  onClick={()=>window.open(ChequeInstructions)}>Instructions</span>)</>}
                                        name="payment_method"
                                        id="PaymentMethod2"
                                        stateVal={formik.values.payment_method}
                                        disabled={false}
                                        value={'cheque'} 
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} />}
                                </div>

                            </div>
                            {formik?.errors?.payment_method && (
                                <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                                    {formik?.errors?.payment_method}
                                </div>
                            )}
                            <hr className="border border-gray2" />
                        </div>
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button className="btn-brown w-44" type="submit" >
                                CONTINUE
                            </button>
                        </div>
                    </div>
                        </form>
                </div>
            </div>
            <Footer />
        </div>)
}

export default WarrantPayment