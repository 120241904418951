import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../components/button/button'
import {Footer} from '../../../components/footer/footer'
import {Nav} from '../../../components/nav/nav'
import {Checkbox} from '../../../components/checkbox/checkbox'
import {InputWithLabel} from '../../../components/InputWithLabel/InputWithLabel'
import {SelectCountryWithLabel} from '../../../components/selectWithLabel/selectCountryWithLabel'
import {SelectStateWithLabel} from '../../../components/selectWithLabel/selectStateWithLabel'
import {FaUpload} from 'react-icons/fa'

export const Pay = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            streetAddress: '',
            city: '',
            zipCode: '',
            State: '',
            Country: '',
            Email: '',
            telephoneNumber: '',
            NewIssueSecurities: '',
        },
        validationSchema: Yup.object({
            NewIssueSecurities: Yup.string().required(),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/arrangement-event')
        },
    })

    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="w-11/12 md:w-9/12 mx-auto flex flex-col py-12 space-y-4 sm:space-y-10 accent-reddark">
                <div className="space-y-1">
                    <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                            Securities to be delivered to
                        </div>

                        <div className="md:space-x-3 space-y-3 md:space-y-0 flex flex-col md:flex-row">
                            <Checkbox
                                text={
                                    <div>
                                        Pay by wire (See{' '}
                                        <span className="text-brown">
                                            Instructions
                                        </span>
                                        )
                                    </div>
                                }
                                name="NewIssueSecurities"
                                id="NewIssueSecurities1"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={'SPay by wire (See Instructions)'}
                            />
                            <Checkbox
                                text={
                                    <div>
                                        Pay by cheque (See{' '}
                                        <span className="text-brown">
                                            Instructions
                                        </span>
                                        )
                                    </div>
                                }
                                name="NewIssueSecurities"
                                id="NewIssueSecurities2"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={'Pay by cheque (See Instructions)'}
                            />
                        </div>
                    </div>
                    {formik.errors.NewIssueSecurities && (
                        <div className="text-reddark text-xs">
                            {formik.errors.NewIssueSecurities}
                        </div>
                    )}
                </div>
                <hr className="border border-gray2" />
                <div className="flex items-center justify-end space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium py-5">
                    <div>Upload supporting documents</div>
                    <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                        <FaUpload />
                        <span>Upload</span>
                    </button>
                </div>
                <div className="mt-10  flex flex-col items-end justify-end w-full ">
                    <button className="btn-brown w-44 " type="submit">
                        EXERCISE
                    </button>
                </div>
            </form>

            <div className="bottom-0 absolute w-full">
                <Footer />
            </div>
        </div>
    )
}
