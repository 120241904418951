import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../components/footer/footer'
import {Nav} from '../../../components/nav/nav'
import {Header} from '../../../components/header/header'

import {FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import {Input} from '../../../components/input/input'
import {E_CONSENT} from '../../constants'
import {useDispatch} from 'react-redux'
import {shallowEqual, useSelector} from 'react-redux'
import {onEconsentEmail} from '../../../redux/actions/auth.actions'
export const EConsent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {event, user} = useSelector(state => state.VerificationReducer, shallowEqual)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Email: '',
        },
        validationSchema: Yup.object({
            Email: Yup.string().email().required('Number of Email Required'),
        }),
        onSubmit: (values) => {
            const {Email} = values
            dispatch(onEconsentEmail({Email},navigate))
            goToEventScreen()
        },
    })

    const goToEventScreen = ()=>{
        if(event.eventtype_id === 1 || event.eventtype_id === 3){
            navigate('/deposit-instruction')
        } else if(event.eventtype_id === 2 ){
            navigate('/exercise-instruction')
        }   
    }

    return (
        <div className="w-full flex flex-col justify-between min-h-screen">
            <div className="">
                <Nav
                    enableProps={true}
                />
                <Header
                    heading={event.event_name}
                    description="Consent to Electronic Delivery"
                />

                <div className="page-padding space-y-8 py-10">
                    <div className="text-blue-base text-sm flex items-center justify-between  Roboto-Bold">
                        <div
                            className="flex items-center space-x-2 cursor-pointer"
                            onClick={() => navigate(-1)}>
                            <FaArrowLeft /> <span>BACK</span>
                        </div>
                        <div
                            className="flex items-center space-x-2 cursor-pointer"
                            onClick={() => goToEventScreen()}>
                            <FaArrowRight /> <span>SKIP</span>
                        </div>
                    </div>
                    <div className="md:px-14 flex flex-col items-center justify-center space-y-8">
                        <div className="">
                            <div className="border space-y-4 border-blue-base p-8  text-sm 2xl:text-base Roboto-Regular">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    {E_CONSENT.HEADING}
                                </div>

                                <div>{E_CONSENT.DESCRIPTION}</div>
                                <div className="space-y-4">
                                    {E_CONSENT.NOTE.map((item, index) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="space-y-4  ">
                            <div className="space-y-2">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                    Please enter your email address
                                </div>
                            </div>
                        </div>
                        <form
                            onSubmit={formik.handleSubmit}
                            autoComplete="off"
                            className="mt-10 sm:w-1/2 md:w-1/3 flex flex-col px-5 items-center  space-y-3">
                            <Input
                                placeholder="Enter number of Email"
                                name="Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Email}
                                errors={formik?.errors?.Email}
                                touched={formik?.touched?.Email}
                                width="full"
                            />
                            {formik.values.Email !== '' && (
                                <button
                                    className="btn-brown w-full "
                                    type="submit">
                                    SUBMIT
                                </button>
                            )}
                            {console.log(formik.initialValues.Email)}
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
