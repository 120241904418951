import { CircularProgress } from '@material-ui/core'
import { createRef, useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactMaterialTable } from '../../components/table/customtable'
import { onGetEventDetailsOnly, onGetEvents, onGetSecurityHolders, resetAdminState } from '../../redux/actions/admin.actions'

export const SecurityHolders = () => {
    const { id } = useParams<any>()
    const dispatch = useDispatch<any>()
    const [mainloading, isMainLoading] = useState(false)
    const tableRef = createRef();
    const navigate = useNavigate()

    const { security_holders } = useSelector((state: any) => state.AdminReducer, shallowEqual)
    const [queryParams, setQueryParams] = useState<
        {
            submission_status: string
            search: string
            status: string
        }[]
    >([])
    useEffect(() => {
        async function anyNameFunction() {
            if (id) {
                isMainLoading(true)
                const query = `?event_id=${id}`
                await dispatch(onGetEventDetailsOnly(query, navigate))
                isMainLoading(false)
            }
        }

        anyNameFunction()
        return () => dispatch(resetAdminState())
    }, [])

    const [searchedInputStatus, setsearchedInputStatus] =
        useState<boolean>(false)
    const [searchedList, setsearchedList] = useState<
        {
            full_name: string
            event_date: string
            total_securities_exchanged: string
            total_securities_unexchanged: string
            event_status: string
        }[]
    >(security_holders?.results)

    var columns = [
        {
            field: 'full_name',
            title: 'HOLDER NAME',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium text-blue-base Roboto-Medium">
                        {rowData?.full_name}
                    </div>
                </div>
            ),
        },
        {
            field: 'event_name',
            title: '',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4" onClick={() => SUBMISSION_STATUS.filter(e => e?.value === rowData?.submission_status)[0]?.value === 'approval_required' || SUBMISSION_STATUS.filter(e => e?.value === rowData?.submission_status)[0]?.value === 'completed' || SUBMISSION_STATUS.filter(e => e?.value === rowData?.submission_status)[0]?.value === 'rejected' ?
                    navigate(`/admin-events/${id}/security-holders/${rowData?.submission_id}`) : console.log()}>
                    <div className="text-brown Roboto-Bold"
                    >REVIEW SUBMISSION</div>
                </div>
            ),
        },
        {
            field: 'submission_status',
            title: 'SUBMISSION STATUS',
            align: 'center',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex justify-center items-center space-x-4">
                    <div style={{ backgroundColor: SUBMISSION_STATUS.filter(e => e?.value === rowData?.submission_status)[0]?.color, padding: '5px 30px', color: '#fff' }} className="font-medium">
                        {SUBMISSION_STATUS.filter(e => e?.value === rowData?.submission_status)[0]?.label}
                    </div>
                </div>
            ),
        },
    ]

    const onEnterInput = (e: any, searchedBy: string, input: boolean) => {
        let query = { ...queryParams }
        if (e.target.value) {
            query[searchedBy] = e.target.value
        } else {
            delete query[searchedBy]
        }
        setQueryParams(query)
        tableRef.current && (tableRef.current as any).onQueryChange({ page: 0 })
    }

    const SUBMISSION_STATUS = [
        { label: 'NOT STARTED', value: 'not_started', color: '#3388AD' },
        { label: 'APPROVAL REQUIRED', value: 'approval_required', color: '#E8A466' },
        { label: 'REJECTED', value: 'rejected', color: '#F84C41' },
        { label: 'COMPLETED', value: 'completed', color: '#49B654' },
    ]

    if (mainloading) {
        return (
            <div className="w-full flex justify-center mt-2">
                <CircularProgress style={{ color: "#000", width: 20, height: 20 }} />
            </div>
        )
    }

    return (
        <div className="page-padding py-10">
            <div className="flex items-center w-full space-y-4 md:space-y-0 flex-col md:flex-row md:w-8/12 lg:w-2/3 md:space-x-10">
                <div className="h-7 w-full sm:h-8 md:h-10 relative flex items-center space-x-3 px-2 md:px-4 bg-white text-gray border  border-bordergray">
                    <input
                        className={`h-full text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base pace-x-2 sm:space-x-4 2xl:space-x-8 `}
                        onChange={(e: any) =>
                            onEnterInput(e, 'search', true)
                        }
                        placeholder="Search Securityholder by name or submission ID"
                    />
                    <FaSearch className="text-sm md:text-lg lg:text-xl 2xl:text-2xl bg-white text-home-layout-gray" />

                    {searchedInputStatus && searchedList.length > 0 && (
                        <div className="w-full bg-white z-50 p-2 absolute -left-3 text-xs top-7 border border-meeting-cardBorder rounded-sm">
                            {searchedList.map((item, index) => (
                                <div key={index}>{item.full_name}</div>
                            ))}
                        </div>
                    )}
                </div>
                <div
                    className={`md:w-1/2 h-7 sm:h-8 flex items-center px-2 text-blue-normal md:h-10 outline-none border border-blue-base Roboto-Medium text-sm`}>
                    <select
                        onChange={(e: any) =>
                            onEnterInput(e, 'submission_status', false)
                        }
                        className={`h-full outline-none w-full`}>
                        <option value="">SUBMISSION STATUS</option>
                        {SUBMISSION_STATUS.map((item: any, index: any) => (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="py-10">
                <ReactMaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={(query) => (
                        new Promise(async (resolve, reject) => {
                            console.log('query', query);
                            const page = query.page ? query.page + 1 : 1
                            const params = { ...queryParams, page }
                            const result = await dispatch(onGetSecurityHolders(id, params, navigate))
                            return resolve(result)
                        }))
                    }
                    searchbar={false}
                    toolbar={false}
                    exportbtn={false}
                    fontSize="16px"
                    pagging={true}
                    id="table"
                />
            </div>
        </div>
    )
}
