export const AUTH_ACTION_TYPES = {
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    HOME: "HOME",
    STATUS: "STATUS",
    ECONSENT_ADDED: "ECONSENT_ADDED",
    UPDATE: 'USER_UPDATE',
    EVENT_TERM_AND_CONDITIONS: 'EVENT_TERM_CONDITIONS',
    UPDATE_PORTAL_TERM_AND_CONDITIONS: 'UPDATE_PORTAL_TERM_AND_CONDITIONS',
    AUTH_FAILED: 'AUTH_FAILED',
    RESET_AUTH_STATE: 'RESET_AUTH_STATE',
    LOGOUT: 'LOGOUT',
    GET_CONSOLIDATION_EXCHANGE_DETAIL: 'GET_CONSOLIDATION_EXCHANGE_DETAIL',
    PREPARE_CONSOLIDATION_EXCHANGE_DETAIL: 'PREPARE_CONSOLIDATION_EXCHANGE_DETAIL',
    RESET_CONSOLIDATION_EXCHANGE_DETAIL: 'RESET_CONSOLIDATION_EXCHANGE_DETAIL',
    GET_WARRANT_DETAIL: 'GET_WARRANT_DETAIL',
    PREPARE_WARRANT_REQUEST: 'PREPARE_WARRANT_REQUEST',
    RESET_WARRANT_DETAIL: 'RESET_WARRANT_DETAIL',
}

export const ADMIN_AUTH_ACTION_TYPES = {
    LOGIN: 'ADMIN_LOGIN',
    AUTH_FAILED: 'ADMIN_AUTH_FAILED',
    SEND_OTP: 'SEND_OTP',
    SEND_OTP_FAILED: 'SEND_OTP_FAILED',
    CONFIRM_OTP: 'CONFIRM_OTP',
    CONFIRM_OTP_FAILED: 'CONFIRM_OTP_FAILED',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',
    REGISTER: 'REGISTER',
    SET_2FA: 'SET_2FA',
}

export const ADMIN_ACTION_TYPES = {
    ADD_CLIENT: 'ADD_CLIENT',
    ADD_CLIENT_FAILED: 'ADD_CLIENT_FAILED',
    ADD_EVENT: 'ADD_EVENT',
    ADD_EVENT_FAILED: 'ADD_EVENT_FAILED',
    UPDATE_EVENT: 'UPDATE_EVENT',
    UPDATE_EVENT_FAILED: 'UPDATE_EVENT_FAILED',
    DELETE_EVENT_ENTITLEMENT: 'DELETE_EVENT_ENTITLEMENT',
    DELETE_EVENT_ENTITLEMENT_FAILED: 'DELETE_EVENT_ENTITLEMENT_FAILED',
    GET_CLIENT: 'GET_CLIENT',
    GET_CLIENT_FAILED: 'GET_CLIENT_FAILED',
    GET_EVENTS: 'GET_EVENTS',
    GET_EVENTS_FAILED: 'GET_EVENTS_FAILED',
    GET_EVENT_TYPE: 'GET_EVENT_TYPE',
    GET_EVENT_TYPE_FAILED: 'GET_EVENT_TYPE_FAILED',
    GET_EVENT_DETAILS: 'GET_EVENT_DETAILS',
    GET_EVENT_DETAILS_FAILED: 'GET_EVENT_DETAILS_FAILED',
    GENERATE_AND_GET_ACCESS_CODES: 'GENERATE_AND_GET_ACCESS_CODES',
    RESET_DETAILS: 'RESET_DETAILS',
    GENERATE_AND_GET_ACCESS_CODES_FAILED: 'GENERATE_AND_GET_ACCESS_CODES_FAILED',
    GET_GENERATE_AND_GET_ACCESS_CODES: 'GET_GENERATE_AND_GET_ACCESS_CODES',
    GET_GENERATE_AND_GET_ACCESS_CODES_FAILED: 'GET_GENERATE_AND_GET_ACCESS_CODES_FAILED',
    SEND_ACCESS_CODES: 'SEND_ACCESS_CODES',
    SEND_ACCESS_CODES_FAILED: 'SEND_ACCESS_CODES_FAILED',
    ACCESS_CODES_LEFT: 'ACCESS_CODES_LEFT',
    ACCESS_CODES_LEFT_FAILED: 'ACCESS_CODES_LEFT_FAILED',
    SECURITY_HOLDERS: 'SECURITY_HOLDERS',
    SECURITY_HOLDERS_FAILED: 'SECURITY_HOLDERS_FAILED',
    SECURITY_HOLDERS_SUBMISSION_REVIEW: 'SECURITY_HOLDERS_SUBMISSION_REVIEW',
    SECURITY_HOLDERS_SUBMISSION_REVIEW_FAILED: 'SECURITY_HOLDERS_SUBMISSION_REVIEW_FAILED',
    CLIENT_DROPDOWN_LIST: 'CLIENT_DROPDOWN_LIST',
    CLIENT_DROPDOWN_LIST_FAILED: 'CLIENT_DROPDOWN_LIST_FAILED',
    SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION: 'SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION',
    SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION_FAILED: 'SECURITY_HOLDERS_SUBMISSION_REVIEW_ACTION_FAILED',
    RUN_ACTIVITY_REPORT: 'RUN_ACTIVITY_REPORT',
    RUN_ACTIVITY_REPORT_FAILED: 'RUN_ACTIVITY_REPORT_FAILED',
}

export const CLIENT_AUTH_ACTION_TYPES = {
    LOGIN: 'ADMIN_LOGIN',
    AUTH_FAILED: 'ADMIN_AUTH_FAILED',
    SEND_OTP: 'SEND_OTP',
    SEND_OTP_FAILED: 'SEND_OTP_FAILED',
    CONFIRM_OTP: 'CONFIRM_OTP',
    CONFIRM_OTP_FAILED: 'CONFIRM_OTP_FAILED',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',
    CLIENT_DASHBOARD: 'CLIENT_DASHBOARD',
    SET_EVENT_DETAIL: 'SET_EVENT_DETAIL',
    SET_EVENT_PARTICIPANTS: 'SET_EVENT_PARTICIPANTS',
    SET_SECURITY_HOLDERS: 'SET_SECURITY_HOLDERS',
    SET_SECURITY_HOLDER_DETAIL: 'SET_SECURITY_HOLDER_DETAIL',
    SET_CLIENT_DETAIL: 'SET_CLIENT_DETAIL',
    SET_2FA: 'SET_2FA'
}