import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft} from 'react-icons/fa'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import { prepareConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'

export const ResidencyInstructions = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)


    const formik = useFormik({
        initialValues: {
            resident: consolidation_exchange?.tax?.resident,
        },
        validationSchema: Yup.object({
            resident: Yup.string().required(),
        }),
        onSubmit: async(values) => {
            let prevTax = consolidation_exchange.tax
            await dispatch(prepareConsolidationExchangeDetail({tax: {...prevTax , ...values}}))
            if(values.resident !== "other"){
                navigate('/tax-certification-step-1')
            } else {
                if(consolidation_exchange.election.residency_tax_declaration == "2" && consolidation_exchange_detail.w9_from_us_holder) {
                    navigate('/tax-certification-step-2')
                } else if(consolidation_exchange.election.residency_tax_declaration == "1" && consolidation_exchange_detail.w8_from_non_us_holder) {
                    navigate('/tax-certification-step-4')
                    // if(consolidation_exchange.accredited_investor_status === "individual"){
                    //     navigate('/tax-certification-step-3')
                    // } else {
                    //         navigate('/tax-certification-step-4')
                    // }
                } else {
                    navigate('/review-instruction')
                }
            }
        },
    })
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav
                    enableProps={true}
                />
                <Header
                    heading={event.event_name}
                    description="Tax Election"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col py-12 space-y-10 accent-reddark">
                        <div className="space-y-1">
                            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                    I am resident of
                                </div>
                                <div className="flex items-center space-x-10">
                                    <Checkbox
                                        text="Canada"
                                        name="resident"
                                        id="resident1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'canada'}
                                        stateVal={formik.values.resident}
                                    />
                                    <Checkbox
                                        text="USA"
                                        name="resident"
                                        id="resident2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'usa'}
                                        stateVal={formik.values.resident}
                                    />
                                    <Checkbox
                                        text="Other"
                                        name="resident"
                                        id="resident2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'other'}
                                        stateVal={formik.values.resident}
                                    />
                                </div>
                            </div>
                            {formik.errors.resident && (
                                <div className="text-reddark text-xs">
                                    {formik.errors.resident}
                                </div>
                            )}
                        </div>
                        <hr className="border border-gray2" />
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button className="btn-brown w-44" type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}
