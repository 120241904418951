import {CLIENT_ENDPOINTS} from '../../utils/api/api'
import http from '../../utils/api/http'
import {setAppLoadingState} from './appLoading.action'
import {
    setLocalStorage,
    removeLocalStorage,
    showToast,
    getLocalStorage,
} from '../../utils/functions'
import {TOAST_TYPE, TOKEN_KEY} from '../../utils/constants/'
import { CLIENT_AUTH_ACTION_TYPES } from '../types'

export const clientLogin = (input, navigate) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.LOGIN,
                input
            )
            if (response.status !== 200 || !response.data) {
                showToast('Error in login', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
            } else {
                setLocalStorage(TOKEN_KEY, response.data?.data?.token)
                dispatch(setAppLoadingState(false))
                if(response?.data?.data){
                    delete response.data.data.token
                }    
                dispatch({
                    type: CLIENT_AUTH_ACTION_TYPES.SET_CLIENT_DETAIL,
                    payload: response?.data?.data
                })
                if(response?.data?.data?.is2fa){
                    return true
                }else {
                    navigate('/user/home')
                    return false
                }
            }
        } catch (err) {
            showToast('Error in login', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}

export const sendOtp = (input) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.SEND_OTP,
                input
            )
            if (response.status !== 200 || !response.data) {
                showToast('Error sending token', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('Token has been sent successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
           return true
        } catch (err) {
            showToast('Error sending token', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}

export const confirmOtp = (input, isLoginFlow) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                isLoginFlow?CLIENT_ENDPOINTS.CONFIRM_2FA_OTP : CLIENT_ENDPOINTS.CONFIRM_OTP,
                input
            )
            if (response.status !== 200 || !response.data) {
                showToast('Error confirming token', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            setLocalStorage(TOKEN_KEY, response.data?.token)
            showToast('Token has been confirmed successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
            dispatch({
                type: CLIENT_AUTH_ACTION_TYPES.CONFIRM_OTP,
                payload: response.data
            })
           return true
        } catch (err) {
            showToast('Error confirming token', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}

export const resetPassword = (input) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.RESET_PASSWORD,
                input
            )
            if (response.status !== 200 || !response.data) {
                showToast('Error setting password', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('Password has been reset successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
            setLocalStorage(TOKEN_KEY, response?.data?.token)
           return response?.data
        } catch (err) {
            showToast('Error setting password', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}

export const set2FA = (input, navigate, redirection=true) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.SETUP_2FA,
                input
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('Error setting 2 factor authentication', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('2 Factor authentication successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
            if(redirection){
                navigate('/user/home')
            }else {
                dispatch({
                    type: CLIENT_AUTH_ACTION_TYPES.SET_2FA,
                    payload: response?.data?.data
                })
            }
           return true
        } catch (err) {
            showToast('Error setting 2 factor authentication', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}
export const getClientEvents = (navigate, params) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                CLIENT_ENDPOINTS.DASHBOARD,{ params }
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('get event api failed', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            dispatch(setAppLoadingState(false))
            dispatch({
                type: CLIENT_AUTH_ACTION_TYPES.CLIENT_DASHBOARD,
                payload: response.data
            })
            return {
                data: response?.data?.results,
                page:params && params.page? params.page-1 : 0,
                totalCount: response?.data?.count,
              }
        } catch (err) {
            showToast('get event api failed', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}
export const updatePasswordAction = (input, navigate) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.UPDATE_PASSWORD,
                input
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('Error updating password', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('Password has been updated successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
           return true
        } catch (err) {
            showToast('Error updating password', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}
export const updatePasswordSendOtpAction = (input, navigate) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.UPDATE_PASSWORD_SEND_OTP,
                input
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('Error sending token', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('Token has been send successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
           return true
        } catch (err) {
            showToast('Error sending token', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}
export const updatePasswordConfirmOtpAction = (input, navigate) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.post(
                CLIENT_ENDPOINTS.UPDATE_PASSWORD_CONFIRM_OTP,
                input
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('Error confirming token', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            showToast('Token has been confirmed successfully', TOAST_TYPE.sucess)
            dispatch(setAppLoadingState(false))
           return true
        } catch (err) {
            showToast('Error confirming token', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
            return false
        }
    }
}
export const clientLogout = (navigate)=>{
    removeLocalStorage(TOKEN_KEY)
    navigate('/login')
}

export const getEventDetail = (eventId, navigate) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                CLIENT_ENDPOINTS.EVENT_DETAIL+`?event_id=${eventId}`,
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('get event detail api failed', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            dispatch(setAppLoadingState(false))
            dispatch({
                type: CLIENT_AUTH_ACTION_TYPES.SET_EVENT_DETAIL,
                payload: response?.data?.data
            })
           return true
        } catch (err) {
            showToast('get event detail api failed', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}

export const getEventParticipants = (eventId, navigate, pageNumber) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                CLIENT_ENDPOINTS.EVENT_PARTICIPANTS+`${eventId}`+`${pageNumber?"?page="+pageNumber:''}`,
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('get event participants api failed', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            dispatch(setAppLoadingState(false))
            dispatch({
                type: CLIENT_AUTH_ACTION_TYPES.SET_EVENT_PARTICIPANTS,
                payload: response?.data
            })
           return {
            data: response?.data?.results,
            page:pageNumber? pageNumber-1 : 0,
            totalCount: response?.data?.count,
          }
        } catch (err) {
            showToast('get event participants api failed', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}

export const getSecurityHolders = (navigate, params) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                CLIENT_ENDPOINTS.SECURITY_HOLDERS, { params }
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('get security holders api failed', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            dispatch(setAppLoadingState(false))
            dispatch({
                type: CLIENT_AUTH_ACTION_TYPES.SET_SECURITY_HOLDERS,
                payload: response?.data
            })
            return {
                data: response?.data?.results,
                page:params && params.page? params.page-1 : 0,
                totalCount: response?.data?.count,
              }
        } catch (err) {
            showToast('get security holders api failed', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}
export const getSecurityHolderDetail = (navigate, securityHolderId) => {
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                CLIENT_ENDPOINTS.SECURITY_HOLDERS+`/${securityHolderId}`,
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('get security holder detail api failed', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return false
            }
            dispatch(setAppLoadingState(false))
            dispatch({
                type: CLIENT_AUTH_ACTION_TYPES.SET_SECURITY_HOLDER_DETAIL,
                payload: response?.data?.data
            })
           return true
        } catch (err) {
            showToast('get security holder detail api failed', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}
export const getActivityReport = (eventId, navigate) =>{
    return async dispatch => {
        try {
            dispatch(setAppLoadingState(true))
            const response = await http.get(
                CLIENT_ENDPOINTS.ACTIVITY_REPORT+`${eventId}`,{
                    responseType: 'blob',
                }
            )
            if (response.status !== 200 || !response.data) {
                if(response.status === 401){
                    clientLogout(navigate)
                }
                showToast('Error getting activity report', TOAST_TYPE.error)
                dispatch(setAppLoadingState(false))
                return response.arrayBuffer()
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.csv');
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            showToast('Error getting activity report', TOAST_TYPE.error)
            dispatch(setAppLoadingState(false))
        }
    }
}