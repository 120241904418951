import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../../components/button/button'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'
import {SelectStateWithLabel} from '../../../../components/selectWithLabel/selectStateWithLabel'
import {SelectCountryWithLabel} from '../../../../components/selectWithLabel/selectCountryWithLabel'
import {Checkbox} from '../../../../components/checkbox/checkbox'

export const ArrangementIssueEntitlement = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            fullName: '',
            streetAddress: '',
            city: '',
            zipCode: '',
            State: '',
            Country: '',
            Email: '',
            telephoneNumber: '',
            IssueEntitlement: '',
        },
        validationSchema: Yup.object({
            // fullName: Yup.string()
            //     .min(3, 'Min character 3 required.')
            //     .max(16, 'Limit Max character 16')
            //     .required('Full Name Required'),
            // streetAddress: Yup.string().required('Street Address Required'),
            // city: Yup.string().required('City Required'),
            // zipCode: Yup.string().required('Zip Code Required'),
            // State: Yup.string().required('State Required'),
            // Country: Yup.string().required('Country Required'),
            // Email: Yup.string()
            //     .email('Invalid Email')
            //     .required('Email Required'),
            // telephoneNumber: Yup.number().required('Telephone Required'),
            // IssueEntitlement: Yup.string().required(),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/arrangement-dilivery')
        },
    })
    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="w-11/12 md:w-9/12 mx-auto flex flex-col py-12 space-y-10 accent-reddark">
                <div className="space-y-1">
                    <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                        <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                            Issue entitlement(s)
                        </div>
                        <Checkbox
                            text="In the name of registered securityholder"
                            name="IssueEntitlement"
                            id="IssueEntitlement1"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={'In the name of registered securityholder'}
                        />
                        <Checkbox
                            text="In another name"
                            name="IssueEntitlement"
                            id="IssueEntitlement2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={'In another name'}
                        />
                    </div>
                    {formik.errors.IssueEntitlement && (
                        <div className="text-reddark text-xs">
                            {formik.errors.IssueEntitlement}
                        </div>
                    )}
                </div>
                <hr className="border border-gray2" />
                <div className="mt-10 space-y-8">
                    <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                        <div className="space-y-3">
                            {' '}
                            <InputWithLabel
                                label="Full Name"
                                placeholder="Please enter full name"
                                name="fullName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.fullName}
                                errors={formik?.errors?.fullName}
                                touched={formik?.touched?.fullName}
                                width="full"
                            />
                            <InputWithLabel
                                label="Street Address"
                                placeholder="Please enter street"
                                name="streetAddress"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.streetAddress}
                                errors={formik?.errors?.streetAddress}
                                touched={formik?.touched?.streetAddress}
                                width="full"
                            />
                            <InputWithLabel
                                label="City"
                                placeholder="Please enter city"
                                name="city"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                errors={formik?.errors?.city}
                                touched={formik?.touched?.city}
                                width="full"
                            />
                            <InputWithLabel
                                label="Zip/Postal Code"
                                placeholder="Please enter Zip code"
                                name="zipCode"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.zipCode}
                                errors={formik?.errors?.zipCode}
                                touched={formik?.touched?.zipCode}
                                width="full"
                            />
                        </div>
                        <div className="space-y-3">
                            <SelectStateWithLabel
                                label="State"
                                name="State"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.State}
                                errors={formik?.errors?.State}
                                touched={formik?.touched?.State}
                                width="full"
                            />
                            <SelectCountryWithLabel
                                label="Country"
                                name="Country"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Country}
                                errors={formik?.errors?.Country}
                                touched={formik?.touched?.Country}
                                width="full"
                            />

                            <InputWithLabel
                                label="Email Address"
                                placeholder="Please enter email address"
                                name="Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.Email}
                                errors={formik?.errors?.Email}
                                touched={formik?.touched?.Email}
                                width="full"
                            />
                            <InputWithLabel
                                label="Telephone Number"
                                placeholder="Please enter telephone number"
                                name="telephoneNumber"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.telephoneNumber}
                                errors={formik?.errors?.telephoneNumber}
                                touched={formik?.touched?.telephoneNumber}
                                width="full"
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <button
                            className="btn-brown-outline w-44"
                            type="button">
                            SUBMIT
                        </button>
                    </div>
                    <hr className="border border-gray2" />
                </div>
                <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                    <div className="text-xs sm:text-sm Roboto-Medium text-blue-base">
                        Instruction to print LT and send in with medallion
                        guarantee
                    </div>

                    <button 
                    onClick={()=> navigate('/security-holder/arrangement-dilivery')}
                    className="btn-brown w-44" type="button">
                        CONTINUE
                    </button>
                </div>
            </form>

            <Footer />
        </div>
    )
}
