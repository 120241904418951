import { setNestedObjectValues, useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../components/footer/footer'
import { Nav } from '../../components/nav/nav'
import { Header } from '../../components/header/header'

import { FaArrowLeft, FaCheck } from 'react-icons/fa'
import { shallowEqual, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getEventTermAndConditions, updateEventTermAndConditionStatus } from '../../redux/actions/auth.actions'
import { useEffect, useMemo, useState } from 'react'
import { ASSETS } from '../../assets/path'
import { RootState } from '../../redux/rootstore'
import Pdf from '../../utils/helpers/pdf'
import { DockSharp } from '@material-ui/icons'

export const EventTermConditionScreen = () => {
    const navigate = useNavigate()
    const [documents, setDocuments] = useState([])
    const { event, event_conditions, user } = useSelector(
        (state: RootState) => state.VerificationReducer,
        shallowEqual
    )
    const securityholder_detail = user && user.securityholder_detail
    const [readed, setReaded] = useState(0)
    const dispatch = useDispatch<any>()

    useEffect(() => {
        dispatch(getEventTermAndConditions(navigate))
    }, [])

    const TERMS_AND_CONDITIONS = useMemo(() => {
        let head: { DOCS: any; heading?: any; des?: string };
        if (event_conditions && event_conditions.terms_and_conditions_of_event) {
            head = {
                heading: event_conditions.terms_and_conditions_of_event,
                des: `You must read the following before continuing`,
                DOCS: [],
            }
        }
        if (event_conditions.letter_of_transmittal) {
            head.DOCS.push({
                IMG: event_conditions.letter_of_transmittal,
                LINK: event_conditions.letter_of_transmittal,
                TEXT: 'Letter of Transmittal',
            })
        }
        if (event_conditions.warrant_document) {
            head.DOCS.push({
                IMG: event_conditions.warrant_document,
                LINK: event_conditions.warrant_document,
                TEXT: 'Warrant Document',
            })
        }
        if (event_conditions.exercise_form) {
            head.DOCS.push({
                IMG: event_conditions.exercise_form,
                LINK: event_conditions.exercise_form,
                TEXT: 'Excercise Form',
            })
        }
        if (event_conditions.other_document) {
            head.DOCS.push({
                IMG: event_conditions.other_document,
                LINK: event_conditions.other_document,
                TEXT: 'Other Document',
            })
        }
        return head
    }, [event_conditions])

    const handleSubmit = async () => {
        if (securityholder_detail.mark_read_term_and_conditions_of_event !== true) {
            await dispatch(updateEventTermAndConditionStatus(true, navigate))
        }
        if (user.event_detail && user.event_detail.extra_validation_required) {
            if (event.eventtype_id === 1) {
                navigate('/validation/security-issue')
            } else if (event.eventtype_id === 2) {
                navigate('/validation/warrant-issue')
            } else {
                navigate('/validation/security-issue')
            }
        } else {
            if (user && user.event_detail && user.event_detail.offer_econsent && user.securityholder_detail && !user.securityholder_detail.email) {
                navigate('/econsent-email')
            } else {
                if (event.eventtype_id === 1 ||event.eventtype_id === 3) {
                    navigate('/deposit-instruction')
                } else if (event.eventtype_id === 2) {
                    navigate('/exercise-instruction')
                }
            }
        }
    }

    const addDocument = (name) => {
        setDocuments(Array.from(new Set([...documents, name])))
    }
    return (
        <div className="w-full h-full flex flex-col items-center ">
            <Nav
                enableProps={true}
            />
            <Header heading={event.event_name} description={'Event Terms and Conditions'} />

            <div className="page-padding space-y-8 py-10">
                <div
                    onClick={() => navigate(-1)}
                    className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>
                <div className="px-14 mx-auto ">
                    <div className="space-y-4  ">
                        <div className="space-y-6">
                            <div className="Roboto-Bold text-base md:text-xl text-blue-base c-word-break">
                                {TERMS_AND_CONDITIONS?.heading}
                            </div>
                            <div className="text-xs md:text-sm Roboto-Regular">
                                {TERMS_AND_CONDITIONS?.des}
                            </div>
                            {TERMS_AND_CONDITIONS?.DOCS?.length > 0 && (
                                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                                    {TERMS_AND_CONDITIONS?.DOCS.map(
                                        (item: any, index: number) => (
                                            <div
                                                onClick={() => {
                                                    setReaded(readed+1)
                                                    item.read = true
                                                    window.open(item.LINK)
                                                }
                                                }
                                                key={index}
                                                className="space-y-2 cursor-pointer">
                                                <div className="border border-blue-base">
                                                    <Pdf url={item.LINK} />
                                                    <div className="bg-blue-base text-xs text-white text-center py-2">
                                                        {item.TEXT}
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-center" onClick={() => addDocument(item.TEXT)}>
                                                    <div className={`${item && item.read ? 'bg-green-normal' : 'bg-gray2'} text-white flex items-center justify-center w-10 h-10 rounded-full`}>
                                                        <FaCheck />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>

                            )}
                        </div>
                    </div>
                    {<div className="mt-10  flex flex-col items-end space-y-3 justify-end">
                       {Number(readed) >= Number(TERMS_AND_CONDITIONS?.DOCS?.length)  && <button
                            className="btn-brown w-44 "
                            type="button"
                            onClick={() => handleSubmit()}>
                            CONTINUE
                        </button>}
                    </div>}
                </div>
            </div>
            <Footer />
        </div>
    )
}
