import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/rootstore"
import LoadingOverlay from 'react-loading-overlay';


const EventRoute = ({ component: RouteComponent }) => {
    const { app_loading } = useSelector((state: RootState) => state && state.AppLoadingReducer)
    return (
        <React.Fragment>
            <LoadingOverlay
                active={app_loading}
                spinner={true}
                text='Loading...'
            >
                <RouteComponent />
            </LoadingOverlay>
        </React.Fragment>
    )
}



export default EventRoute

