export const TOAST_STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
};
export const STORAGE_KEYS = {
    TOKEN: 'access_token',
};

export const TOKEN_KEY = "Token";
export const EVENT_ID = "EventId";
export const SESSION_ID = "SessionId";
export const TOAST_TYPE = {
    error: "error",
    success: "success",
    warning: "warning",
    info: "info",
};