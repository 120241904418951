import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft, FaUpload} from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'

export const TAX_CERT_STEP3 = () => {
    const navigate = useNavigate()
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)
    const formik = useFormik({
        initialValues: {
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values) => {
             navigate('/review-instruction')
        },
    })
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav
                    enableProps={true}
                />
                <Header
                    heading={event.event_name}
                    description="Tax Election"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col space-y-10 accent-reddark">
                        <div className="space-y-1">
                            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                    Please download applicable W8, complete,
                                    sign and upload using the button below.
                                </div>
                            </div>
                        </div>
                        <hr className="border border-gray2" />
                        <div className="flex flex-col space-y-3 md:w-1/2 lg:w-1/3">
                            <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                <div> W8-BEN-E (Individuals)</div>
                                <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                    <FaUpload />
                                    <span>Download</span>
                                </button>
                            </div>
                            <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                <div> W8-BEN-E (Entities)</div>
                                <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                    <FaUpload />
                                    <span>Download</span>
                                </button>
                            </div>
                            <div className="flex items-center justify-between space-x-4 text-blue-base text-xs md:text-sm Roboto-Medium">
                                <div> W-8IMY (Intermediaries)</div>
                                <button className="border-2 border-blue-base flex items-center text-xs px-4 space-x-2 h-8 text-blue-base bg-white text-center">
                                    <FaUpload />
                                    <span>Download</span>
                                </button>
                            </div>
                        </div>
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button
                                className="btn-brown w-44"
                                type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}
