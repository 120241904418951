import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'
import {SelectStateWithLabel} from '../../../../components/selectWithLabel/selectStateWithLabel'
import {SelectCountryWithLabel} from '../../../../components/selectWithLabel/selectCountryWithLabel'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import {prepareConsolidationExchangeDetail} from '../../../../redux/actions/auth.actions'

export const DepositStep4 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const {consolidation_exchange_detail, consolidation_exchange, event, user} = useSelector((state: RootState)=> state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            street_address: consolidation_exchange.delivery_address ? consolidation_exchange.delivery_address.street_address || '' : '',
            city: consolidation_exchange.delivery_address ? consolidation_exchange.delivery_address.city || '' : '',
            zip_or_postal_code: consolidation_exchange.delivery_address ? consolidation_exchange.delivery_address.zip_or_postal_code || '' : '',
            state: consolidation_exchange.delivery_address ? consolidation_exchange.delivery_address.state || '' : '',
            country: consolidation_exchange.delivery_address ? consolidation_exchange.delivery_address.country || '' : '',
            unit_suite_apartment_number: consolidation_exchange.delivery_address ? consolidation_exchange.delivery_address.unit_suite_apartment_number || '' : '',
            delivery_type: String(consolidation_exchange.delivery_type) || '1',
        },
        validationSchema: Yup.object({
            street_address: Yup.string().when('delivery_type', {
                is: '2',
                then: Yup.string().required('Street Address Required')}),
            city: Yup.string().when('delivery_type', {
                is: '2',
                then: Yup.string().required('City Required')}),
            zip_or_postal_code: Yup.string().when(['delivery_type','country'], {
                is: (delivery_type, country)=> delivery_type === '2' && (country === 'US' || country === 'CA'),
                then: Yup.string().required('Zip Code Required')}),
            state: Yup.string().when(['delivery_type','country'], {
                is: (delivery_type, country)=> delivery_type === '2' && (country === 'US' || country === 'CA'),
                then: Yup.string().required('State Required')}),
            country: Yup.string().when('delivery_type', {
                is: '2',
                then: Yup.string().required('Country Required')}),
            unit_suite_apartment_number: Yup.string(),
            delivery_type: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            let {delivery_type,...delivery_address} = values;
            if(delivery_type === '1' || delivery_type === '3'){
                delivery_address = null
            }
            await dispatch(prepareConsolidationExchangeDetail({delivery_type,delivery_address}))
            if(event && event.eventtype_id === 1){
                navigate("/review-instruction")
            }
            if(event && event.eventtype_id === 3){
                if(consolidation_exchange_detail.holder_make_an_election ||
                    consolidation_exchange_detail.ask_residency_holder_status_question ||
                    consolidation_exchange_detail.accredited_investor_info_required) {
                        navigate("/election-declaration")
                    }else {
                        if ((consolidation_exchange_detail.entitlement && consolidation_exchange_detail.entitlement.toLowerCase() == 'cash')) {
                            navigate('/Payment-methods')
                        } else {
                            if (consolidation_exchange_detail.ask_residency_holder_status_question) {
                                navigate('/residency-instructions')
                            } else {
                                navigate('/review-instruction')
                            }
                        }
                    }
            }
        },
    })
    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav
                enableProps={true}
            />
            <Header
                heading={event.event_name}
                description="Deposit Instructions"
            />
            <div className="page-padding space-y-8 py-8 w-full">
                <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="md:px-14 mx-auto flex flex-col pb-4 space-y-10 accent-reddark">
                    <div className="space-y-8">
                    {formik.values.delivery_type == "3" && <div className='Roboto-Regular text-sm italic'>
                        Please note that if you have econsent on file and securties are not being transferred, your DRS will be delivered via email.
                        </div>}
                    {formik.values.delivery_type && formik.values.delivery_type == '2' && <div className="border border-blue-base p-8  text-sm 2xl:text-base Roboto-Regular space-y-4">
                            <div>
                                To have your entitlement(s) delivered to an
                                address other than the registered address on
                                file/provided, please complete the fields below.
                            </div>
                        </div>}
                        <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row md:space-x-10">
                            <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                Choose Delivery Option
                            </div>
                            <div className="flex space-x-4 space-y-2 md:space-y-0 flex-col md:flex-row">
                                <Checkbox
                                    text="Deliver to registered address"
                                    name="delivery_type"
                                    id="RegisteredAddress1"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'1'}
                                    stateVal={formik.values.delivery_type}
                                />
                                {user?.event_detail?.allow_deliver_to_alternate_mailing_address ? <Checkbox
                                    text="Deliver to  alternate mailing address"
                                    name="delivery_type"
                                    id="RegisteredAddress2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'2'}
                                    stateVal={formik.values.delivery_type}
                                />:""}
                                {user?.event_detail?.allow_hold_for_pickup ? <Checkbox
                                    text="Hold for pickup"
                                    name="delivery_type"
                                    id="RegisteredAddress3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'3'}
                                    stateVal={formik.values.delivery_type}
                                />:""}
                            </div>
                        </div>
                        {formik.errors.delivery_type && (
                            <div className="text-reddark text-xs">
                                {formik.errors.delivery_type}
                            </div>
                        )}
                    </div>
                    <hr className="border border-gray2" />
                    <div className="mt-10 space-y-8">
                       {formik.values.delivery_type && formik.values.delivery_type == '2' && <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                            <div className="space-y-3">
                                <SelectCountryWithLabel
                                    label="Country"
                                    name="country"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.country}
                                    errors={formik?.errors?.country}
                                    touched={formik?.touched?.country}
                                    width="full"
                                    arrange={true}
                                />
                                {['US','CA'].includes(formik.values.country) && <SelectStateWithLabel
                                    label="Province/State"
                                    name="state"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.state}
                                    errors={formik?.errors?.state}
                                    touched={formik?.touched?.state}
                                    width="full"
                                    countryCode={formik.values.country}
                                />}
                                <InputWithLabel
                                    label="Unit/Suite/Apt"
                                    placeholder="Please enter unit/suite/appartment number"
                                    name="unit_suite_apartment_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.unit_suite_apartment_number}
                                    errors={formik?.errors?.unit_suite_apartment_number}
                                    touched={formik?.touched?.unit_suite_apartment_number}
                                    width="full"
                                />
                            </div>
                            <div className="space-y-3">
                                <InputWithLabel
                                    label="Street Address"
                                    placeholder="Please enter street"
                                    name="street_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.street_address}
                                    errors={formik?.errors?.street_address}
                                    touched={formik?.touched?.street_address}
                                    width="full"
                                />

                                <InputWithLabel
                                    label="City"
                                    placeholder="Please enter city"
                                    name="city"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    errors={formik?.errors?.city}
                                    touched={formik?.touched?.city}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="Zip/Postal Code"
                                    placeholder="Please enter Zip code"
                                    name="zip_or_postal_code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.zip_or_postal_code}
                                    errors={formik?.errors?.zip_or_postal_code}
                                    touched={formik?.touched?.zip_or_postal_code}
                                    width="full"
                                />
                            </div>
                        </div>}
                        <div className="flex items-center justify-end">
                            <button className="btn-brown w-44" type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}
