import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Footer } from '../../../../components/footer/footer'
import { Nav } from '../../../../components/nav/nav'
import { Checkbox } from '../../../../components/checkbox/checkbox'
import { Header } from '../../../../components/header/header'
import { FaArrowLeft } from 'react-icons/fa'
import { Input } from '../../../../components/input/input'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/rootstore'
import { prepareConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'
import { useEffect } from 'react'

export const TAX_CERT_STEP1 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            accountType: consolidation_exchange?.tax?.accountType,
            social_insurance_number: consolidation_exchange?.tax?.social_insurance_number,
            business_number: consolidation_exchange?.tax?.business_number,
        },
        validationSchema: Yup.object({
            accountType: Yup.string().required(),
            social_insurance_number: Yup.string().when("accountType", {
                is: 'SIN',
                then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                    .min(9, 'Must be 9 digit').max(9, 'Must be 9 digit').required("SIN is required"),
                otherwise: Yup.string().when("accountType", {
                    is: 'TIN',
                    then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                        .min(9, 'Must be 9 digit').max(9, 'Must be 9 digit').required("TIN is required")
                })
            }),
            business_number: Yup.string().when("accountType", {
                is: 'Business Number',
                then: Yup.string()
                    .min(15, 'Must be 15 character').max(15, 'Must be 15 character').required("Buisness Number is required"),
                otherwise: Yup.string().when("accountType", {
                    is: 'SSN',
                    then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                        .min(9, 'Must be 9 digit').max(9, 'Must be 9 digit').required("SSN is required")
                })
            })
        }),
        onSubmit: async (values) => {
            let newValues = { ...values }
            delete newValues.accountType
            let prevTax = { ...consolidation_exchange.tax }
            if (consolidation_exchange.election.residency_tax_declaration == "2" && consolidation_exchange_detail.w9_from_us_holder) {
                await dispatch(prepareConsolidationExchangeDetail({ tax: { ...prevTax, ...newValues } }))
                navigate('/tax-certification-step-2')
            } else if (consolidation_exchange.election.residency_tax_declaration == "1" && consolidation_exchange_detail.w8_from_non_us_holder) {
                await dispatch(prepareConsolidationExchangeDetail({ tax: { ...prevTax, ...newValues } }))
                navigate('/tax-certification-step-4')
                // if(consolidation_exchange.accredited_investor_status === "individual"){
                //     navigate('/tax-certification-step-3')
                // } else {
                //         navigate('/tax-certification-step-4')
                // }
            } else {
                await dispatch(prepareConsolidationExchangeDetail({ tax: { ...prevTax, ...newValues } }))
                navigate('/review-instruction')
            }

        },
    })

    useEffect(() => {
        if (['SIN', 'TIN'].includes(formik.values.accountType)) {
            delete formik.values.business_number
        } else {
            delete formik.values.social_insurance_number
        }
    }, [formik.values.accountType])
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav
                    enableProps={true}
                />
                <Header
                    heading={event.event_name}
                    description="Tax Election"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col space-y-10 accent-reddark">
                        <div className="space-y-1">
                            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                    Please provide one of the following
                                </div>
                                <div className="flex flex-col space-y-4">
                                    <div className="flex items-center space-x-10">
                                        {consolidation_exchange?.tax?.resident === "usa" ? (<><Checkbox
                                            text={"TIN"}
                                            name="accountType"
                                            id="accountType1"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value="TIN"
                                            stateVal={formik.values.accountType}
                                        />
                                            <Checkbox
                                                text={"SSN"}
                                                name="accountType"
                                                id="accountType2"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={'SSN'}
                                                stateVal={formik.values.accountType}
                                            /></>) :
                                            (<><Checkbox
                                                text={"SIN"}
                                                name="accountType"
                                                id="accountType1"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value="SIN"
                                                stateVal={formik.values.accountType}
                                            />
                                                <Checkbox
                                                    text={"Business Number"}
                                                    name="accountType"
                                                    id="accountType2"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={'Business Number'}
                                                    stateVal={formik.values.accountType}
                                                /></>)
                                        }
                                    </div>
                                    {formik.errors.accountType && (
                                        <div className="text-reddark text-xs">
                                            {formik.errors.accountType}
                                        </div>
                                    )}
                                    <div>
                                        {formik.values.accountType === 'SIN' && (
                                            <Input
                                                placeholder="(9 digits)"
                                                name="social_insurance_number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.social_insurance_number}
                                                errors={formik?.errors?.social_insurance_number}
                                                touched={formik?.touched?.social_insurance_number}
                                                width="full"
                                            />
                                        )}
                                        {formik.values.accountType === 'TIN' && (
                                            <Input
                                                placeholder="(9 digits)"
                                                name="social_insurance_number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.social_insurance_number}
                                                errors={formik?.errors?.social_insurance_number}
                                                touched={formik?.touched?.social_insurance_number}
                                                width="full"
                                            />
                                        )}
                                        {formik.values.accountType === 'Business Number' && (
                                            <Input
                                                placeholder="(15 alphanumeric)"
                                                name="business_number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values
                                                        .business_number
                                                }
                                                errors={
                                                    formik?.errors
                                                        ?.business_number
                                                }
                                                touched={
                                                    formik?.touched
                                                        ?.business_number
                                                }
                                                width="full"
                                            />
                                        )}
                                        {formik.values.accountType === 'SSN' && (
                                            <Input
                                                placeholder="(9 digits)"
                                                name="business_number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={
                                                    formik.values
                                                        .business_number
                                                }
                                                errors={
                                                    formik?.errors
                                                        ?.business_number
                                                }
                                                touched={
                                                    formik?.touched
                                                        ?.business_number
                                                }
                                                width="full"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="border border-gray2" />

                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button
                                className="btn-brown w-44"
                                type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}
