import { FC } from 'react'

type InputProps = {
    placeholder: string
    name: string
    onChange: any
    onBlur: any
    value: any
    errors?: any
    touched?: any
    width: string
    type?: any
    id?: string
    readOnly?: boolean
    min?: any
    max?: any
    disabled?: any
}

export const Input: FC<InputProps> = (props) => (
    <div
        className={`space-y-1 flex flex-col items-center justify-center w-${props.width}`}>
        <input
            className={`h-7 sm:h-8 md:h-10 text-gray w-full outline-none px-2 text-xs sm:text-sm 2xl:text-base border space-x-2 sm:space-x-4 2xl:space-x-8 ${props.touched && props.errors
                ? 'border-reddark'
                : 'border-bordergray'} `}
            {...props} />

        {props.touched && props?.errors && (
            <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                {props?.errors}
            </div>
        )}
    </div>
)
