import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { imgs } from '../../assets/images/img'
import { Nav } from '../../components/nav/nav'
import { Footer } from '../../components/footer/footer'
import { InputPassword } from '../../components/inputPassword/inputPassword'
import { Input } from '../../components/input/input'
import { useDispatch } from 'react-redux'
import { onAdminConfirmOTP, onAdminLogin, onAdminSendOTP, onResetPassword } from '../../redux/actions/auth.actions'
import { FaTimes } from 'react-icons/fa'
import OtpInput from 'react-otp-input'
import { ModalComponent } from '../../components/Modal'
import { useState } from 'react'
import { confirmOtp } from '../../redux/actions/client.actions'
import { showToast } from '../../utils/functions'
import { TOAST_TYPE } from '../../utils/constants'

export const AdminLogin = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const [modal, setmodal] = useState(false)
    const [tokenmodal, settokenmodal] = useState(false)
    const [isLoginFlow, setIsLoginFlow] = useState(false)
    const [PasswordUpdateModal, setPasswordUpdateModal] = useState(false)
    const [otpData, setotpData] = useState(null)

    const [otp, setotp] = useState('')

    const handleChange = (value: any) => {
        setotp(value)
    }

    const handleSubmit = () => {
        dispatch(onAdminConfirmOTP({ email: isLoginFlow ? formik.values.email : formik1.values.email, otp }, handleOpenPassword, isLoginFlow))
    }

    const handleSubmitResend = (handleOpenToken) => {
        dispatch(onAdminSendOTP({ email: isLoginFlow ? formik.values.email : formik1.values.email }, handleOpenToken, isLoginFlow))
    }

    const handleResetPassword = () => {
        dispatch(onResetPassword({
            new_password1: formik1.values.password,
            new_password2: formik1.values.new_password2,
            "uid": otpData?.uid,
            "token": otpData?.token
        }, handleClosePassword))
    }

    const handleOpenToken = () => {
        setmodal(!modal)
        settokenmodal(!tokenmodal)
    }
    const handleOpenPassword = (data) => {
        if (isLoginFlow) {
            showToast('Login Success', TOAST_TYPE.success)
            navigate('/admin-clients')
            settokenmodal(!tokenmodal)
            setIsLoginFlow(false)
        } else {
            setotpData(data)
            settokenmodal(!tokenmodal)
            setPasswordUpdateModal(!PasswordUpdateModal)
        }
    }
    const handleClosePassword = () => {
        setPasswordUpdateModal(!PasswordUpdateModal)
    }

    const formik1 = useFormik({
        initialValues: {
            email: '',
            password: '',
            new_password2: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Email address Required'),
            password: Yup.string()
                .min(6, 'Must be greater 6 digits.')
                .max(12, 'Must be less than 12 digits.')
                .required('Password Required'),
            new_password2: Yup.string()
                .min(6, 'Must be greater 6 digits.')
                .max(12, 'Must be less than 12 digits.')
                .required('Password Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            // alert(JSON.stringify(values, null, 2))
            // navigate('/terms-conditions')
        },
    })


    const PasswordResetModalContent = (
        <div className="bg-white   outline-none max-h-96 space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setmodal(!modal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                // onSubmit={formik.handleSubmit}
                className="space-y-8 px-10 pt-10 pb-20">
                <div className="Roboto-Bold text-blue-base text-xl">
                    Password Reset
                </div>
                <div className="Roboto-Medium w-full text-blue-base text-sm">
                    Please enter your Email Address and click the Reset button.
                </div>
                <Input
                    placeholder="Enter Your Email"
                    name="email"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.email}
                    errors={formik1?.errors?.email}
                    touched={formik1?.touched?.email}
                    width="full"
                />

                <button
                    className="btn-brown w-full "
                    onClick={(e) => {
                        e.preventDefault()
                        handleSubmitResend(handleOpenToken)

                    }}>
                    RESET
                </button>
            </form>
        </div>
    )

    const TokenInputModalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => settokenmodal(!tokenmodal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                onSubmit={formik1.handleSubmit}
                className="space-y-8 px-10 pt-5 pb-10">
                <div className="Roboto-Bold text-blue-base text-xl">
                    Token Input
                </div>
                <div className="Roboto-Medium w-full text-blue-base text-sm">
                    Please enter token number which was sent to your email
                    address.
                </div>
                <div className="space-y-3 w-full">
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span className="text-white"> -</span>}
                        className="h-10 w-full"
                        inputStyle={{
                            border: '1px solid #8B8B8B',
                            height: '100%',
                            width: '100%',
                        }}
                        focusStyle={false}
                    />
                    <div
                        onClick={() => handleSubmitResend(null)}
                        className="Roboto-Bold cursor-pointer w-full text-right text-blue-base text-sm">
                        Resend Token
                    </div>
                </div>
                <div className="space-y-3">
                    <button
                        className="btn-brown w-full "
                        onClick={(e) => {
                            e.preventDefault()
                            handleSubmit()

                        }}>
                        {isLoginFlow ? 'SUBMIT' : "RESET"}
                    </button>
                    <button
                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                        type="submit">
                        CANCEL
                    </button>
                </div>
            </form>
        </div>
    )

    const PasswordUpdateModalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2.5/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setPasswordUpdateModal(!PasswordUpdateModal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                onSubmit={formik1.handleSubmit}
                className="space-y-8 px-10 pt-5 pb-10">
                <div className="Roboto-Bold text-blue-base text-xl">
                    Password Update
                </div>
                <div className="Roboto-Medium w-full text-blue-base text-sm">
                    Your new password should have one uppercase, one lowercase,
                    one number, and one symbol.
                </div>
                <div className="space-y-3">
                    <InputPassword
                        placeholder="Enter Your Password"
                        name="password"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.password}
                        errors={formik1?.errors?.password}
                        touched={formik1?.touched?.password}
                    />

                    <InputPassword
                        placeholder="Confirm Your Password"
                        name="new_password2"
                        onChange={formik1.handleChange}
                        onBlur={formik1.handleBlur}
                        value={formik1.values.new_password2}
                        errors={formik1?.errors?.new_password2}
                        touched={formik1?.touched?.new_password2}
                    />
                </div>
                <div className="space-y-3">
                    <button className="btn-brown w-full " onClick={(e) => {
                        e.preventDefault()
                        handleResetPassword()

                    }}>
                        RESET
                    </button>
                    <button
                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                        type="submit">
                        CANCEL
                    </button>
                </div>
            </form>
        </div>
    )


    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Email address Required'),
            password: Yup.string()
                .min(6, 'Must be greater 6 digits.')
                .max(12, 'Must be less than 12 digits.')
                .required('Password Required'),
        }),
        onSubmit: async (values) => {
            console.log(values)
            const { email, password } = values
            let show2fa = await dispatch(onAdminLogin({ email, password }, navigate))
            console.log('show2fa', show2fa);

            if (show2fa?.type !== 'ADMIN_AUTH_FAILED') {
                setIsLoginFlow(true)
                settokenmodal(true)
            }
            // navigate('/terms-conditions')
        },
    })

    const tokenInputformik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required(),
            //.min(6, 'Must be 6 digit').max(6, 'Must be 6 digit')
        }),
        onSubmit: async (values) => {
            let { otp } = values
            let email = formik.values.email
            let res = await dispatch(confirmOtp({ email, otp }, isLoginFlow))
            if (res) {
                settokenmodal(!tokenmodal)
                if (isLoginFlow) {
                    setIsLoginFlow(false)
                    navigate('/admin-clients')
                } else {
                    setPasswordUpdateModal(!PasswordUpdateModal)
                }
            }
        },
    })


    return (
        <div
            className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
            style={{
                backgroundImage: `url(${imgs.background})`,
            }}>
            <div
                className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
                style={{
                    backgroundImage: `url(${imgs.backgroundlayer})`,
                }}>
                <Nav enableProps={false} adminLogin={true} />
                <div className="flex justify-center  py-10 text-left min-h-full md:space-x-10 xl:space-x-16">
                    <div className="text-white w-1/2 lg:w-5/12 space-y-6 px-12 hidden sm:block">
                        <div className="text-2xl md:text-4xl Roboto-Bold">
                            OdysseyXpress Corporate Event Gateway
                        </div>
                        <div className="text-base md:text-lg Roboto-Regular">
                            Odyssey's Corporate Event Gateway makes it simple,
                            fast and easy for you to monitor and track the
                            status of your corporate actions and other corporate
                            events and for your registered holders to submit
                            their instructions securely online.
                        </div>
                    </div>
                    <div className="bg-white px-10 py-10  space-y-5 flex flex-col items-center">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="space-y-8">
                            <div className="flex flex-col items-center justify-center px-5">
                                <img
                                    src={imgs.logo}
                                    alt=""
                                    className="w-full object-cover"
                                />
                                <div className="self-end Roboto-Medium mr-1 text-xs sm:text-sm text-blue-base">
                                    XPRESS
                                </div>
                            </div>
                            <div className='space-y-4'>
                                <div className="Roboto-Bold text-blue-base text-xl lg:text-2xl">
                                    Login
                                </div>
                                <div className="space-y-3">
                                    <Input
                                        placeholder="Enter Your Email"
                                        name="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        errors={formik?.errors?.email}
                                        touched={formik?.touched?.email}
                                        width="full"
                                    />
                                    <InputPassword
                                        placeholder="Enter Your Password"
                                        name="password"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        errors={formik?.errors?.password}
                                        touched={formik?.touched?.password}
                                    />
                                </div>

                                <button
                                    // onClick={()=> navigate('/admin-auth')}
                                    className="btn-brown w-full mb-5"
                                    type="submit">
                                    LOGIN
                                </button>
                            </div>
                        </form>
                        <div
                            onClick={() => setmodal(!modal)}
                            className="Roboto-Medium cursor-pointer text-center w-full text-black-normal text-sm">
                            Forgot your login details? Click&nbsp;<span className='Roboto-Bold text-blue-base'>here</span>.
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
                <ModalComponent
                    modalState={modal}
                    handleModal={() => setmodal(!modal)}
                    content={PasswordResetModalContent}
                />
                <ModalComponent
                    modalState={tokenmodal}
                    handleModal={() => settokenmodal(!tokenmodal)}
                    content={TokenInputModalContent}
                />
                <ModalComponent
                    modalState={PasswordUpdateModal}
                    handleModal={() =>
                        setPasswordUpdateModal(!PasswordUpdateModal)
                    }
                    content={PasswordUpdateModalContent}
                />
            </div>
        </div>
    )
}
