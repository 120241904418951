import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { InputPassword } from '../../../components/inputPassword/inputPassword'
import { Input } from '../../../components/input/input'
import OtpInput from 'react-otp-input'
import { useSelector } from 'react-redux'
import { updatePasswordAction, updatePasswordSendOtpAction, updatePasswordConfirmOtpAction } from '../../../redux/actions/client.actions'
import { useNavigate } from 'react-router-dom';

export const UpdatePassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { client_detail } = useSelector(state => state && state.ClientReducer)
    const [currentView, setCurrentView] = useState(client_detail.is2fa ? "EMAIL_VIEW" : "UPDATE_VIEW")
    useEffect(() => {
        if (client_detail.is2fa) {
            setCurrentView("EMAIL_VIEW")
        } else {
            setCurrentView("UPDATE_VIEW")
        }

    }, [client_detail])
    const passwordInputformik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            old_password: Yup.string().required(),
            new_password: Yup.string().matches(/^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/, "Your new password should have one uppercase, one lowercase, one number, and one symbol").required(),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('new_password'), null], 'Passwords must match').required()
        }),
        onSubmit: async (values) => {
            dispatch(updatePasswordAction(values, navigate))
        }
    })
    const passwordResetformik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Email address Required'),
        }),
        onSubmit: async (values) => {
            let response = await dispatch(updatePasswordSendOtpAction(values, navigate))
            response && setCurrentView("OTP_VIEW")
        }
    })

    const ConfirmToken = () => {
        const tokenInputformik = useFormik({
            initialValues: {
                otp: ''
            },
            validationSchema: Yup.object({
                otp: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required(),
                //.min(6, 'Must be 6 digit').max(6, 'Must be 6 digit')
            }),
            onSubmit: async (values) => {
                let { otp } = values
                let response = await dispatch(updatePasswordConfirmOtpAction({ email: passwordResetformik.values.email, otp }, navigate))
                response && setCurrentView('UPDATE_VIEW')
            }
        })
        return (
            <div className="bg-white outline-none space-y-4 flex flex-col items-center">
                <form
                    onSubmit={tokenInputformik.handleSubmit}
                    className="space-y-8 px-10 pt-5 pb-10">
                    <div className="Roboto-Bold text-blue-base text-xl">
                        Token Input
                    </div>
                    <div className="Roboto-Medium w-full text-blue-base text-sm">
                        Please enter token number which was sent to your email
                        address.
                    </div>
                    <div className="space-y-3 w-full">
                        <OtpInput
                            value={tokenInputformik.values.otp}
                            onChange={(otp) => tokenInputformik.setFieldValue("otp", otp)}
                            numInputs={6}
                            separator={<span className="text-white"> -</span>}
                            className="h-10"
                            inputStyle={{
                                border: '1px solid #8B8B8B',
                                height: '100%',
                            }}
                            focusStyle={false}
                        />
                        {tokenInputformik.errors.otp && (
                            <div className="text-reddark text-xs">
                                {tokenInputformik.errors.otp}
                            </div>
                        )}
                        <div
                            onClick={async () => {
                                await dispatch(updatePasswordSendOtpAction({ email: passwordResetformik.values.email }, navigate))
                            }}
                            className="Roboto-Bold cursor-pointer w-full text-right text-blue-base text-sm">
                            Resend Token
                        </div>
                    </div>
                    <div className="space-y-3">
                        <button
                            className="btn-brown w-full "
                            type='submit'>
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
        )
    }
    return (
        <React.Fragment>
            {currentView === 'UPDATE_VIEW' &&
                <div className="bg-white outline-none space-y-4 flex flex-col items-center">
                    <form
                        onSubmit={passwordInputformik.handleSubmit}
                        className="space-y-8 px-10 pt-5 pb-10">
                        <div className="Roboto-Bold text-blue-base text-xl">
                            Change Password
                        </div>
                        <div className="space-y-3">
                            <InputPassword
                                placeholder="Old Password"
                                name="old_password"
                                onChange={passwordInputformik.handleChange}
                                value={passwordInputformik.values.old_password}
                                onBlur={passwordInputformik.handleBlur}
                                errors={passwordInputformik?.errors?.old_password}
                                touched={passwordInputformik?.touched?.old_password} />
                            <InputPassword
                                placeholder="New Password"
                                name="new_password"
                                onChange={passwordInputformik.handleChange}
                                value={passwordInputformik.values.new_password}
                                onBlur={passwordInputformik.handleBlur}
                                errors={passwordInputformik?.errors?.new_password}
                                touched={passwordInputformik?.touched?.new_password} />

                            <InputPassword
                                placeholder="Confirm New Password"
                                name="confirm_password"
                                onChange={passwordInputformik.handleChange}
                                value={passwordInputformik.values.confirm_password}
                                onBlur={passwordInputformik.handleBlur}
                                errors={passwordInputformik?.errors?.confirm_password}
                                touched={passwordInputformik?.touched?.confirm_password} />
                        </div>
                        <div className="space-y-3">
                            <button className="btn-brown w-full " type='submit'>
                                UPDATE
                            </button>
                        </div>
                    </form>
                </div>
            }
            {currentView === 'EMAIL_VIEW' &&
                <div className="bg-white outline-none space-y-4 flex flex-col items-center">
                    <form
                        onSubmit={passwordResetformik.handleSubmit}
                        className="space-y-8 px-10 pt-10 pb-20">
                        <div className="Roboto-Bold text-blue-base text-xl">
                            Change Password
                        </div>
                        <div className="Roboto-Medium w-full text-blue-base text-sm">
                            Instructions to change your password will be sent to your email address
                        </div>
                        <Input
                            placeholder="Enter Your Email"
                            name="email"
                            onChange={passwordResetformik.handleChange}
                            onBlur={passwordResetformik.handleBlur}
                            value={passwordResetformik.values.email}
                            errors={passwordResetformik?.errors?.email}
                            touched={passwordResetformik?.touched?.email}
                        />

                        {passwordResetformik.values.email && <button
                            className="btn-brown w-full "
                            type='submit'
                        >
                            SUBMIT
                        </button>}
                    </form>
                </div>}
            {currentView === 'OTP_VIEW' && <ConfirmToken />}
        </React.Fragment>
    )
}