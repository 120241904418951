import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../components/button/button'
import {Footer} from '../../../components/footer/footer'
import {Nav} from '../../../components/nav/nav'
import {Header} from '../../../components/header/header'
import {Input} from '../../../components/input/input'
import {warrantExercise} from '../../constants'

export const WarrantExercise = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            Email: '',
        },
        validationSchema: Yup.object({
            Securities: Yup.string()
                .email('Invalid Email')
                .required('Email Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            // navigate('/security-holder/issue-entitlement')
        },
    })

    const Securitiesformik = useFormik({
        initialValues: {
            Securities: '',
        },
        validationSchema: Yup.object({
            Securities: Yup.string()
                .matches(/^[0-9,.]*$/)
                .required('Number of Securities Required'),
        }),
        onSubmit: (values) => {
            console.log(values)
            alert(JSON.stringify(values, null, 2))
            navigate('/security-holder/issue-securities')
        },
    })

    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav enableProps={true} />
            <Header heading="Additional Validation Step" />
            <div className="w-full">
                <div className="w-11/12 md:w-9/12 mx-auto py-5 flex flex-col items-center justify-center space-y-10">
                    <div className="space-y-4  ">
                        <div className="space-y-2">
                            <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                Please enter the total number of securities you
                                currently hold in [name of securities issue]
                            </div>
                        </div>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="mt-10 sm:w-1/2 md:w-1/3 flex flex-col px-5 items-center  space-y-3">
                        <Input
                            placeholder="Enter number of Securities"
                            name="Securities"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Email}
                            errors={formik?.errors?.Email}
                            touched={formik?.touched?.Email}
                            width="full"
                        />
                        <button className="btn-brown w-full " type="submit">
                            SUBMIT
                        </button>
                    </form>
                </div>
                <form
                    onSubmit={Securitiesformik.handleSubmit}
                    autoComplete="off"
                    className="text-left w-full px-5 sm:px-10 md:px-20 my-10 ">
                    <div className="Roboto-Bold text-base md:text-2xl text-blue-base mb-10">
                        Warrant Exercise
                    </div>
                    <div className="space-y-2">
                        {warrantExercise.map((item, index) => (
                            <div
                                key={index}
                                className="border-b border-gray2 text-xs sm:text-sm Roboto-Medium py-2">
                                {item}
                            </div>
                        ))}
                    </div>

                    <div className="space-x-6 mt-12 flex items-center">
                        <div className="text-xs sm:text-sm Roboto-Medium">
                            Number of new securities to be purchased:
                        </div>
                        <Input
                            placeholder="Enter number of Securities"
                            name="Securities"
                            onChange={Securitiesformik.handleChange}
                            onBlur={Securitiesformik.handleBlur}
                            value={Securitiesformik.values.Securities}
                            errors={Securitiesformik?.errors?.Securities}
                            touched={Securitiesformik?.touched?.Securities}
                            width="w-1/3"
                        />
                    </div>
                    <div className="w-full  flex justify-end mt-5">
                        <div className="space-y-2">
                            <div className="text-xs sm:text-sm md:text-base text-blue-base Roboto-Bold text-right">
                                eConsent Flag
                            </div>
                            <button className="btn-brown w-44 " type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <Footer />
        </div>
    )
}
