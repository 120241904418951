import {FC} from 'react'
import countries from '../../utils/constants/countries'

type inputWithLabelProps = {
    label: string
    name: string
    onChange: any
    onBlur: any
    value: any
    errors: any
    touched: any
    width: string
    arrange ?: boolean
}

export const SelectCountryWithLabel: FC<inputWithLabelProps> = (props) => {
    function arraymove(arr) {
        let us_index = arr.findIndex(ele=> ele.name.common === "United States")
        let us_element = arr[us_index];
        arr.splice(us_index, 1);
        let ca_index = arr.findIndex(ele=> ele.name.common === "Canada")
        let ca_element = arr[ca_index];
        arr.splice(ca_index, 1);

        arr.splice(0, 0, ca_element);
        arr.splice(1, 0, us_element);
        return arr;
    }
    let countries_final = props.arrange ? arraymove(countries) : countries
    return (
    <div className="space-y-1">
        <div className="flex flex-col space-y-1 w-full">
            <label htmlFor={props.name} className="text-xs Roboto-Medium">
                {props?.label}
            </label>

            <div
                className={`focus:border-bordergray focus:outline-none h-7 sm:h-8 md:h-10   text-gray w-full px-2 py-1 text-xs sm:text-sm 2xl:text-base border ${
                    props?.touched && props?.errors
                        ? 'border-reddark'
                        : 'border-bordergray'
                } `}>
                <select
                    id={props.name}
                    {...props}
                    className={`w-full h-full outline-none  text-gray border-none`}>
                        <option value={''}>
                            Select the value
                        </option>
                    {countries_final.map((item: any, index: any) => (
                        <option key={index} value={item?.cca2}>
                            {item?.name?.common}
                        </option>
                    ))}
                </select>
            </div>
        </div>
        {props?.touched && props?.errors && (
            <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                {props?.errors}
            </div>
        )}
    </div>
)
        }
