import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { imgs } from '../../../assets/images/img'
import { Footer } from '../../../components/footer/footer'
import { InputPassword } from '../../../components/inputPassword/inputPassword'
import { Input } from '../../../components/input/input'
import { ModalComponent } from '../../../components/Modal'
import { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import OtpInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import { clientLogin, sendOtp, confirmOtp, resetPassword, set2FA } from '../../../redux/actions/client.actions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/rootstore'

export const Login = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const forgotPass = searchParams.get("forgot_password")
    const dispatch = useDispatch<any>()
    const [modal, setmodal] = useState(forgotPass?true:false)
    const [tokenmodal, settokenmodal] = useState(false)
    const [isLoginFlow, setIsLoginFlow] = useState(false)
    const [PasswordUpdateModal, setPasswordUpdateModal] = useState(false)
    const [TwoFAModal, setTwoFAModal] = useState(false)

    const otpResult = useSelector((state: RootState) => state && state.ClientReducer && state.ClientReducer && state.ClientReducer.otp_result)

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Email address Required'),
            password: Yup.string()
                .min(6, 'Must be greater 6 digits.')
                .max(12, 'Must be less than 12 digits.')
                .required('Password Required'),
        }),
        onSubmit: async (values) => {
            let show2fa = await dispatch(clientLogin(values, navigate))
            if(show2fa){
                setIsLoginFlow(true)
                settokenmodal(true)
            }

        },
    })
    const passwordResetformik = useFormik({
        initialValues: {
            email: formik.values.email
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required('Email address Required'),
        }),
        onSubmit: async (values) => {
            const res = await dispatch(sendOtp(values))
            if (res) {
                setmodal(!modal)
                settokenmodal(!tokenmodal)
            }
        }
    })

    const tokenInputformik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required(),
            //.min(6, 'Must be 6 digit').max(6, 'Must be 6 digit')
        }),
        onSubmit: async (values) => {
            let { otp } = values
            let email = passwordResetformik.values.email || formik.values.email
            let res = await dispatch(confirmOtp({ email, otp },isLoginFlow))
            if (res) {
                settokenmodal(!tokenmodal)
                if(isLoginFlow){
                    setIsLoginFlow(false)
                    navigate('/user/home')
                }else {
                    setPasswordUpdateModal(!PasswordUpdateModal)
                }
            }
        },
    })

    const passwordInputformik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().matches(/^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/, "Your new password should have one uppercase, one lowercase, one number, and one symbol").required(),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match').required()
        }),
        onSubmit: async (values) => {
            let { password, confirmPassword } = values
            let { token, uid } = otpResult
            let result = await dispatch(resetPassword({ new_password1: password, new_password2: confirmPassword, token, uid }))
            if (result) {
                setPasswordUpdateModal(!PasswordUpdateModal)
                if(result.is2fa){
                    navigate('/user/home')
                }else {
                    setTwoFAModal(!TwoFAModal)
                }
            }
        }
    })

    const twoFactorformik = useFormik({
        initialValues: {
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
            await dispatch(set2FA({ "is2fa": true }, navigate))
        },
    })

    const PasswordResetModalContent = (
        <div className="bg-white outline-none max-h-96 space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setmodal(!modal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                onSubmit={passwordResetformik.handleSubmit}
                className="space-y-8 px-10 pt-10 pb-20">
                <div className="Roboto-Bold text-blue-base text-xl">
                    Password Reset
                </div>
                <div className="Roboto-Medium w-full text-blue-base text-sm">
                    Please enter your Email Address and click the Reset button.
                </div>
                <Input
                    placeholder="Enter Your Email"
                    name="email"
                    onChange={passwordResetformik.handleChange}
                    onBlur={passwordResetformik.handleBlur}
                    value={passwordResetformik.values.email}
                    errors={passwordResetformik?.errors?.email}
                    touched={passwordResetformik?.touched?.email}
                    width="full"
                />

                {passwordResetformik.values.email && <button
                    className="btn-brown w-full "
                    type='submit'
                >
                    RESET
                </button>}
            </form>
        </div>
    )

    const TokenInputModalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => settokenmodal(!tokenmodal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                onSubmit={tokenInputformik.handleSubmit}
                className="space-y-8 px-10 pt-5 pb-10">
                <div className="Roboto-Bold text-blue-base text-xl">
                    Token Input
                </div>
                <div className="Roboto-Medium w-full text-blue-base text-sm">
                    Please enter the token number.
                </div>
                <div className="space-y-3 w-full">
                    <OtpInput
                        value={tokenInputformik.values.otp}
                        onChange={(otp) => tokenInputformik.setFieldValue("otp", otp)}
                        numInputs={6}
                        separator={<span className="text-white"> -</span>}
                        className="h-10 w-full"
                        inputStyle={{
                            border: '1px solid #8B8B8B',
                            height: '100%',
                            width: '100%',
                        }}
                        focusStyle={false}
                    />
                    {tokenInputformik.errors.otp && (
                        <div className="text-reddark text-xs">
                            {tokenInputformik.errors.otp}
                        </div>
                    )}
                    <div
                        onClick={async () => {
                            await dispatch(sendOtp({ email: passwordResetformik.values.email || formik.values.email }))
                        }}
                        className="Roboto-Bold cursor-pointer w-full text-right text-blue-base text-sm">
                        Resend Token
                    </div>
                </div>
                <div className="space-y-3">
                    <button
                        className="btn-brown w-full "
                        type='submit'>
                        SUBMIT
                    </button>
                    <button
                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                        onClick={() => settokenmodal(!tokenmodal)}>
                        CANCEL
                    </button>
                </div>
            </form>
        </div>
    )

    const PasswordUpdateModalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2.5/12  flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setPasswordUpdateModal(!PasswordUpdateModal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                onSubmit={passwordInputformik.handleSubmit}
                className="space-y-8 px-10 pt-5 pb-10">
                <div className="Roboto-Bold text-blue-base text-xl">
                    Password Update
                </div>
                <div className="Roboto-Medium w-full text-blue-base text-sm">
                    Your new password should have one uppercase, one lowercase,
                    one number, and one symbol.
                </div>
                <div className="space-y-3">
                    <InputPassword
                        placeholder="Enter Your Password"
                        name="password"
                        onChange={passwordInputformik.handleChange}
                        value={passwordInputformik.values.password}
                        onBlur={passwordInputformik.handleBlur}
                        errors={passwordInputformik?.errors?.password}
                        touched={passwordInputformik?.touched?.password} />

                    <InputPassword
                        placeholder="Confirm Your Password"
                        name="confirmPassword"
                        onChange={passwordInputformik.handleChange}
                        value={passwordInputformik.values.confirmPassword}
                        onBlur={passwordInputformik.handleBlur}
                        errors={passwordInputformik?.errors?.confirmPassword}
                        touched={passwordInputformik?.touched?.confirmPassword} />
                </div>
                <div className="space-y-3">
                    <button className="btn-brown w-full " type='submit'>
                        SUBMIT
                    </button>
                    <button
                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                        onClick={() => setPasswordUpdateModal(!PasswordUpdateModal)}
                    >
                        CANCEL
                    </button>
                </div>
            </form>
        </div>
    )
    const TwoFAModalContent = (
        <div className="bg-white   outline-none  space-y-4 w-8/12 sm:w-1/2 md:w-4/12 lg:w-5/12 xl:w-3/12 2xl:w-2/12 flex flex-col items-center">
            <div className="flex justify-end w-full text-reddark p-2">
                <FaTimes
                    onClick={() => setTwoFAModal(!TwoFAModal)}
                    className="cursor-pointer"
                />
            </div>
            <form
                onSubmit={twoFactorformik.handleSubmit}
                className="space-y-12 px-10 pt-5 pb-10">
                <div className="Roboto-Bold text-blue-base text-xl xl:text-2xl text-center px-5">
                    Would you like to set up 2FA?
                </div>

                <div className="space-y-3">
                    <button className="btn-brown w-full " type='submit'>YES</button>
                    <button
                        className="w-full h-7 sm:h-8 md:h-10 hover:bg-brown hover:text-white bg-white text-brown text-xs sm:text-sm Roboto-Medium text-center duration-1000 "
                        onClick={() => navigate('/user/home')}>
                        SKIP FOR NOW
                    </button>
                </div>
            </form>
        </div>
    )

    return (
        <div
            className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
            style={{
                backgroundImage: `url(${imgs.background})`,
            }}>
            <div
                className="bg-cover w-full h-full flex flex-col items-center justify-between min-h-screen"
                style={{
                    backgroundImage: `url(${imgs.backgroundlayer})`,
                }}>
                <div className="w-full flex flex-col lg:flex-row overflow-hidden lg:h-16 items-center justify-between text-blue-base bg-white py-1 px-5 sm:px-8 border-b-2 border-blue-base">
                    <div className='flex lg:w-1/4 justify-between'>
                        <div className="flex flex-col items-center">
                            <img
                                src={imgs.logo}
                                alt=""
                                className="h-4 w-full sm:h-10 object-cover"
                            />
                            <div className="self-end Roboto-Medium text-xs sm:text-sm ">
                                XPRESS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center  py-10 text-left min-h-full md:space-x-10 xl:space-x-16">
                    <div className="text-white w-1/2 lg:w-5/12 space-y-6 px-12 hidden sm:block">
                        <div className="text-2xl md:text-4xl Roboto-Bold">
                            OdysseyXpress Corporate Event Gateway
                        </div>
                        <div className="text-base md:text-lg Roboto-Regular">
                            Odyssey's Corporate Event Gateway makes it simple,
                            fast and easy for you to monitor and track the
                            status of your corporate actions and other corporate
                            events and for your registered holders to submit
                            their instructions securely online.
                        </div>
                    </div>
                    <div className="bg-white px-10 py-10 max-h-96 space-y-4   flex flex-col items-center">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="space-y-8">
                            <div className="Roboto-Bold text-blue-base text-xl">
                                Login
                            </div>
                            <div className="space-y-3">
                                <Input
                                    placeholder="Enter Your Email"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    errors={formik?.errors?.email}
                                    touched={formik?.touched?.email}
                                    width="full"
                                />
                                <InputPassword
                                    placeholder="Enter Your Password"
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    errors={formik?.errors?.password}
                                    touched={formik?.touched?.password}
                                />
                            </div>

                            <button className="btn-brown w-full " type="submit">
                                Continue
                            </button>
                        </form>
                        <div
                            onClick={() => setmodal(!modal)}
                            className="Roboto-Bold cursor-pointer w-full text-right text-blue-base text-sm">
                            Forgot Password?
                        </div>
                    </div>
                </div>
                <ModalComponent
                    modalState={modal}
                    handleModal={() => setmodal(!modal)}
                    content={PasswordResetModalContent}
                />
                <ModalComponent
                    modalState={tokenmodal}
                    handleModal={() => settokenmodal(!tokenmodal)}
                    content={TokenInputModalContent}
                />
                <ModalComponent
                    modalState={PasswordUpdateModal}
                    handleModal={() =>
                        setPasswordUpdateModal(!PasswordUpdateModal)
                    }
                    content={PasswordUpdateModalContent}
                />
                <ModalComponent
                    modalState={TwoFAModal}
                    handleModal={() =>
                        setTwoFAModal(!TwoFAModal)
                    }
                    content={TwoFAModalContent}
                />
                <Footer />
            </div>
        </div>
    )
}
