import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {InputWithLabel} from '../../../../components/InputWithLabel/InputWithLabel'
import {SelectStateWithLabel} from '../../../../components/selectWithLabel/selectStateWithLabel'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import { prepareConsolidationExchangeDetail } from '../../../../redux/actions/auth.actions'
import { RootState } from '../../../../redux/rootstore'
import React from 'react'
import { SelectCountryWithLabel } from '../../../../components/selectWithLabel/selectCountryWithLabel'
import { getCountryNameByCode } from '../../../../utils/constants/countries'

export const PaymentInstruction = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const { event, user, consolidation_exchange_detail, consolidation_exchange } = useSelector((state: RootState) => state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            withdrawal_payment_method: consolidation_exchange?.payment_instructions?.withdrawal_payment_method || '',
            beneficiary_name: consolidation_exchange?.issue_in_anothername?.full_name || `${user?.securityholder_detail?.holder_reg1 || ''} ${user?.securityholder_detail?.holder_reg2 || ''} ${user?.securityholder_detail?.holder_reg3 || ''}` || '',
            beneficiary_province_or_state: consolidation_exchange?.issue_in_anothername?.state || user?.securityholder_detail?.state || '',
            beneficiary_country: consolidation_exchange?.issue_in_anothername?.country || user?.securityholder_detail?.country || '',
            beneficiary_street_address: consolidation_exchange?.issue_in_anothername?.street_address || `${user?.securityholder_detail?.holder_address1 || ''} ${user?.securityholder_detail?.holder_address2 || ''} ${user?.securityholder_detail?.holder_address3 || ''}` || '',
            beneficiary_city: consolidation_exchange?.issue_in_anothername?.city || user?.securityholder_detail?.city || '',
            beneficiary_postal_zip_code: consolidation_exchange?.issue_in_anothername?.zip_or_postal_code || user?.securityholder_detail?.zipcode || '',
            beneficiary_bank_name: consolidation_exchange?.payment_instructions?.bank?.beneficiary_bank_name,
            province_or_state: consolidation_exchange?.payment_instructions?.bank?.province_or_state,
            country: consolidation_exchange?.payment_instructions?.bank?.country,
            address: consolidation_exchange?.payment_instructions?.bank?.address,
            city: consolidation_exchange?.payment_instructions?.bank?.city,
            postal_zip_code: consolidation_exchange?.payment_instructions?.bank?.postal_zip_code,
            
            transit_number: consolidation_exchange?.payment_instructions?.wire?.transit_number || consolidation_exchange?.payment_instructions?.directdeposit?.transit_number,
            institution_number: consolidation_exchange?.payment_instructions?.wire?.institution_number,
            // ACHAccountNumber: consolidation_exchange.ACHAccountNumber,
            account_type: consolidation_exchange?.payment_instructions?.wire?.account_type,
            account_number: consolidation_exchange?.payment_instructions?.wire?.account_number || consolidation_exchange?.payment_instructions?.directdeposit?.account_number || consolidation_exchange?.payment_instructions?.ach?.account_number,
            swift_code: consolidation_exchange?.payment_instructions?.wire?.swift_code,
            aba: consolidation_exchange?.payment_instructions?.wire?.aba || consolidation_exchange?.payment_instructions?.ach?.aba,
            iban_number: consolidation_exchange?.payment_instructions?.wire?.iban_number,
            sort_code: consolidation_exchange?.payment_instructions?.wire?.sort_code,
            bsb_number: consolidation_exchange?.payment_instructions?.wire?.bsb_number,
            bic_number: consolidation_exchange?.payment_instructions?.wire?.bic_number,
            additional_notes: consolidation_exchange?.payment_instructions?.wire?.additional_notes,
            paymentFeeTerms: false
        },
        validationSchema: Yup.object({
            beneficiary_name: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            beneficiary_province_or_state: Yup.string().when('beneficiary_country', {
                is: (beneficiary_country)=> (beneficiary_country === 'US' || beneficiary_country === 'CA'),
                then: Yup.string().required('State Required')}),
            beneficiary_street_address: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            beneficiary_country: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            beneficiary_city: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            beneficiary_postal_zip_code: Yup.string().when(['withdrawal_payment_method','country'], {
                is: (withdrawal_payment_method, country)=> withdrawal_payment_method !== 'cheque' && (country === 'US' || country === 'CA'),
                then: Yup.string().required()}),
            beneficiary_bank_name: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            province_or_state: Yup.string().when('country', {
                is: (country)=> (country === 'US' || country === 'CA'),
                then: Yup.string().required('State Required')}),
            country: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method === 'wire',
                then: Yup.string().required()}),
            address: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            city: Yup.string().when('withdrawal_payment_method', {
                is: (withdrawal_payment_method)=> withdrawal_payment_method !== 'cheque',
                then: Yup.string().required()}),
            postal_zip_code: Yup.string().when(['withdrawal_payment_method','country'], {
                is: (withdrawal_payment_method, country)=> withdrawal_payment_method !== 'cheque' && (country === 'US' || country === 'CA'),
                then: Yup.string().required()}),

            account_number: Yup.string().when("withdrawal_payment_method",{
                is: 'wire',
                then: Yup.string().required('Bank Account Number Required')
            }).when("withdrawal_payment_method",{
                is: 'direct_deposit',
                then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                .min(7, 'Atleast 7 digit').max(12, 'Atmost 12 digit'),
            }).when("withdrawal_payment_method",{
                is: 'ach',
                then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                .min(6, 'Atleast 6 digit').max(17, 'Atmost 17 digit'),
            }),
            transit_number: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            .min(5, 'Must be 5 digit').max(5, 'Must be 5 digit'),
            // transit_number: Yup.string().when("withdrawal_payment_method",{
            //     is: 'wire',
            //     then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            // .min(5, 'Must be 5 digit').max(9, 'Must be 9 digit')})
            // .when("withdrawal_payment_method",{
            //     is: 'direct_deposit',
            //     then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            // .min(5, 'Must be 5 digit').max(5, 'Must be 5 digit')}),
            swift_code: Yup.string()
            .min(8, 'Min 8 character').max(11, 'Max 11 character'),
            aba: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            .min(9, 'Must be 9 digit').max(9, 'Must be 9 digit'),
            iban_number: Yup.string()
            .min(0, 'Min 0 character').max(34, 'Max 34 character'),
            sort_code: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be 6 digit').max(6, 'Must be 6 digit'),
            bsb_number: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be 6 digit').max(6, 'Must be 6 digit'),
            bic_number: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be 6 digit').max(6, 'Must be 6 digit'),

            // transit_number: Yup.string().when("withdrawal_payment_method",{
            //     is: 'direct_deposit',
            //     then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
            //     .min(5, 'Must be 5 digit').max(5, 'Must be 5 digit'),
            // }),
            institution_number: Yup.string().when("withdrawal_payment_method",{
                is: 'direct_deposit',
                then: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                .min(3, 'Must be 3 digit').max(3, 'Must be 3 digit'),
            }),
            withdrawal_payment_method: Yup.string().required(),
            paymentFeeTerms: Yup.boolean().when("withdrawal_payment_method",{
                is: (m)=> m !== 'cheque',
                then: Yup.boolean().required('Required')
                .oneOf([true], 'You must accept the terms of payment fee.'),})
        }),
        onSubmit: async (values) => {
            let {withdrawal_payment_method} = values;
            let payload:any = {
                withdrawal_payment_method,
                bank: {
                    beneficiary_name: values.beneficiary_name,
                    beneficiary_province_or_state: values.beneficiary_province_or_state,
                    beneficiary_country: values.beneficiary_country,
                    beneficiary_street_address: values.beneficiary_street_address,
                    beneficiary_city: values.beneficiary_city,
                    beneficiary_postal_zip_code: values.beneficiary_postal_zip_code,
                    beneficiary_bank_name: values.beneficiary_bank_name,
                    province_or_state: values.province_or_state,
                    country: values.country,
                    address: values.address,
                    city: values.city,
                    postal_zip_code: values.postal_zip_code
                }
            }
            if(withdrawal_payment_method !== "cheque"){
                if(withdrawal_payment_method === "wire"){
                    payload.wire = {
                        account_number: values.account_number, 
                        transit_number: values.transit_number,
                        swift_code: values.swift_code, 
                        aba: values.aba, 
                        iban_number: values.iban_number, 
                        sort_code: values.sort_code,
                        bsb_number: values.bsb_number,
                        bic_number: values.bic_number,
                        additional_notes: values.additional_notes
                    }
                } else if(withdrawal_payment_method === "direct_deposit"){
                    payload.directdeposit = {
                        account_number: values.account_number, 
                        transit_number: values.transit_number, 
                        institution_number: values.institution_number
                    }
                } else {
                    //wire.account_number || directdeposit.account_number || ach.account_number
                    //wire.aba || ach.aba
                    //wire.transit_number || directdeposit.transit_number
                    payload.ach = {
                        account_number: values.account_number, 
                        aba: values.aba,
                        account_type: values.account_type 
                    }
                }
            }
            await dispatch(prepareConsolidationExchangeDetail({payment_instructions: payload}))
            if(consolidation_exchange_detail.ask_residency_holder_status_question){
                navigate('/residency-instructions')
            } else {
                navigate('/review-instruction')
            }
        },
    })
    return (
        <div className="w-full h-full flex flex-col items-center min-h-screen">
            <Nav
                enableProps={true}
            />
            <Header
               heading={event.event_name}
                description="Deposit Instructions"
            />
            <div className="page-padding space-y-8 py-8 w-full">
                <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                    className="md:px-14 mx-auto flex flex-col pb-4 space-y-10 accent-reddark">
                    <div className="space-y-8">
                        <div className="flex justify-between space-y-2 md:space-y-0 flex-col md:flex-row">
                            <div className="Roboto-Bold text-base md:text-xl lg:text-2xl text-blue-base ">
                                Choose the Payment Method
                            </div>
                            <div className="flex flex-col  space-y-4">
                               {consolidation_exchange_detail.by_cheque && <div className="flex"><Checkbox
                                    text="Check"
                                    name="withdrawal_payment_method"
                                    id="Cheque"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'cheque'}
                                    stateVal={formik.values.withdrawal_payment_method}
                                /><span className='fee'>&nbsp;{`(no fee)`}</span></div>}
                                {consolidation_exchange_detail.by_wire && <div className="flex"><Checkbox
                                    text="Wire"
                                    name="withdrawal_payment_method"
                                    id="Wire"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'wire'}
                                    stateVal={formik.values.withdrawal_payment_method}
                                /><span className='fee'>&nbsp;{`($${consolidation_exchange_detail.by_wire_fee||0} fee)`}</span></div>}
                                {consolidation_exchange_detail.direct_deposit && <div className="flex"><Checkbox
                                    text="Direct Deposit (Canadian Bank)"
                                    name="withdrawal_payment_method"
                                    id="DirectDeposit"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'direct_deposit'}
                                    stateVal={formik.values.withdrawal_payment_method}
                                /><span className='fee'>&nbsp;{`($${consolidation_exchange_detail.direct_deposit_fee||0} fee)`}</span></div>}
                                {consolidation_exchange_detail.ach && <div className="flex"><Checkbox
                                    text="Direct Deposit (U.S. banks only)"
                                    name="withdrawal_payment_method"
                                    id="ACH"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={'ach'}
                                    stateVal={formik.values.withdrawal_payment_method}
                                /><span className='fee'>&nbsp;{`($${consolidation_exchange_detail.ach_fee||0} fee)`}</span></div>}
                            </div>
                        </div>
                        {formik.errors.withdrawal_payment_method && (
                            <div className="text-reddark text-xs">
                                {formik.errors.withdrawal_payment_method}
                            </div>
                        )}
                        {formik.values.withdrawal_payment_method && formik.values.withdrawal_payment_method !== "cheque" ? <div className="flex flex-col">
                            <div className="text-left  flex items-center text-xs sm:text-sm space-x-2">
                                <input
                                    type="checkbox"
                                    id="checked"
                                    className="accent-[#0E294B] h-4 w-4 p-1 cursor-pointer outline-none ring-0 focus:ring-0"
                                    name="paymentFeeTerms"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={formik.values.paymentFeeTerms}
                                />
                                <label
                                    htmlFor="checked"
                                    className="text-blue-base Roboto-Medium mt-1 cursor-pointer">
                                    I acknowledge and agree to the payment method selected and the fee to be deducted from the final proceeds (if applicable)
                                </label>
                            </div>
                            {formik.touched.paymentFeeTerms &&
                                formik?.errors.paymentFeeTerms && (
                                    <div className="Gilroy-Light text-xs text-reddark pl-5">
                                        {formik.errors.paymentFeeTerms}
                                    </div>
                                )}
                        </div>:""}
                    </div>
                    <hr className="border border-gray2" />
                    {formik.values.withdrawal_payment_method && formik.values.withdrawal_payment_method  !== 'cheque' &&
                    <React.Fragment>
                    <div className="mt-10 space-y-8">
                        <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                            <div className="Roboto-Bold text-base md:text-xl lg:text-2xl text-blue-base ">
                                Beneficiary Details
                            </div>
                            <div className="Roboto-Bold text-base md:text-xl lg:text-2xl text-blue-base ">
                                Beneficiary Bank Details
                            </div>
                        </div>
                        <div className="grid md:grid-cols-2 gap-4 md:gap-16">
                            <div className="space-y-3">
                                    <div className="space-y-3">
                                        <InputWithLabel
                                            label="**Beneficiary Name(s)"
                                            placeholder="Please enter beneficiary name"
                                            name="beneficiary_name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                                formik.values.beneficiary_name
                                            }
                                            errors={
                                                formik?.errors?.beneficiary_name
                                            }
                                            touched={
                                                formik?.touched?.beneficiary_name
                                            }
                                            width="full"
                                            readOnly={true}
                                            // readOnly={formik.values.withdrawal_payment_method === 'wire' ? false : true}
                                        />
                                        {/* {formik.values.withdrawal_payment_method === 'wire' ? <SelectCountryWithLabel
                                    label="**Beneficiary Country"
                                    name="beneficiary_country"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.beneficiary_country}
                                    errors={formik?.errors?.beneficiary_country}
                                    touched={formik?.touched?.beneficiary_country}
                                    width="full"
                                /> : */}
                                <InputWithLabel
                                            label="**Beneficiary Country"
                                            placeholder=""
                                            name="beneficiary_country"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={getCountryNameByCode(formik.values.beneficiary_country)}
                                            errors={formik?.errors?.beneficiary_country}
                                            touched={formik?.touched?.beneficiary_country}
                                            width="full"
                                            readOnly={true}
                                        />
                                        {/* } */}
                                            
                                             {/* {formik.values.withdrawal_payment_method === 'wire' ? 
                                            (['US','CA'].includes(formik.values.beneficiary_country) && <SelectStateWithLabel
                                            label="**Beneficiary Province/State"
                                            name="beneficiary_province_or_state"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.beneficiary_province_or_state}
                                            errors={formik?.errors?.beneficiary_province_or_state}
                                            touched={formik?.touched?.beneficiary_province_or_state}
                                            width="full"
                                            countryCode={formik.values.beneficiary_country}
                                        />) : */}
                                        <InputWithLabel
                                            label="**Beneficiary Province/State"
                                            name="beneficiary_province_or_state"
                                            placeholder=""
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.beneficiary_province_or_state}
                                            errors={formik?.errors?.beneficiary_province_or_state}
                                            touched={formik?.touched?.beneficiary_province_or_state}
                                            width="full"
                                            readOnly={true}
                                        />
                                        {/* } */}
                                        <InputWithLabel
                                            label="**Beneficiary Street Address"
                                            placeholder="Please enter street"
                                            name="beneficiary_street_address"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.beneficiary_street_address}
                                            errors={
                                                formik?.errors?.beneficiary_street_address
                                            }
                                            touched={
                                                formik?.touched?.beneficiary_street_address
                                            }
                                            width="full"
                                            readOnly={true}
                                            // readOnly={formik.values.withdrawal_payment_method === 'wire' ? false : true}
                                        />

                                        <InputWithLabel
                                            label="**Beneficiary City"
                                            placeholder="Please enter city"
                                            name="beneficiary_city"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.beneficiary_city}
                                            errors={formik?.errors?.beneficiary_city}
                                            touched={formik?.touched?.beneficiary_city}
                                            width="full"
                                            readOnly={true}
                                            // readOnly={formik.values.withdrawal_payment_method === 'wire' ? false : true}
                                        />
                                        <InputWithLabel
                                            label="**Beneficiary Zip/Postal Code"
                                            placeholder="Please enter Zip code"
                                            name="beneficiary_postal_zip_code"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.beneficiary_postal_zip_code}
                                            errors={formik?.errors?.beneficiary_postal_zip_code}
                                            touched={formik?.touched?.beneficiary_postal_zip_code}
                                            width="full"
                                            readOnly={true}
                                            // readOnly={formik.values.withdrawal_payment_method === 'wire' ? false : true}
                                        />
                                    </div>
                            </div>
                            <div className="space-y-3">
                                <InputWithLabel
                                    label="**Beneficiary Bank/Financial Institution"
                                    placeholder="Please enter Bank"
                                    name="beneficiary_bank_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.beneficiary_bank_name}
                                    errors={formik?.errors?.beneficiary_bank_name}
                                    touched={formik?.touched?.beneficiary_bank_name}
                                    width="full"
                                />
                                {formik.values.withdrawal_payment_method && formik.values.withdrawal_payment_method  === 'wire' && <SelectCountryWithLabel
                                    label="**Country"
                                    name="country"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.country}
                                    errors={formik?.errors?.country}
                                    touched={formik?.touched?.country}
                                    width="full"
                                />}
                                {['US','CA'].includes(formik.values.country) && <SelectStateWithLabel
                                    label="**Province/State"
                                    name="province_or_state"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.province_or_state}
                                    errors={formik?.errors?.province_or_state}
                                    touched={formik?.touched?.province_or_state}
                                    width="full"
                                    countryCode={formik.values.country}
                                />}
                                <InputWithLabel
                                    label="**Street Address"
                                    placeholder="Please enter street"
                                    name="address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    errors={formik?.errors?.address}
                                    touched={formik?.touched?.address}
                                    width="full"
                                />

                                <InputWithLabel
                                    label="**City"
                                    placeholder="Please enter city"
                                    name="city"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    errors={formik?.errors?.city}
                                    touched={formik?.touched?.city}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="**Zip/Postal Code"
                                    placeholder="Please enter Zip code"
                                    name="postal_zip_code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.postal_zip_code}
                                    errors={formik?.errors?.postal_zip_code}
                                    touched={formik?.touched?.postal_zip_code}
                                    width="full"
                                />
                            </div>
                        </div>
                    </div>
                    <hr className="border border-gray2" />
                    {formik.values.withdrawal_payment_method === 'wire' ? (
                        <div className="space-y-6">
                            <div className="text-xs md:text-sm Roboto-Medium">
                                PLEASE ONLY COMPLETE THE APPLICABLE BOXES BELOW,
                                AS PROVIDED BY YOUR FINANCIAL INSTITUTION. YOU
                                ARE NOT REQUIRED TO COMPLETE ALL BOXES
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                <InputWithLabel
                                    label="**Bank Account Number"
                                    placeholder="Please enter account number"
                                    name="account_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.account_number}
                                    errors={formik?.errors?.account_number}
                                    touched={formik?.touched?.account_number}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="Transit Number"
                                    placeholder="(5 digits)"
                                    name="transit_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.transit_number}
                                    errors={formik?.errors?.transit_number}
                                    touched={formik?.touched?.transit_number}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="Swift Code"
                                    placeholder="(8-11 Characters)"
                                    name="swift_code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.swift_code}
                                    errors={formik?.errors?.swift_code}
                                    touched={formik?.touched?.swift_code}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="ABA (US)"
                                    placeholder="(9 Digits)"
                                    name="aba"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.aba}
                                    errors={formik?.errors?.aba}
                                    touched={formik?.touched?.aba}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="IBAN Number (Europe)"
                                    placeholder="(Up to 34 Characters)"
                                    name="iban_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.iban_number}
                                    errors={formik?.errors?.iban_number}
                                    touched={formik?.touched?.iban_number}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="Sort Code (GBP)"
                                    placeholder="(6 digits)"
                                    name="sort_code"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.sort_code}
                                    errors={formik?.errors?.sort_code}
                                    touched={formik?.touched?.sort_code}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="BSB Number"
                                    placeholder="(6 Digits)"
                                    name="bsb_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.bsb_number}
                                    errors={formik?.errors?.bsb_number}
                                    touched={formik?.touched?.bsb_number}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="BIC Number"
                                    placeholder="(6 Digits)"
                                    name="bic_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.bic_number}
                                    errors={formik?.errors?.bic_number}
                                    touched={formik?.touched?.bic_number}
                                    width="full"
                                />
                            </div>
                            <InputWithLabel
                                label="Additional Notes and special routing Instructions:"
                                placeholder=""
                                name="additional_notes"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.additional_notes}
                                errors={formik?.errors?.additional_notes}
                                touched={formik?.touched?.additional_notes}
                                width="full"
                            />
                            <div className="text-xs md:text-sm Roboto-Medium">
                                ** Mandatory fields
                            </div>
                        </div>
                    ) : formik.values.withdrawal_payment_method ===
                      'direct_deposit' ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                            <InputWithLabel
                                label="Bank Account Number"
                                placeholder="(7 to 12 digits - no spaces)"
                                name="account_number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.account_number}
                                errors={formik?.errors?.account_number}
                                touched={formik?.touched?.account_number}
                                width="full"
                            />
                            <InputWithLabel
                                label="Transit Number"
                                placeholder="(5 digits)"
                                name="transit_number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.transit_number}
                                errors={formik?.errors?.transit_number}
                                touched={formik?.touched?.transit_number}
                                width="full"
                            />
                            <InputWithLabel
                                label="Institution Number"
                                placeholder="(3 digits)"
                                name="institution_number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.institution_number}
                                errors={formik?.errors?.institution_number}
                                touched={formik?.touched?.institution_number}
                                width="full"
                            />
                        </div>
                    ) : formik.values.withdrawal_payment_method ===
                    'ach' ? (
                        <div className="space-y-6">
                            <div className="flex items-center justify-between space-x-10 sm:w-1/2">
                                <div className="text-sm Roboto-Medium">
                                    Account Type
                                </div>
                                <div className="flex justify-between space-x-10 sm:w-1/2">
                                    <Checkbox
                                        text="Chequing"
                                        name="account_type"
                                        id="AccountType1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'Chequing'}
                                        stateVal={formik.values.account_type}
                                    />
                                    <Checkbox
                                        text="Savings"
                                        name="account_type"
                                        id="AccountType1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'Savings'}
                                        stateVal={formik.values.account_type}
                                    />
                                </div>
                            </div>
                            <div className="grid md:grid-col-3 lg:grid-cols-4 gap-6">
                                <InputWithLabel
                                    label="ABA"
                                    placeholder="(9 digits)"
                                    name="aba"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.aba}
                                    errors={formik?.errors?.aba}
                                    touched={formik?.touched?.aba}
                                    width="full"
                                />
                                <InputWithLabel
                                    label="Account Number"
                                    placeholder="(6 to 17 digits – no spaces)"
                                    name="account_number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.account_number}
                                    errors={formik?.errors?.account_number}
                                    touched={formik?.touched?.account_number}
                                    width="full"
                                />
                            </div>
                        </div>
                    ):''}
                    </React.Fragment>}
                    <div className="flex items-center justify-end">
                        <button className="btn-brown w-44" type="submit">
                            CONTINUE
                        </button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}
