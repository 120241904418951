import {toast} from 'react-toastify'
import {TOAST_TYPE} from '../constants'
import 'react-toastify/dist/ReactToastify.css'
import {uploadFile} from 'react-s3'
import { Buffer } from "buffer";
import { saveAs } from 'file-saver';

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
const S3_BUCKET = 'corporate-actions-p-32128'
const REGION = 'us-east-2'
const ACCESS_KEY = 'AKIA4KGTUZ6KKIWZLSOG'
const SECRET_ACCESS_KEY = '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'

const configs3 = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}

const config = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
}

export const handleUpload = async file => {
    return uploadFile(file, configs3)
        .then(data =>{
            console.log('file uploaded at', data)
            return {
                status: true,
                data
            }
        })
        .catch(err => {
            console.log('upload err',err)
            return {
                status: false,
                err
            }
        })
}
export const handleDownload = (dataurl, filename) =>{
    saveAs(dataurl, filename)
  }
export const getLocalStorage = key => {
    return sessionStorage.getItem(key)
}

export const setLocalStorage = (key, value) => {
    sessionStorage.setItem(key, value)
}

export const removeLocalStorage = key => {
    return sessionStorage.removeItem(key)
}

export const customDebounce = (func, delay = 300) => {
    return function () {
        setTimeout(() => {
            func()
        }, delay)
    }
}

export const showToast = (message, type = TOAST_TYPE.info) => {
    switch (type) {
        case TOAST_TYPE.info:
            return toast.info(message, config)

        case TOAST_TYPE.success:
            return toast.success(message, config)

        case TOAST_TYPE.warning:
            return toast.warning(message, config)

        case TOAST_TYPE.error:
            return toast.error(message, config)

        default:
            return toast.info(message, config)
    }
}

export const isEmailValid = email => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(String(email).toLocaleLowerCase())
}

export const errorMessage = message => {
    return {
        error: true,
        message: message,
    }
}

export const getFile = file => {
    const fileReg = /(?:\.([^.]+))?$/
    const res = {
        file,
        ext: fileReg.exec(`${file}`)[1],
    }
    return res
}

export const debounce = (func, delay = 300) => {
    let timer
    return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func()
        }, delay)
    }
}
