import axios from 'axios'
import {TOKEN_KEY} from '../constants'
import {getLocalStorage, removeLocalStorage} from '../functions'

// import history from "./history";
// const BASE_URL = `https://corporate-actions-p-32128.botics.co` // old url
const BASE_URL = `https://odysseytrustportal.botics.io`
// const BASE_URL = `http://localhost:8000`
// const BASE_URL = `http://192.168.18.13:4000/api/v1`

const http = axios.create({
    baseURL: BASE_URL,
})

http.interceptors.request.use(
    async (config) => {
        //token

        const token = (await getLocalStorage(TOKEN_KEY)) || ''
        config.headers.Authorization = `Token ${token}`
        // config.headers.authorization =  token ? `Token ${token}` : '';
        return config
    },
    (err) => Promise.reject(err)
)

const ResponseInterceptor = (response) => {
    // const dispatch = useDispatch()
    // dispatch(setAppLoadingState(false));
    return response
}

http.interceptors.response.use(ResponseInterceptor, (err) => {
    const error = err.response.data

    if (err?.response?.status === 401) {
        //unthorized user
        removeLocalStorage(TOKEN_KEY)
        // RootNavigation.navigate('Sign-in');
    }

    if (error) {
        console.log(error)
    }

    return err.response
})

export default http
