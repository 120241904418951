// states array
export const STATES = {
      "US": [
          {
              "name": "Alabama",
              "state_code": "AL"
          },
          {
              "name": "Alaska",
              "state_code": "AK"
          },
          {
              "name": "American Samoa",
              "state_code": "AS"
          },
          {
              "name": "Arizona",
              "state_code": "AZ"
          },
          {
              "name": "Arkansas",
              "state_code": "AR"
          },
          {
              "name": "Baker Island",
              "state_code": "UM-81"
          },
          {
              "name": "California",
              "state_code": "CA"
          },
          {
              "name": "Colorado",
              "state_code": "CO"
          },
          {
              "name": "Connecticut",
              "state_code": "CT"
          },
          {
              "name": "Delaware",
              "state_code": "DE"
          },
          {
              "name": "District of Columbia",
              "state_code": "DC"
          },
          {
              "name": "Florida",
              "state_code": "FL"
          },
          {
              "name": "Georgia",
              "state_code": "GA"
          },
          {
              "name": "Guam",
              "state_code": "GU"
          },
          {
              "name": "Hawaii",
              "state_code": "HI"
          },
          {
              "name": "Howland Island",
              "state_code": "UM-84"
          },
          {
              "name": "Idaho",
              "state_code": "ID"
          },
          {
              "name": "Illinois",
              "state_code": "IL"
          },
          {
              "name": "Indiana",
              "state_code": "IN"
          },
          {
              "name": "Iowa",
              "state_code": "IA"
          },
          {
              "name": "Jarvis Island",
              "state_code": "UM-86"
          },
          {
              "name": "Johnston Atoll",
              "state_code": "UM-67"
          },
          {
              "name": "Kansas",
              "state_code": "KS"
          },
          {
              "name": "Kentucky",
              "state_code": "KY"
          },
          {
              "name": "Kingman Reef",
              "state_code": "UM-89"
          },
          {
              "name": "Louisiana",
              "state_code": "LA"
          },
          {
              "name": "Maine",
              "state_code": "ME"
          },
          {
              "name": "Maryland",
              "state_code": "MD"
          },
          {
              "name": "Massachusetts",
              "state_code": "MA"
          },
          {
              "name": "Michigan",
              "state_code": "MI"
          },
          {
              "name": "Midway Atoll",
              "state_code": "UM-71"
          },
          {
              "name": "Minnesota",
              "state_code": "MN"
          },
          {
              "name": "Mississippi",
              "state_code": "MS"
          },
          {
              "name": "Missouri",
              "state_code": "MO"
          },
          {
              "name": "Montana",
              "state_code": "MT"
          },
          {
              "name": "Navassa Island",
              "state_code": "UM-76"
          },
          {
              "name": "Nebraska",
              "state_code": "NE"
          },
          {
              "name": "Nevada",
              "state_code": "NV"
          },
          {
              "name": "New Hampshire",
              "state_code": "NH"
          },
          {
              "name": "New Jersey",
              "state_code": "NJ"
          },
          {
              "name": "New Mexico",
              "state_code": "NM"
          },
          {
              "name": "New York",
              "state_code": "NY"
          },
          {
              "name": "North Carolina",
              "state_code": "NC"
          },
          {
              "name": "North Dakota",
              "state_code": "ND"
          },
          {
              "name": "Northern Mariana Islands",
              "state_code": "MP"
          },
          {
              "name": "Ohio",
              "state_code": "OH"
          },
          {
              "name": "Oklahoma",
              "state_code": "OK"
          },
          {
              "name": "Oregon",
              "state_code": "OR"
          },
          {
              "name": "Palmyra Atoll",
              "state_code": "UM-95"
          },
          {
              "name": "Pennsylvania",
              "state_code": "PA"
          },
          {
              "name": "Puerto Rico",
              "state_code": "PR"
          },
          {
              "name": "Rhode Island",
              "state_code": "RI"
          },
          {
              "name": "South Carolina",
              "state_code": "SC"
          },
          {
              "name": "South Dakota",
              "state_code": "SD"
          },
          {
              "name": "Tennessee",
              "state_code": "TN"
          },
          {
              "name": "Texas",
              "state_code": "TX"
          },
          {
              "name": "United States Minor Outlying Islands",
              "state_code": "UM"
          },
          {
              "name": "United States Virgin Islands",
              "state_code": "VI"
          },
          {
              "name": "Utah",
              "state_code": "UT"
          },
          {
              "name": "Vermont",
              "state_code": "VT"
          },
          {
              "name": "Virginia",
              "state_code": "VA"
          },
          {
              "name": "Wake Island",
              "state_code": "UM-79"
          },
          {
              "name": "Washington",
              "state_code": "WA"
          },
          {
              "name": "West Virginia",
              "state_code": "WV"
          },
          {
              "name": "Wisconsin",
              "state_code": "WI"
          },
          {
              "name": "Wyoming",
              "state_code": "WY"
          }
      ],
      "CA": [
        {
            "name": "Alberta",
            "state_code": "AB"
        },
        {
            "name": "British Columbia",
            "state_code": "BC"
        },
        {
            "name": "Manitoba",
            "state_code": "MB"
        },
        {
            "name": "New Brunswick",
            "state_code": "NB"
        },
        {
            "name": "Newfoundland and Labrador",
            "state_code": "NL"
        },
        {
            "name": "Northwest Territories",
            "state_code": "NT"
        },
        {
            "name": "Nova Scotia",
            "state_code": "NS"
        },
        {
            "name": "Nunavut",
            "state_code": "NU"
        },
        {
            "name": "Ontario",
            "state_code": "ON"
        },
        {
            "name": "Prince Edward Island",
            "state_code": "PE"
        },
        {
            "name": "Quebec",
            "state_code": "QC"
        },
        {
            "name": "Saskatchewan",
            "state_code": "SK"
        },
        {
            "name": "Yukon",
            "state_code": "YT"
        }
    ]
  }