import { useEffect, useRef, useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactMaterialTable } from '../../components/table/customtable'
import { onGenerateAndGetAccessCodes, onGetAccessCodes, onGetEventDetails, onGetEventDetailsOnly, onGetGenerateAndGetAccessCodes, onSendAccessCode, resetAdminState } from '../../redux/actions/admin.actions'
import moment from 'moment'
import { FaArrowLeft, FaUpload } from 'react-icons/fa'
import { handleUpload, showToast } from '../../utils/functions'
import CsvDownload from 'react-json-to-csv'
import { CircularProgress } from '@material-ui/core'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { TOAST_TYPE } from '../../utils/constants'
import { getCountryNameByCode } from '../../utils/constants/countries'
import { BasicCountries } from '../../utils/constants/BasicCountries'
export const AdminEventAccessCode = () => {
    const hiddenFileInput = useRef<any>(null);

    const { id } = useParams<any>()
    const navigate = useNavigate()
    const [file, setFile] = useState(null)
    const [left, isLeft] = useState(false)
    const [loading, isLoading] = useState(false)
    const [mainloading, isMainLoading] = useState(false)
    const [generated, isGenerated] = useState(false)
    const [codeLeft, setCodeLeft] = useState([])
    const [eventStatus, setEventStatus] = useState('')
    const dispatch = useDispatch<any>()
    const { codes_left, eventDetails } = useSelector((state: any) => state.AdminReducer, shallowEqual)

    const [queryParams, setQueryParams] = useState<
        {
            search: string
            status: string
        }[]
    >([])

    useEffect(() => {
        async function anyNameFunction() {
            if (id) {
                isMainLoading(true)
                const query = `?event_id=${id}`
                const res = await dispatch(onGetEventDetailsOnly(query))
                isMainLoading(false)
                setEventStatus(res?.payload?.status)
                if (res?.payload?.is_securityholders_added) {
                    handleSendAccess(true)
                    isMainLoading(true)
                }
            }
        }

        anyNameFunction()
        return () => dispatch(resetAdminState())
    }, [])

    const getCountryNameByCode = (name) => {
        const res = BasicCountries.filter(e => e.code === name)
        return res[0].country_name || ''
    }

    console.log('codes_left', codes_left);

    useEffect(() => {
        if (codes_left) {
            const newList = []
            codes_left.forEach(element => {
                newList.push({
                    full_name: element?.full_name,
                    holder_address1: element?.holder_address1,
                    holder_address2: element?.holder_address2,
                    holder_address3: element?.holder_address3,
                    city: element?.city,
                    state: element?.state,
                    zipcode: element?.zipcode,
                    country: getCountryNameByCode(element?.country),
                    suppressed: element?.suppress_mailings,
                    email: element?.email,
                    ofac: element?.ofac,
                    pin: element?.pin?.toString(),
                })
            });
            setCodeLeft(newList)
        }
    }, [codes_left])

    const handleChangeLocal = async (file: any) => {
        if (file) {
            setFile(file.target.files[0])
            showToast('Document has been uploaded!', TOAST_TYPE.success)
        }
    }
    const handleGenerate = async () => {
        isLoading(true)
        const payload = new FormData()
        if (file) {
            payload.append('securityholder_document', file)
            await dispatch(onGenerateAndGetAccessCodes(id, payload, navigate))
            isGenerated(true)
        } else {
            alert('Please upload file first ')
        }
        isLoading(false)
    }
    const handleSendAccess = async (noAlert) => {
        const payload = {
            event_id: id
        }
        const res = await dispatch(onSendAccessCode(payload, noAlert, navigate))
        if (res?.type === "SEND_ACCESS_CODES") {
            isLeft(true)
        }
        isMainLoading(false)

    }

    const handleClick = () => {
        hiddenFileInput.current.click();
    };


    var columns = [
        {
            field: 'full_name',
            title: 'HOLDER NAME',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium text-blue-base Roboto-Medium">
                        {rowData?.full_name}
                    </div>
                </div>
            ),
        },
        {
            field: 'mail_address',
            title: 'MAIL ADDRESS (Street Address, City, Prov/State, Postal/ZIP code, Country)',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.mail_address}</div>
                </div>
            ),
        },
        {
            field: 'email',
            title: 'EMAIL ADDRESS',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.email}
                    </div>
                </div>
            ),
        },
        // {
        //     field: 'telephone',
        //     title: 'TELEPHONE',
        //     type: 'string' as const,
        //     render: (rowData: any) => (
        //         <div className="cursor-pointer mx-auto flex items-center space-x-4">
        //             <div className="text-brown Roboto-Bold">{rowData?.telephone}</div>
        //         </div>
        //     ),
        // },
        {
            field: 'pin',
            title: 'ACCESS CODE',
            type: 'string' as const,
            render: (rowData: any) => (
                <div className="cursor-pointer mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {rowData?.pin}
                    </div>
                </div>
            ),
        },
    ]

    const print = () => {
        const pdf = new jsPDF("p", "pt", "a4");
        const columns = [
            { full_name: "HOLDER NAME", mail: 'MAIL ADDRESS', email: 'EMAIL ADDRESS', access_code: 'ACCES CODE' },
        ];
        var rows = ['full_name', 'mail_address', 'email', 'pin'];
        // pdf.text( "Tabla de Prestamo");
        pdf.table(20, 40, codes_left, rows, {});
        console.log(pdf.output("datauristring"));
        pdf.save("pdf");
    };

    if (mainloading) {
        return (
            <div className="w-full flex justify-center mt-2">
                <CircularProgress style={{ color: "#000", width: 20, height: 20 }} />
            </div>
        )
    }

    return (
        <div className="page-padding py-10">
            <div onClick={() => navigate(-1)} className="text-blue-base cursor-pointer text-sm self-start flex items-center space-x-2 Roboto-Bold">
                <FaArrowLeft /> <span>BACK</span>
            </div>
            <div className="space-y-12  border-gray2 p-8">
                <div className="grid md:grid-cols-1 md:gap-20  w-full space-y-4  text-sm 2xl:text-base Roboto-Regular">
                    <div className="flex flex-col space-y-10 justify-between">
                        <div className="space-y-10">
                            {left ?
                                <div className="Roboto-Medium text-center md:text-1xl text-blue-base">
                                    Access codes successfully sent to holders with email addresses.
                                </div>
                                :
                                <div className="Roboto-Medium text-center md:text-2xl text-blue-base">
                                    {generated ? 'Access codes have been generated and are ready to send.' : file && !generated ? "Upload of securityholder information was successful. Please generate access codes." : "Please upload a document with security holders information."}
                                </div>
                            }
                            {
                                left &&
                                <div className="Roboto-Medium text-center md:text-1xl text-blue-base">
                                    Those holders without email addresses must have their access code and form sent by mail.
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    !left && !generated &&
                    <div className="w-full flex justify-between">
                        <div style={{ width: '10%' }} />
                        <button onClick={handleClick} disabled={eventStatus !== 'active'} style={{ opacity: eventStatus !== 'active' ? 0.5 : 1 }} className="border-2 border-blue-base flex items-center text-xs px-16 space-x-2 h-10 text-blue-base bg-white text-center">
                            <FaUpload />
                            <input ref={hiddenFileInput} style={{ display: 'none' }} type="file" accept='application/csv' onChange={(e) => handleChangeLocal(e)} />
                            <span>UPLOAD</span>
                        </button>
                        <button style={{ opacity: file ? 1 : 0.5 }} className="btn-brown uppercase px-8" disabled={!file} onClick={handleGenerate}>
                            {'GENERATE ACCESS CODES'}
                        </button>
                    </div>
                }
            </div>
            {
                loading ?
                    <div className="w-full flex justify-center">
                        <CircularProgress style={{ color: "#000", width: 20, height: 20 }} />
                    </div>
                    :

                    <div className="py-10">
                        {
                            left ?
                                <ReactMaterialTable
                                    columns={columns}
                                    data={codes_left}
                                    searchbar={false}
                                    toolbar={false}
                                    exportbtn={false}
                                    fontSize="16px"
                                    pagging={false}
                                    id="table"
                                />
                                :
                                <>
                                    {
                                        generated ?
                                            <ReactMaterialTable
                                                columns={columns}
                                                data={(query) => (
                                                    new Promise(async (resolve, reject) => {
                                                        const page = query.page ? query.page + 1 : 1
                                                        const params = { ...queryParams, page }
                                                        const result = await dispatch(onGetGenerateAndGetAccessCodes(id, params, navigate))
                                                        return resolve(result)
                                                    }))
                                                }
                                                searchbar={false}
                                                toolbar={false}
                                                exportbtn={false}
                                                fontSize="16px"
                                                pagging={true}
                                                id="table"
                                            />
                                            :
                                            <ReactMaterialTable
                                                columns={columns}
                                                data={[]}
                                                searchbar={false}
                                                toolbar={false}
                                                exportbtn={false}
                                                fontSize="16px"
                                                pagging={true}
                                                id="table"
                                            />
                                    }
                                </>
                        }
                    </div>
            }
            {
                !left ?
                    <div className="w-full flex justify-end">
                        <button className="btn-brown uppercase px-8" onClick={handleSendAccess}>
                            {'SEND ACCESS'}
                        </button>
                    </div>
                    :
                    <div className="w-full flex justify-end">
                        {/* <button className="btn-brown uppercase mr-4 px-8" onClick={print}>
                            {'Generate PDFs for mailing'}
                        </button> */}
                        <CsvDownload data={codeLeft} className="btn-brown uppercase px-8">
                            {'Download list in .csv'}
                        </CsvDownload>
                    </div>
            }
        </div>
    )
}
