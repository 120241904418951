import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor } from './redux/store'
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <App />
                <ToastContainer />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)
