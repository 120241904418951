import { ADMIN_AUTH_ACTION_TYPES, AUTH_ACTION_TYPES } from '../types'

const INITIAL_STATE = {
    failed: false,
    success: false,
    logout: false,
    status_bar: '1',
    event_conditions: {},
    user: {},
    event: {},
    consolidation_exchange_detail: {},
    consolidation_exchange: {},
    warrant_detail: {},
    warrant_request: {},
    econsentAddedd: false
}

export const VerificationReducer = (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case ADMIN_AUTH_ACTION_TYPES.LOGIN:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
            }
        case ADMIN_AUTH_ACTION_TYPES.SEND_OTP:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
            }
            case ADMIN_AUTH_ACTION_TYPES.SET_2FA:
                return {
                    ...state,
                    user: {...state.user, is2fa: actions.payload}
                }
        case ADMIN_AUTH_ACTION_TYPES.CONFIRM_OTP:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
                user: actions.payload,
            }
        case ADMIN_AUTH_ACTION_TYPES.RESET_PASSWORD:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
            }
        case ADMIN_AUTH_ACTION_TYPES.RESET_PASSWORD_FAILED:
            return {
                ...state,
                success: false,
                failed: true,
                logout: false,
            }
        case AUTH_ACTION_TYPES.LOGIN:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
                consolidation_exchange_detail: {},
                consolidation_exchange: {},
                warrant_detail: {},
                warrant_request: {},
                user: actions.payload,
            }
        case AUTH_ACTION_TYPES.HOME:
            return {
                ...state,
                consolidation_exchange_detail: {},
                consolidation_exchange: {},
                warrant_detail: {},
                warrant_request: {},
                user: {},
                event: actions.payload,
            }
        case AUTH_ACTION_TYPES.EVENT_TERM_AND_CONDITIONS:
            return {
                ...state,
                event_conditions: actions.payload,
            }
        case AUTH_ACTION_TYPES.UPDATE_PORTAL_TERM_AND_CONDITIONS:
            return {
                ...state,
                user: {
                    ...state.user,
                    securityholder_detail: {
                        ...state.user.securityholder_detail,
                        mark_read_term_and_conditions_of_portal:
                            actions.payload,
                    },
                },
            }
        case AUTH_ACTION_TYPES.STATUS:
            return {
                ...state,
                status_bar: actions.payload,
            }
            case AUTH_ACTION_TYPES.ECONSENT_ADDED:
                return {
                    ...state,
                    econsentAddedd: true,
                }
        case AUTH_ACTION_TYPES.REGISTER:
            return {
                ...state,
                success: true,
                failed: false,
                logout: false,
                user: actions.payload,
            }

        case AUTH_ACTION_TYPES.UPDATE:
            return {
                ...state,
                user: { ...state.user, ...actions.payload },
            }

        case AUTH_ACTION_TYPES.AUTH_FAILED:
            return {
                ...state,
                failed: true,
                success: false,
                logout: false,
                //user: {},
            }

        case AUTH_ACTION_TYPES.RESET_AUTH_STATE:
            return {
                ...state,
                failed: false,
                success: false,
                logout: false,
                user: {},
            }

        case AUTH_ACTION_TYPES.LOGOUT:
            return {
                ...state,
                failed: false,
                success: false,
                logout: true,
                user: {},
            }

        case AUTH_ACTION_TYPES.GET_CONSOLIDATION_EXCHANGE_DETAIL:
            return {
                ...state,
                failed: false,
                success: true,
                logout: false,
                consolidation_exchange_detail: actions.payload,
            }

        case AUTH_ACTION_TYPES.PREPARE_CONSOLIDATION_EXCHANGE_DETAIL:
            return {
                ...state,
                failed: false,
                success: true,
                logout: false,
                consolidation_exchange: {
                    ...state.consolidation_exchange,
                    ...actions.payload,
                },
            }
        case AUTH_ACTION_TYPES.RESET_CONSOLIDATION_EXCHANGE_DETAIL:
            return {
                ...state,
                failed: false,
                success: true,
                logout: false,
                consolidation_exchange: {},
            }
        case AUTH_ACTION_TYPES.GET_WARRANT_DETAIL:
            return {
                ...state,
                failed: false,
                success: true,
                logout: false,
                warrant_detail: actions.payload,
            }
        case AUTH_ACTION_TYPES.PREPARE_WARRANT_REQUEST:
            return {
                ...state,
                failed: false,
                success: true,
                logout: false,
                warrant_request: {
                    ...state.warrant_request,
                    ...actions.payload,
                },
            }
        case AUTH_ACTION_TYPES.RESET_WARRANT_DETAIL:
            return {
                ...state,
                failed: false,
                success: true,
                logout: false,
                warrant_request: {},
            }
        default:
            return state
    }
}
