import {FC} from 'react'

type inputWithLabelProps = {
    label: string
    placeholder: string
    name: string
    onChange: any
    onBlur: any
    value: any
    errors: any
    touched: any
    width: string
    readOnly?: boolean
}

export const InputWithLabel: FC<inputWithLabelProps> = (props) => (
    <div className="space-y-1">
        <div className="flex flex-col space-y-1 w-full">
            <label htmlFor={props.name} className="text-xs Roboto-Medium">
                {props.label}
            </label>
            <input
                id={props.name}
                readOnly={props.readOnly || false}
                {...props}
                className={`h-7 sm:h-8 md:h-10 text-gray w-full outline-none px-3 text-xs sm:text-sm 2xl:text-base border space-x-2 sm:space-x-4 2xl:space-x-8 ${
                    props.touched && props.errors
                        ? 'border-reddark'
                        : props.readOnly ? 'border-0'
                        : 'border-bordergray'
                } `}
            />
        </div>
        {props.touched && props?.errors && (
            <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                {props?.errors}
            </div>
        )}
    </div>
)
