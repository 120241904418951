import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../components/footer/footer'
import {Nav} from '../../../components/nav/nav'
import {Header} from '../../../components/header/header'

import {FaArrowLeft} from 'react-icons/fa'
import {Input} from '../../../components/input/input'
import {Validation} from '../../constants'
import {shallowEqual, useSelector} from 'react-redux'
import { useDispatch } from 'react-redux'
import {checkSecuritiesHold} from '../../../redux/actions/auth.actions'
import { RootState } from '../../../redux/rootstore'
import { useState } from 'react'
import { ModalComponent } from '../../../components/Modal'
import ValidationError from './ValidationError'

export const SecuritiesIssues = () => {
    const [modal, setmodal] = useState(false)
    const navigate = useNavigate()
    const {event, user} = useSelector((state: RootState) => state.VerificationReducer, shallowEqual)
    const dispatch = useDispatch<any>()
    const formik = useFormik({
        initialValues: {
            Securities: '',
        },
        validationSchema: Yup.object({
            Securities: Yup.string()
                .matches(/^[0-9,.]*$/)
                .required('Number of Securities Required'),
        }),
        onSubmit: async (values) => {
            const {Securities} = values;
            const matched = await dispatch(checkSecuritiesHold(Securities, navigate))
            if(matched){
                if(user && user.event_detail && user.event_detail.offer_econsent && user.securityholder_detail && !user.securityholder_detail.email)
                {
                    navigate('/econsent-email')
                } else {
                    navigate('/deposit-instruction')
                }
            }else {
                setmodal(true)
            }
           
        },
    })
    return (
        <div className="w-full flex flex-col items-center min-h-screen">
            <Nav
                enableProps={true}
            />
            <Header heading={event.event_name} description={'Validation Step'} />

            <div className="page-padding space-y-8 py-10">
                <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                    <FaArrowLeft /> <span>BACK</span>
                </div>
                <div className="md:px-14 flex flex-col items-center justify-center space-y-8">
                    <div className="space-y-8">
                        <div className="Roboto-Bold text-base md:text-xl lg:text-2xl xl:text-3xl text-blue-base text-center">
                            {Validation.securitiesIssues.HEADING}
                        </div>
                        <div className="border border-blue-base p-8  text-sm 2xl:text-base Roboto-Regular space-y-4">
                            <div>
                                {
                                    Validation.securitiesIssues.DESCRIPTION
                                        .HEADING
                                }
                            </div>
                            <ul className="pl-10 xl:pl-16 list-disc">
                                {Validation.securitiesIssues.DESCRIPTION.STEPS.map(
                                    (item, index) => (
                                        <li key={index} className="">
                                            {item}
                                        </li>
                                    )
                                )}
                            </ul>
                            <div>
                                {Validation.securitiesIssues.DESCRIPTION.NOTE}
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4  ">
                        <div className="space-y-2">
                            <div className="Roboto-Bold text-base md:text-xl text-blue-base text-center">
                                Please enter the total number of securities you
                                currently hold in {user?.event_detail?.current_issue_name} {user?.event_detail?.current_issue_type}
                            </div>
                        </div>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="mt-10 sm:w-1/2 md:w-1/3 flex flex-col px-5 items-center  space-y-3">
                        <Input
                            placeholder="Enter number of Securities"
                            name="Securities"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.Securities}
                            errors={formik?.errors?.Securities}
                            touched={formik?.touched?.Securities}
                            width="full"
                        />
                        {formik.values.Securities && <button className="btn-brown w-full " type="submit">
                            SUBMIT
                        </button>}
                    </form>
                </div>
            </div>
            <ModalComponent
                modalState={modal}
                handleModal={() => setmodal(!modal)}
                content={<ValidationError modal={modal} setmodal={setmodal} />}
            />
            <Footer />
        </div>
    )
}
