import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {Footer} from '../../../../components/footer/footer'
import {Nav} from '../../../../components/nav/nav'
import {Checkbox} from '../../../../components/checkbox/checkbox'
import {Header} from '../../../../components/header/header'
import {FaArrowLeft} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { prepareWarrantRequest } from '../../../../redux/actions/auth.actions'
import { RootState } from '../../../../redux/rootstore'
import OriginalSignatureDoc from '../../../../assets/doc/W9 Instructions.pdf'

export const REG_INS_STEP1 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<any>();
    const { warrant_request, event, user } = useSelector((state: RootState) => state && state.VerificationReducer)

    const formik = useFormik({
        initialValues: {
            issue_to: warrant_request.issue_to || '1',
        },
        validationSchema: Yup.object({
            issue_to: Yup.string().required(),
        }),
        onSubmit: (values) => {
            const {issue_to} = values;
            let input = {issue_to};
            if(issue_to === '1'){
                input['issue_in_anothername'] = null
            }
            dispatch(prepareWarrantRequest(input))
            if(issue_to === '1'){
                if(user?.event_detail?.allow_deliver_to_alternate_mailing_address || user?.event_detail?.allow_hold_for_pickup){
                    navigate('/delivery-instruction')
                } else {
                    navigate('/warrant-payment')
                }
            } else {
                navigate('/registration-instruction-step-2')
            }
        },
    })
    return (
        <div className="w-full flex flex-col justify-between   min-h-screen h-screen">
            <div>
                <Nav enableProps={true} />
                <Header
                    heading={event.event_name}
                    description="Registration Instructions"
                />
                <div className="page-padding space-y-8 py-10">
                    <div onClick={() => navigate(-1)} className="text-blue-base text-sm self-start flex items-center space-x-2 Roboto-Bold">
                        <FaArrowLeft /> <span>BACK</span>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        autoComplete="off"
                        className="md:px-14 mx-auto flex flex-col py-12 space-y-10 accent-reddark">
                        <div className="space-y-1">
                            <div className="flex space-y-2 md:space-y-0 flex-col md:flex-row justify-between">
                                <div className="Roboto-Bold text-base md:text-xl text-blue-base md:text-center">
                                    Issue Entitlement(s)
                                </div>
                                <div className="flex items-center space-x-10">
                                    <Checkbox
                                        text="In the same name (as currently registered)"
                                        name="issue_to"
                                        id="IssueEntitlement1"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'1'}
                                        stateVal={formik.values.issue_to}
                                    />
                                    <Checkbox
                                        text="In another name"
                                        name="issue_to"
                                        id="IssueEntitlement2"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={'2'}
                                        stateVal={formik.values.issue_to}
                                    />
                                </div>
                            </div>
                            {formik.errors.issue_to && (
                                <div className="text-reddark text-xs">
                                    {formik.errors.issue_to}
                                </div>
                            )}
                        </div>
                        <hr className="border border-gray2" />
                        {formik.values.issue_to ===
                            '2' && (
                            <div className="text-sm">
                                Please ensure the terms of the warrants allow
                                for this transfer or we may have to reject this
                                request. To issue securities in another name, we
                                will require the <span className='text-brown cursor-pointer' onClick={()=>window.open(OriginalSignatureDoc)}>original signature(s)</span> of the
                                current registered holder(s), or their
                                authorized signer(s), with <span className='text-brown cursor-pointer' onClick={()=>window.open(OriginalSignatureDoc)}>all signature(s)
                                guaranteed</span>. After you confirm the details of
                                your submission, you will be required to print a
                                copy of the Exercise Form which will contain
                                instructions for signing and obtaining an
                                acceptable signature / medallion guarantee and
                                where to send the original document for
                                processing.
                            </div>
                        )}
                        <div className="mt-10  flex flex-col items-end space-y-6 justify-end w-full ">
                            <button className="btn-brown w-44" type="submit">
                                CONTINUE
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}
