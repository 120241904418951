import {FC, useState} from 'react'
import {FaEye, FaEyeSlash} from 'react-icons/fa'

type InputProps = {
    placeholder: string
    name: string
    onChange: any
    onBlur: any
    value: any
    errors: any
    touched: any
}

export const InputPassword: FC<InputProps> = (props) => {
    const [accountPinShow, setaccountPinShow] = useState<Boolean>(false)
    return (
        <div className="space-y-1">
            <div
                className={`flex items-center h-7 md:h-8 lg:h-10 text-gray px-2 w-full border space-x-2 sm:space-x-4 2xl:space-x-8 ${
                    props.touched && props.errors
                        ? 'border-reddark'
                        : 'border-bordergray'
                } `}>
                <input
                    type={accountPinShow ? 'text' : 'password'}
                    autoComplete="off"
                    className="outline-none ring-0 focus:ring-0 h-full focus:border-none border-none px-1 text-xs sm:text-sm 2xl:text-base w-full"
                    {...props}
                />
                {accountPinShow ? (
                    <FaEye
                        className="cursor-pointer"
                        onClick={() => setaccountPinShow(!accountPinShow)}
                    />
                ) : (
                    <FaEyeSlash
                        className="cursor-pointer"
                        onClick={() => setaccountPinShow(!accountPinShow)}
                    />
                )}
            </div>
            {props?.touched && props?.errors && (
                <div className="Roboto-Light  text-xms text-reddark text-left pl-3">
                    {props?.errors}
                </div>
            )}
        </div>
    )
}
