import { useEffect, useState } from 'react'
import { ReactMaterialTable } from '../../../components/table/customtable'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getEventDetail, getEventParticipants, getActivityReport } from '../../../redux/actions/client.actions'
import { useSelector } from 'react-redux'
import {NUMBER_FORMAT, DOLLAR_STRING, DATE_FORMAT} from '../../constants'
import generateDocument from '../../../utils/helpers/doc'
import consolidationDoc from '../../../assets/doc/ConsolidationSubmissionConfirmation.docx'
ChartJS.register(ArcElement, Tooltip, Legend)

export const cash_data = {
    labels: ['CASH PAID/RECEIVED', 'CASH UNPAID'],
    datasets: [
        {
            label: '# of Votes',
            data: [62, 38],
            backgroundColor: ['#F9BD64', '#F23557'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
        },
    ],
}

export const security_data = {
    labels: ['TOTAL SECURITIES EXCHANGE/EXERCISED', 'TOTAL SECURITIES UNEXCHANGED/UNEXERCISED'],
    datasets: [
        {
            label: '# of Votes',
            data: [62, 38],
            backgroundColor: ['#EFC076', '#5A90E5'],
            borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
            borderWidth: 1,
        },
    ],
}

const options = {
    plugins: {
        title: {
            display: true,
            text: 'TEST',
            // position: 'bottom',
        },
        datalabels: {
            color: 'black',
            formatter: (value, ctx) => {
                let sum = 0
                let dataArr = ctx.chart.data.datasets[0].data
                // console.log(dataArr)
                dataArr.map((data) => {
                    sum += data
                })
                sum = sum || 1
                let percentage = ((value * 100) / sum).toFixed(0) + '%'
                return percentage
            },
        },
        legend: {
            responsive: true,
            display: true,
            position: 'right',
            labels: {
                color: 'rgb(255, 99, 132)',
            },
        },
    },
}
export const EventMain = () => {
    
    const navigate = useNavigate()
    const eventId = useParams()
    const dispatch = useDispatch()
    const { event_detail, event_participants } = useSelector(state => state.ClientReducer && state.ClientReducer)
    const [activityReport, setactivityReport] = useState(false)

    useEffect(() => {
        dispatch(getEventDetail(eventId.id, navigate))
        //dispatch(getEventParticipants(eventId.id, navigate))
    }, [])

    useEffect(()=>{
        if(event_detail){
            cash_data.datasets[0].data = [event_detail.total_amount_paid||0, event_detail.total_amount_unpaid||0]
            security_data.datasets[0].data = [event_detail.total_securities_exchanged||0, event_detail.total_securities_unexchanged||0]
        }
    },[event_detail])


    var columns = [
        {
            field: 'full_name',
            title: 'FULL NAME',
            type: 'string',
            render: (rowData) => (
                <div className="mx-auto flex items-center space-x-4">
                    <div className="font-medium">{rowData?.full_name}</div>
                </div>
            ),
        },
        {
            field: 'total_securities_held',
            title: event_detail.e_type === 2 ? 'TOTAL WARRANTS HELD':'TOTAL SECURITIES HELD',
            type: 'string',
            render: (rowData) => (
                <div className="mx-auto flex items-center space-x-4">
                    <div className="font-medium">{NUMBER_FORMAT(rowData?.total_securities_held)}</div>
                </div>
            ),
        },
        {
            field: 'total_securities_submitted',
            title: event_detail.e_type === 2 ? 'TOTAL WARRANTS EXERCISED': 'TOTAL SECURITIES SUBMITTED',
            type: 'string',
            render: (rowData) => (
                <div className="mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {NUMBER_FORMAT(rowData?.total_securities_submitted)}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_securities_issued',
            title: 'TOTAL SECURITIES ISSUED',
            type: 'string',
            render: (rowData) => (
                <div className="mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {NUMBER_FORMAT(rowData?.total_securities_issued)}
                    </div>
                </div>
            ),
        },
        {
            field: 'total_funds_paid',
            title: 'TOTAL FUNDS PAID ($)',
            type: 'string',
            render: (rowData) => (
                <div className="mx-auto flex items-center space-x-4">
                    <div className="font-medium">
                        {DOLLAR_STRING.format(rowData?.total_funds_paid)}
                    </div>
                </div>
            ),
        },
    ]

    return (
        <div className="space-y-10 page-padding">
            <div className="border space-y-12  border-gray2 p-1 md:p-8">
                <div className="grid md:grid-cols-2 md:gap-20  w-full space-y-4  text-sm 2xl:text-base Roboto-Regular">
                    <div className="flex flex-col justify-between">
                        <div className="space-y-14 border-b border-gray2">
                            <div className="Roboto-Medium text-base md:text-2xl text-blue-base">
                                EVENT NAME: {event_detail.event_name}
                            </div>
                            <div className="flex justify-between">
                                <div className="Roboto-Medium text-base md:text-2xl text-blue-base">
                                    EVENT STATUS:
                                </div>
                                <div
                                    className="font-medium h-8 text-sm flex items-center justify-center w-32 uppercase bg-blue-light text-white text-center">
                                    {event_detail.status}
                                </div>
                            </div>
                            <div className='space-y-1'>
                                {event_detail.e_type === 2 &&
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                                Current Exercise Terms: {event_detail.exercise_terms}
                            </div>}
                            <div className="flex justify-end py-2">
                                <div className="flex space-x-10">
                                    <div className="Roboto-Bold">Number</div>
                                    <div className="Roboto-Bold">
                                        Percent(%)
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="space-y-3">
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                                <div className="flex justify-between">
                                    <div className="Roboto-Bold">Total exchanged securities:</div>
                                    <div>{NUMBER_FORMAT(event_detail.total_securities_exchanged)}</div>
                                    <div>{event_detail.total_securities_exchanged_percentage && event_detail.total_securities_exchanged_percentage.toFixed(2)}</div>
                                </div>
                            </div>
                            <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                                <div className="flex justify-between">
                                    <div className="Roboto-Bold">Total unexchanged securities:</div>
                                    <div>{NUMBER_FORMAT(event_detail.total_securities_unexchanged)}</div>
                                    <div>{event_detail.total_securities_unexchanged_percentage && event_detail.total_securities_unexchanged_percentage.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                        {(event_detail.e_type === 1 || event_detail.e_type === 3) &&
                    <div className=" space-y-3">
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Event effective date: {event_detail.event_start_date && DATE_FORMAT(new Date(event_detail.event_start_date))}
                        </div>
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Event completion date (if applicable): {event_detail.event_end_date && DATE_FORMAT(new Date(event_detail.event_end_date))}
                        </div>
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Number of securityholders exchanged: {NUMBER_FORMAT(event_detail.number_of_securityholders_exchanged)}
                        </div>
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Number of securityholders unexchanged: {NUMBER_FORMAT(event_detail.number_of_securityholders_unexchanged)}
                        </div>
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Total amount paid ($): {DOLLAR_STRING.format(event_detail.total_amount_paid)}
                        </div>
                        <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                            Total amount unpaid ($): {DOLLAR_STRING.format(event_detail.total_amount_unpaid)}
                        </div>
                    </div>}
                    {event_detail.e_type === 2 &&
                    <div className=" space-y-3">
                    <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                        Expiry date: {event_detail.event_end_date && DATE_FORMAT(new Date(event_detail.event_end_date))}
                    </div>
                    <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                        Number of warrantholders exercised: {NUMBER_FORMAT(event_detail.number_of_securityholders_exchanged)}
                    </div>
                    <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                        Number of warrantholders unexercised: {NUMBER_FORMAT(event_detail.number_of_securityholders_unexchanged)}
                    </div>
                    <div className=" h-10 Roboto-Bold text-base md:text-xl text-black-normal  border-b border-gray2">
                        Total funds recieved to date ($): {DOLLAR_STRING.format(event_detail.total_funds_received)}
                    </div>
                </div>}
                </div>
                <div className="w-full flex justify-end">
                <button
                        onClick={() => {
                            navigate(`/user/event/securityholder/${eventId.id}`)
                        }}
                        className="btn-brown uppercase px-2 md:px-8 mr-2 md:mr-8">
                        Security Holders
                    </button>
                    {activityReport ? <button
                        onClick={() => {
                            dispatch(getActivityReport(eventId.id, navigate))
                            setactivityReport(!activityReport)
                        }}
                        className="btn-brown uppercase px-2 md:px-8 ">
                        Export
                    </button> :
                    <button
                        onClick={() => {
                            setactivityReport(!activityReport)
                        }}
                        className="btn-brown uppercase px-2 md:px-8 ">
                        Run activity report
                    </button>}
                </div>
            </div>
            {activityReport ? (
                <div className="grid lg:grid-cols-2 gap-4 lg:gap-20">
                    <div className="mx-auto">
                        <Pie
                            data={security_data}
                            options={options}
                            plugins={[ChartDataLabels]}
                            style={{ maxHeight: '350px', width: '400px' }}
                        />
                    </div>
                    <div className="mx-auto">
                        <Pie
                            data={cash_data}
                            options={options}
                            plugins={[ChartDataLabels]}
                            style={{ maxHeight: '350px', width: '400px' }}
                        />
                    </div>
                </div>
            ) : (
                <div className="w-full">
                    <ReactMaterialTable
                        columns={columns}
                        data = {(query)=>
                            new Promise(async (resolve, reject) => {
                                const pageNumber = query.page ? query.page + 1 : 1
                                const result = await dispatch(getEventParticipants(eventId.id, navigate, pageNumber))
                                return resolve(result)
                              })
                        }
                        searchbar={false}
                        toolbar={false}
                        exportbtn={false}
                        fontSize="16px"
                        pagging={true}
                        id="table"                 
                    />
                </div>
            )}
        </div>
    )
}
